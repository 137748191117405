import { isElementVisible } from 'components/Helpers/isElementVisible';
import { desktopFirstSizes } from 'components/Theme/mediaQueries';
import { useGetWindowSize } from 'hooks/ui/UseGetWindowSize';
import { useResizeWidthEffect } from 'hooks/ui/UseResizeWidthEffect';
import { Dispatch, FC, SetStateAction, memo, useState } from 'react';
import { twJoin } from 'tailwind-merge';
import { use100vh } from 'react-div-100vh';
import { DropdownMenu } from './DropdownMenu/DropdownMenu';
import { HamburgerMenu } from './HamburgerMenu';

type HeaderNotDesktopBlockProps = {
    setIsMenuOpened: Dispatch<SetStateAction<boolean>>;
    actionDiscountsLink: string;
    isMenuOpened: boolean;
    wishlistUuid: string | null;
};

const HeaderNotDesktopBlock: FC<HeaderNotDesktopBlockProps> = ({
    setIsMenuOpened,
    actionDiscountsLink,
    isMenuOpened,
    wishlistUuid,
}) => {
    const height = use100vh();
    const { width } = useGetWindowSize();
    const [isNotDesktop, setIsNotDesktop] = useState(true);

    const onMenuToggleHandler = () => {
        setIsMenuOpened((currentState) => !currentState);
    };

    useResizeWidthEffect(
        width,
        desktopFirstSizes.notLargeDesktop,
        () => setIsNotDesktop(false),
        () => setIsNotDesktop(true),
        () => setIsNotDesktop(isElementVisible([{ min: 0, max: desktopFirstSizes.notLargeDesktop }], width)),
    );

    return (
        <>
            {isNotDesktop && (
                <div
                    className={twJoin(
                        'order-5 m-[var(--headerMobileItemGaps)] flex h-10 w-auto cursor-pointer items-center justify-center outline-0',
                        'lg:m-0 lg:ml-3 lg:h-12',
                        'xl:hidden',
                    )}
                >
                    <HamburgerMenu onMenuToggleHandler={onMenuToggleHandler} isMenuOpened={false} />
                    <DropdownMenu
                        actionDiscountsLink={actionDiscountsLink}
                        onMenuToggleHandler={onMenuToggleHandler}
                        isMenuOpened={isMenuOpened}
                        windowHeight={height}
                        wishlistUuid={wishlistUuid}
                    />
                </div>
            )}
        </>
    );
};

export default memo(HeaderNotDesktopBlock);

HeaderNotDesktopBlock.displayName = 'HeaderNotDesktopBlock';
