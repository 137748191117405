import { useComponentUpdate } from 'hooks/helpers/UseComponentUpdate';
import debounce from 'lodash/debounce';
import { useState } from 'react';

export const useMouseHoverDebounce = (
    onMouseEnter: boolean,
    onMouseLeave: boolean,
    delayShow = 180,
    delayHide = 180,
): boolean => {
    const [isItemHovered, setIsItemHovered] = useState(false);
    const [, setIsMouseEntered] = useState(false);

    const showItemDebounce = debounce(
        () =>
            setIsMouseEntered((isMouseEntered) => {
                if (isMouseEntered) {
                    setIsItemHovered(true);
                }
                return false;
            }),
        delayShow,
    );
    const hideItemDebounce = debounce(
        () =>
            setIsMouseEntered((isMouseEntered) => {
                if (!isMouseEntered) {
                    setIsItemHovered(false);
                }

                return false;
            }),
        delayHide,
    );

    useComponentUpdate(() => {
        setIsMouseEntered(true);
        showItemDebounce();
    }, [onMouseEnter]);

    useComponentUpdate(() => {
        setIsMouseEntered(false);
        hideItemDebounce();
    }, [onMouseLeave]);

    return isItemHovered;
};
