import { getFirstImage } from 'connectors/image/Image';
import { AdvertsFragmentApi, useAdvertsQueryApi } from 'graphql/generated';
import { useQueryError } from 'hooks/graphQl/UseQueryError';
import { AdvertPositionNameType, AdvertType } from 'types/advert';

export const useAdverts = (
    positionName: AdvertPositionNameType,
    isPaused = false,
    categorySlug: string | undefined = undefined,
    originalCategorySlug: string | undefined = undefined,
): { data: AdvertType[] | undefined; fetching: boolean } => {
    const [{ data, error, fetching }] = useAdvertsQueryApi({
        variables: {
            positionName,
            categorySlug: categorySlug ?? null,
            originalCategorySlug: originalCategorySlug ?? null,
        },
        pause: isPaused,
    });
    useQueryError(error);

    if (data?.adverts === undefined) {
        return { data: undefined, fetching };
    }

    return { data: mapAdverts(data.adverts), fetching: fetching };
};

export const mapAdverts = (apiData: AdvertsFragmentApi[]): AdvertType[] | undefined => {
    const mappedAdverts = [];

    for (const advertItem of apiData) {
        mappedAdverts.push({
            ...(advertItem.__typename === 'AdvertImage'
                ? {
                      ...advertItem,
                      link: advertItem.link !== null ? advertItem.link : undefined,
                      image: getFirstImage(advertItem.image),
                      imageMobile: getFirstImage(advertItem.imageMobile),
                  }
                : { ...advertItem }),
        });
    }

    return mappedAdverts.length === 0 ? undefined : mappedAdverts;
};
