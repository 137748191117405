import { ArticleTypeEnumApi, SimpleArticleFragmentApi } from 'graphql/generated';
import { FC, useState } from 'react';
import { twJoin } from 'tailwind-merge';
import { ArrowThinIcon } from 'components/Basic/Icon/IconsSvg';
import { ExtendedNextLink } from 'components/Basic/ExtendedNextLink/ExtendedNextLink';

type FooterMenuItemProps = {
    title: string;
    isContentVisible?: boolean;
    contentElementHeight?: number;
    items: SimpleArticleFragmentApi[];
};

const TEST_IDENTIFIER = 'layout-footer-footermenuitem';

export const FooterMenuItem: FC<FooterMenuItemProps> = (props) => {
    const [isContentVisible, setIsContentVisible] = useState(false);
    return (
        <div
            className={twJoin(
                'border-b-[1px] border-border px-[20px] last:border-b-0',
                'vl:w-1/3 vl:border-b-[0] vl:pl-[20px]',
            )}
            data-testid={TEST_IDENTIFIER}
        >
            <p
                className={twJoin(
                    'mb-0 flex cursor-pointer items-center justify-between py-[18px] font-bold uppercase text-greyMedium',
                    'vl:pointer-events-none vl:mb-6 vl:p-0',
                )}
                onClick={() => setIsContentVisible(!isContentVisible)}
            >
                {props.title}
                <ArrowThinIcon
                    className={twJoin(
                        'h-[18px] w-[18px] text-greyMedium ui-transition',
                        isContentVisible && 'rotate-180',
                        'vl:hidden',
                    )}
                />
            </p>
            <div
                className={twJoin(
                    'grid overflow-hidden ui-transition',
                    isContentVisible ? 'grid-rows-[1fr]' : 'grid-rows-[0fr]',
                    'vl:grid-rows-[1fr]',
                )}
            >
                <div className="min-h-0 vl:pb-0">
                    {props.items.map((item) => (
                        <ExtendedNextLink
                            key={item.uuid}
                            type="article"
                            href={item.url}
                            className="mb-[9px] block text-littleSmall no-underline last:mb-5"
                            target={item.external || item.type === ArticleTypeEnumApi.LinkApi ? '_blank' : undefined}
                            rel={
                                item.external || item.type === ArticleTypeEnumApi.LinkApi
                                    ? 'nofollow noreferrer noopener'
                                    : undefined
                            }
                        >
                            {item.name}
                        </ExtendedNextLink>
                    ))}
                </div>
            </div>
        </div>
    );
};
