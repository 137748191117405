import { FC, useEffect, useState } from 'react';
import { NavigationQueryApi } from 'graphql/generated';
import { NavigationColumnCategories } from './NavigationColumnCategories';
import { twJoin } from 'tailwind-merge';
import twMerge from 'utils/twMerge';
import { ExtendedNextLink } from 'components/Basic/ExtendedNextLink/ExtendedNextLink';
import { useRouter } from 'next/router';
import { Locale } from 'helpers/locale';

type NavigationItemProps = {
    navigationItem: NavigationQueryApi['navigationPanelCategories'][0];
    isHoveredNavigation: boolean;
    defaultLocale: string;
};

const TEST_IDENTIFIER = 'layout-header-navigation-navigationitem';

const NavigationItem: FC<NavigationItemProps> = ({ navigationItem, isHoveredNavigation, defaultLocale }) => {
    const router = useRouter();
    const [isHoveredNavigationItem, setHoveredNavigationItem] = useState(false);

    useEffect(() => {
        router.events.on('routeChangeComplete', () => setHoveredNavigationItem(false));
        return () => {
            router.events.off('routeChangeComplete', () => setHoveredNavigationItem(false));
        };
    });

    return (
        <li
            className={twJoin(
                'flex h-[66px] max-w-[132px]',
                defaultLocale !== Locale.DE ? 'xxl:max-w-[calc(1560px/11)]' : 'max-w-none',
                isHoveredNavigation && navigationItem.hasChildren && isHoveredNavigationItem && 'bg-white',
            )}
            data-testid={TEST_IDENTIFIER}
            onMouseEnter={() => {
                setHoveredNavigationItem(true);
            }}
            onMouseLeave={() => {
                setHoveredNavigationItem(false);
            }}
        >
            <ExtendedNextLink
                type="category"
                href={navigationItem.slug}
                className={twMerge(
                    'relative m-0 flex h-[66px] w-full items-start pl-[10px] pr-[16px] pt-[17px] text-small font-semiBold leading-4 no-underline',
                    'hover:no-underline',
                    'after:absolute after:bottom-auto after:left-0 after:top-[21px] after:block after:h-[10px] after:w-[3px] after:bg-greyLight after:content-[""]',
                    isHoveredNavigation &&
                        navigationItem.hasChildren &&
                        isHoveredNavigationItem &&
                        'z-menu-1 bg-white text-primary no-underline ui-text-shadow after:bottom-0 after:top-0 after:h-auto after:bg-orange',
                )}
            >
                {navigationItem.nameForNavigationPanel}
            </ExtendedNextLink>
            {isHoveredNavigation && isHoveredNavigationItem && navigationItem.hasChildren && (
                <div className="absolute left-0 right-0 top-[66px] z-menu rounded-e-lg bg-white px-[30px] pb-[40px] pt-[30px] shadow-menu">
                    <NavigationColumnCategories activeCategoryUuid={navigationItem.uuid} />
                </div>
            )}
        </li>
    );
};

/* @component */
export default NavigationItem;
NavigationItem.displayName = 'NavigationItem';
