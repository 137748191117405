import { SimpleCategoryFragmentApi } from 'graphql/generated';
import { ExtendedNextLink } from 'components/Basic/ExtendedNextLink/ExtendedNextLink';
import { FC, Fragment } from 'react';

type NavigationSubListProps = {
    columnCategoryChildren: SimpleCategoryFragmentApi[];
};

export const NavigationSubList: FC<NavigationSubListProps> = ({ columnCategoryChildren }) => {
    const testIdentifier = 'layout-header-navigation-navigationsublist';

    return (
        <ul className="ml-[-11px] pl-[11px]" data-testid={testIdentifier}>
            {columnCategoryChildren.map((columnCategoryChild, subListIndex) => (
                <Fragment key={subListIndex}>
                    <ExtendedNextLink
                        type="category"
                        href={columnCategoryChild.slug}
                        className="mb-[2px] text-littleSmall leading-[23px] no-underline"
                        data-testid={testIdentifier + '-' + subListIndex}
                    >
                        {columnCategoryChild.nameForNavigationPanel}
                    </ExtendedNextLink>
                    {subListIndex !== columnCategoryChildren.length - 1 && (
                        <span className="text-greyLight">&nbsp;&bull; </span>
                    )}
                </Fragment>
            ))}
        </ul>
    );
};

NavigationSubList.displayName = 'NavigationSubList';
