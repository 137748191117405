import { GtmProvider } from 'hooks/gtm/context/GtmProvider';
import { SkeletonTheme } from 'react-loading-skeleton';

/**
 * This global provider is used primary for styleguidist as wrapper.
 */
const ShopsysGlobalProvider: FC = ({ children }) => {
    return (
        <GtmProvider>
            <SkeletonTheme baseColor="#f9f9f9" highlightColor="#e3e4ec" duration={2}>
                {children}
            </SkeletonTheme>
        </GtmProvider>
    );
};

/* @component */
export default ShopsysGlobalProvider;
