import { showErrorMessage } from 'components/Helpers/Toasts';
import { mapCart, mapCartItem, useCurrentCart } from 'connectors/cart/Cart';
import { CartTypeEnumApi, useAddToDemandCartMutationApi } from 'graphql/generated';
import { onGtmChangeCartItemEventHandler } from 'helpers/gtm/eventHandlers';
import { getGtmMappedCart } from 'helpers/gtm/gtm';
import { useTypedTranslationFunction } from 'hooks/typescript/UseTypedTranslationFunction';
import { useDomainConfig } from 'hooks/useDomainConfig';
import { useCurrentUserData } from 'hooks/user/useCurrentUserData';
import { usePersistStore } from 'store/zustand/usePersistStore';
import { GtmFlowType, GtmListNameType, GtmMessageOriginType } from 'types/gtm/enums';

export const useAddToDemandCart = (origin: GtmMessageOriginType): typeof addToDemandCartAction => {
    const [, addToDemandCart] = useAddToDemandCartMutationApi();
    const updateUserState = usePersistStore((s) => s.updateUserState);
    const demandCartUuid = usePersistStore((s) => s.demandCartUuid);
    const domainConfig = useDomainConfig();
    const t = useTypedTranslationFunction();
    const { isUserLoggedIn } = useCurrentUserData();
    const { cart } = useCurrentCart(true, true);

    const addToDemandCartAction = async (
        catalogNumber: string,
        listIndex: number,
        quantity: number,
        gtmListName: GtmListNameType,
        productServiceUuids?: string[],
        isAbsoluteQuantity = false,
        imosSessionId: string | null = null,
    ) => {
        const itemToBeAdded = cart?.items.find((item) => item.product.catalogNumber === catalogNumber);
        const getProductServiceUuids = () => {
            if (!productServiceUuids) {
                return !itemToBeAdded ? [] : itemToBeAdded.selectedProductServices.map((service) => service.uuid);
            }
            return productServiceUuids;
        };
        const addToCartActionResult = await addToDemandCart({
            cartUuid: demandCartUuid,
            catalogNumber,
            quantity,
            isAbsoluteQuantity,
            cartType: CartTypeEnumApi.DemandCartApi,
            productServiceUuids: getProductServiceUuids(),
            imosSessionId,
        });
        const initialQuantity =
            (addToCartActionResult.data?.AddToDemandCart.addProductResult.cartItem.quantity ?? 0) -
            (addToCartActionResult.data?.AddToDemandCart.addProductResult.addedQuantity ?? 0);

        if (demandCartUuid === null) {
            updateUserState({ demandCartUuid: addToCartActionResult.data?.AddToDemandCart.demandCart.uuid ?? null });
        }

        // EXTEND ADDING TO CART HERE

        if (addToCartActionResult.error !== undefined) {
            showErrorMessage(t('Unable to add product to online demand'), origin);
            return null;
        }

        const addToDemandCartResult = addToCartActionResult.data?.AddToDemandCart;

        if (addToDemandCartResult === undefined) {
            return null;
        }

        const addedCartItem = addToDemandCartResult.addProductResult.cartItem;
        const notOnStockQuantity = addToDemandCartResult.addProductResult.notOnStockQuantity;

        if (notOnStockQuantity > 0) {
            showErrorMessage(
                t(
                    'You have the maximum available amount in your online demand, you cannot add more (total {{ quantity }} {{ unitName }})',
                    {
                        quantity: addedCartItem.quantity,
                        unitName: addedCartItem.product.unit.name,
                    },
                ),
                origin,
            );
        }

        const quantityDifference = isAbsoluteQuantity
            ? addToDemandCartResult.addProductResult.addedQuantity - initialQuantity
            : addToDemandCartResult.addProductResult.addedQuantity;
        const absoluteEventValue =
            Number.parseFloat(addedCartItem.product.price.priceWithoutVat) * Math.abs(quantityDifference);
        const absoluteEventValueWithTax =
            Number.parseFloat(addedCartItem.product.price.priceWithVat) * Math.abs(quantityDifference);

        const mappedCart = mapCart(addToDemandCartResult.demandCart, domainConfig.currencyCode);
        onGtmChangeCartItemEventHandler(
            mapCartItem(addedCartItem, domainConfig.currencyCode),
            domainConfig.currencyCode,
            absoluteEventValue,
            absoluteEventValueWithTax,
            listIndex,
            quantityDifference,
            gtmListName,
            GtmFlowType.onlineInquiry,
            domainConfig.url,
            getGtmMappedCart(
                mappedCart,
                addToDemandCartResult.demandCart.promoCode,
                isUserLoggedIn,
                domainConfig,
                demandCartUuid,
            ),
        );

        return addToDemandCartResult;
    };

    return addToDemandCartAction;
};
