import { EyeIcon } from 'components/Basic/Icon/IconsSvg';
import { getStateAfterValidation } from 'components/Forms/Helpers/getStateAfterValidation';
import { LabelWrapper } from 'components/Forms/Lib/LabelWrapper/LabelWrapper';
import { FC, ForwardedRef, InputHTMLAttributes, useEffect, useState } from 'react';
import { ControllerRenderProps, UseFormTrigger } from 'react-hook-form';
import { twJoin } from 'tailwind-merge';
import { ExtractNativePropsFromDefault } from 'typeHelpers/ExtractNativePropsFromDefault';
import twMerge from 'utils/twMerge';

type NativeProps = ExtractNativePropsFromDefault<
    InputHTMLAttributes<HTMLInputElement>,
    never,
    | 'name'
    | 'id'
    | 'disabled'
    | 'required'
    | 'onChange'
    | 'readOnly'
    | 'autoComplete'
    | 'className'
    | 'onBlurCapture'
    | 'onBlur'
    | 'onKeyPress'
>;

type TextInputProps = NativeProps & {
    label: string | JSX.Element;
    type: 'text' | 'password' | 'email' | 'tel' | 'search' | 'number' | 'hidden';
    hasError?: boolean;
    isTouched?: boolean;
    inputSize?: 'small' | 'smallerMedium' | 'medium';
    placeholderType?: 'static' | 'none';
    variant?: 'searchInHeader';
    markSuccessfulWhenValid?: boolean;
    fieldRef?: ControllerRenderProps<any, any>;
    value?: number | string;
    forwardRef?: ForwardedRef<HTMLInputElement>;
    placeholder?: string;
    inputTrigger?: UseFormTrigger<any>;
};

export const TextInput: FC<TextInputProps> = ({
    hasError,
    isTouched,
    markSuccessfulWhenValid,
    label,
    fieldRef,
    id,
    type,
    variant,
    required,
    placeholderType,
    onChange,
    value,
    name,
    inputTrigger,
    onBlur,
    inputSize,
    className,
    forwardRef,
    ...restProps
}) => {
    const [inputState, setInputState] = useState<'success' | 'error' | undefined>(undefined);
    const [inputType, setInputType] = useState<'text' | 'password' | 'email' | 'tel' | 'search' | 'number' | 'hidden'>(
        type,
    );
    const placeholderTypeValue = typeof label === 'string' && label.length === 0 ? 'none' : placeholderType;

    const togglePasswordVisibilityHandler = () => {
        setInputType((currentInputType) => {
            if (currentInputType === 'password') {
                return 'text';
            }
            if (currentInputType === 'text') {
                return 'password';
            }
            return currentInputType;
        });
    };

    useEffect(() => {
        setInputState(getStateAfterValidation(hasError, isTouched, markSuccessfulWhenValid));
    }, [hasError, isTouched, markSuccessfulWhenValid]);

    const dynamicStyles: { [key: string]: string | undefined } = {};

    if (inputState === 'error') {
        dynamicStyles.borderColor = 'red';
        dynamicStyles.boxShadow = 'none';
    } else if (inputState === 'success') {
        dynamicStyles.borderColor = 'green';
        dynamicStyles.boxShadow = '0 0 0 0.2rem rgba(40, 167, 69, 0.25)';
    }

    const isEmpty = (value === undefined || value === '') && (fieldRef?.value === undefined || fieldRef.value === '');

    const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (fieldRef?.onChange) {
            fieldRef.onChange(e);
        } else if (onChange) {
            onChange(e);
        }

        if (e.nativeEvent.constructor.name !== 'InputEvent' && inputTrigger) {
            inputTrigger(name);
        }
    };

    const handleAllowNumbers = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const target = e.target as HTMLInputElement;
        const allowedKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Escape', 'Enter', 'Tab', 'Delete'];

        if (
            target.type === 'number' &&
            !(allowedKeys.includes(e.key) || e.key.match(/^[+\-.\d]+$/) || e.ctrlKey || e.metaKey || e.altKey)
        ) {
            e.preventDefault();
        }
    };
    const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
        const target = e.target as HTMLInputElement;

        if (target.type === 'number' && !e.clipboardData.getData('Text').match(/^[+\-.\d]+$/)) {
            e.preventDefault();
        }
    };

    return (
        <LabelWrapper
            htmlFor={id}
            inputType="text-input"
            label={label}
            placeholderType={placeholderTypeValue}
            required={required}
            inputSize={inputSize}
            isEmpty={isEmpty}
        >
            <input
                id={id}
                name={fieldRef?.name ?? name}
                onBlurCapture={fieldRef?.onBlur ?? onBlur}
                onChange={(e) => onChangeHandler(e)}
                placeholder={typeof label === 'string' ? label : ' '}
                ref={fieldRef?.ref ?? forwardRef}
                required={required}
                type={inputType}
                value={fieldRef?.value ?? value}
                className={twMerge(
                    'textInput_twClass peer h-[54px] w-full rounded-md border-2 border-greyLight bg-white px-[10px] pb-0 pt-5 text-small text-base [-moz-appearance:textfield] [-webkit-appearance:none] placeholder:text-[transparent] focus:outline-none',
                    'disabled:pointer-events-none disabled:cursor-no-drop disabled:text-greyMedium',
                    inputSize === 'small' && 'h-[36px]',
                    inputSize === 'medium' && 'h-[48px]',
                    inputSize === 'smallerMedium' && 'h-[40px] pt-4',
                    variant === 'searchInHeader' && 'border-white py-0 pl-[15px] pr-[45px]',
                    placeholderTypeValue === 'static' && 'px-[10px] py-0 opacity-100 placeholder:text-grey',
                    placeholderTypeValue === 'none' && 'px-[10px] pb-0 pt-[2px]',
                    'webkit-autofill:!bg-white webkit-autofill:!shadow-input webkit-autofill:hover:!bg-white webkit-autofill:hover:!shadow-input webkit-autofill:focus:!bg-white webkit-autofill:focus:!shadow-input internal-autofill-selected:!bg-white internal-autofill-selected:!shadow-input',
                    className,
                )}
                style={dynamicStyles}
                onKeyDown={handleAllowNumbers}
                onPaste={handlePaste}
                {...restProps}
            />
            {type === 'password' && (
                <EyeIcon
                    className={twJoin(
                        'absolute right-[15px] top-1/2 h-[25px] w-[25px] -translate-y-1/2 cursor-pointer',
                        inputType !== 'text' && 'opacity-50',
                    )}
                    onClick={togglePasswordVisibilityHandler}
                />
            )}
        </LabelWrapper>
    );
};

TextInput.displayName = 'TextInput';
