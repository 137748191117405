import { DomainConfigType } from 'utils/Domain/Domain';

export const mapConnectionEdges = <UnmappedNodeType, MappedNodeType>(
    connectionEdges: ({ node: UnmappedNodeType | null } | null)[] | null,
    mapper: (unmappedNode: UnmappedNodeType, currentDomainConfig: DomainConfigType) => MappedNodeType,
    currentDomainConfig: DomainConfigType,
): MappedNodeType[] => {
    return connectionEdges !== null && Array.isArray(connectionEdges)
        ? connectionEdges.reduce((mappedEdges: MappedNodeType[], edge) => {
              if (edge !== null && edge.node !== null) {
                  mappedEdges.push(mapper(edge.node, currentDomainConfig));
              }

              return mappedEdges;
          }, [])
        : [];
};
