import NextImage, { ImageProps, StaticImageData } from 'next/image';
import React, { HTMLAttributes, ImgHTMLAttributes, useEffect, useState } from 'react';
import twMerge from 'utils/twMerge';
import vshImageLoader from 'utils/vshImageLoader';

type CustomImageProps = HTMLAttributes<HTMLImageElement> & {
    alt: string | undefined;
    src: string | undefined | null | StaticImageData;
    loading?: ImgHTMLAttributes<HTMLImageElement>['loading'];
    testId?: string;
} & Omit<ImageProps, 'src'>;

const Image: FC<CustomImageProps> = ({
    testId,
    src,
    width,
    height,
    className,
    alt,
    title,
    fill,
    loading,
    ...props
}) => {
    let testIdentifier = testId ?? 'basic-image';
    const [imageUrl, setImageUrl] = useState(src ?? '/images/optimized-noimage.png');

    if (!src) {
        testIdentifier = testIdentifier + '-empty';
    }

    useEffect(() => {
        if (src) {
            setImageUrl(src);
        }
    }, [src]);

    return (
        <NextImage
            src={imageUrl}
            loader={vshImageLoader}
            alt={alt}
            title={title}
            width={width}
            height={height}
            className={twMerge('responsive-image block object-contain focus-visible:outline-none', className)}
            fill={fill}
            loading={loading}
            data-testid={testIdentifier}
            onError={() => setImageUrl('/images/optimized-noimage.png')}
            {...props}
        />
    );
};

export default Image;
