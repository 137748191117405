import { ToggleContent } from 'components/Basic/ToggleContent/ToggleContent';
import { mapAddToCartPopupData } from 'connectors/cart/Cart';
import { useAddToDemandCart } from 'hooks/demandCart/UseAddToDemandCart';
import { useHandleCompare } from 'hooks/product/useHandleCompare';
import { useTypedTranslationFunction } from 'hooks/typescript/UseTypedTranslationFunction';
import { Dispatch, FC, HTMLAttributes, SetStateAction, useState, MouseEvent } from 'react';
import { ExtractNativePropsFromDefault } from 'typeHelpers/ExtractNativePropsFromDefault';
import { useHandleProductInWishlist } from 'hooks/wishlist/useHandleProductInWishlist';
import { GtmListNameType, GtmMessageOriginType } from 'types/gtm/enums';
import { usePersistStore } from 'store/zustand/usePersistStore';
import { useDomainConfig } from 'hooks/useDomainConfig';
import {
    CalculatorIcon,
    CompareIcon,
    HeartFullIcon,
    HeartIcon,
    ArrowThinIcon,
    ShareIcon,
} from 'components/Basic/Icon/IconsSvg';
import twMerge from 'utils/twMerge';
import { copyUrlPath } from 'helpers/copyUrlPath';
import { getSlugFromUrl } from 'utils/getSlugFromUrl';
import dynamic from 'next/dynamic';
import { ProductButtonsActionPopupPropsType } from 'components/Blocks/Popup/ProductButtonsActionPopup/ProductButtonsActionPopup';
import { getGtmElementIdByLocation } from 'helpers/gtm/gtm';

const ProductButtonsActionPopup = dynamic<ProductButtonsActionPopupPropsType>(
    () =>
        import('components/Blocks/Popup/ProductButtonsActionPopup/ProductButtonsActionPopup').then(
            (component) => component.ProductButtonsActionPopup,
        ),
    { ssr: false },
);

export enum DropdownLocationEnumType {
    PRODUCT_LIST = 'PRODUCT_LIST',
    PRODUCT_DETAIL = 'PRODUCT_DETAIL',
}

type NativeProps = ExtractNativePropsFromDefault<HTMLAttributes<HTMLDivElement>, never, 'className'>;

type DropdownStyledProps = {
    bigVariant?: boolean;
    productDetailVariant?: boolean;
    setDropdownActive: Dispatch<SetStateAction<boolean>>;
    catalogNumber: string;
    gtmListName: GtmListNameType;
    gtmMessageOrigin: GtmMessageOriginType;
    listIndex: number;
    testIdentifier?: string;
    dropdownIndex?: number;
    hideContentOnClick?: boolean;
    productUuid: string;
    productSlug: string;
    location?: DropdownLocationEnumType | undefined;
};

export const Dropdown: FC<DropdownStyledProps & NativeProps> = (props) => {
    const t = useTypedTranslationFunction();
    const {
        isProductInCompare,
        removeProductFromCompare,
        addProductToCompare,
        isPopupCompareOpen,
        setIsPopupCompareOpen,
    } = useHandleCompare(props.catalogNumber);
    const changeDemandCartItemQuantity = useAddToDemandCart(props.gtmMessageOrigin);
    const { currencyCode, url } = useDomainConfig();
    const [isContentVisible, setContentVisibility] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const updateUserState = usePersistStore((s) => s.updateUserState);
    const { isProductInWishlist, handleProductInWishlist, isPopupWishlistOpen, setIsPopupWishlistOpen } =
        useHandleProductInWishlist(props.productUuid);

    const onAddToDemandCartHandler = async (event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
        setIsButtonDisabled(true);
        const addToDemandCartResult = await changeDemandCartItemQuantity(
            props.catalogNumber,
            props.listIndex,
            1,
            props.gtmListName,
        );
        const mappedAddToDemandCartResult = mapAddToCartPopupData(addToDemandCartResult, currencyCode);

        if (mappedAddToDemandCartResult) {
            updateUserState({
                addToCartPopupData: {
                    gtmListName: props.gtmListName,
                    listIndex: props.listIndex,
                    succesMessage: t('Great choice! We have added your item to the online demand'),
                    continueText: t('To online demand'),
                    product: mappedAddToDemandCartResult,
                    demandCart: true,
                },
            });
        }

        setIsButtonDisabled(false);
        event.preventDefault();
    };

    const dropdownContentItem_twClass =
        'flex items-center py-2 px-[6px] border-b border-border text-primary no-underline hover:underline last:border-b-0';

    return (
        <>
            <ToggleContent
                variant="simple"
                placement="bottom-end"
                hover={false}
                setOpenParent={setContentVisibility}
                arrowVisiblity={false}
                offsetValue={5}
                click
                animation
                data-testid={props.testIdentifier}
                dropdownIndex={props.dropdownIndex}
                hideContentOnClick={props.hideContentOnClick}
                component={
                    <div className="w-[176px] rounded-md bg-white px-2 py-[5px] shadow-default">
                        <button
                            className="flex w-full cursor-pointer items-center border-b border-border px-[6px] py-2 text-primary hover:underline"
                            onClick={(event) => onAddToDemandCartHandler(event)}
                            disabled={isButtonDisabled}
                            data-testid={props.testIdentifier + '-link-demand'}
                            type="button"
                        >
                            <CalculatorIcon className="text-orange" />
                            <div className="ml-[9px] text-extraVerySmall">{t('Add to online demand')}</div>
                        </button>
                        <a
                            className={dropdownContentItem_twClass}
                            onClick={isProductInCompare ? removeProductFromCompare : addProductToCompare}
                            data-testid={props.testIdentifier + '-link-compare'}
                        >
                            <CompareIcon
                                className={twMerge('text-orange', isProductInCompare && 'text-red')}
                                id={getGtmElementIdByLocation('compare', props.location)}
                            />
                            <div
                                className="ml-[9px] text-extraVerySmall"
                                id={getGtmElementIdByLocation('compare', props.location)}
                            >
                                {isProductInCompare ? t('Cancel comparison') : t('Compare')}
                            </div>
                        </a>
                        <a
                            className={dropdownContentItem_twClass}
                            onClick={(event) => {
                                handleProductInWishlist();
                                event.preventDefault();
                            }}
                            data-testid={props.testIdentifier + '-link-wishlist'}
                        >
                            {isProductInWishlist ? (
                                <HeartFullIcon
                                    className="text-orange"
                                    id={getGtmElementIdByLocation('wishlist', props.location)}
                                />
                            ) : (
                                <HeartIcon
                                    className="text-orange"
                                    id={getGtmElementIdByLocation('wishlist', props.location)}
                                />
                            )}
                            <div
                                className="ml-[9px] text-extraVerySmall"
                                id={getGtmElementIdByLocation('wishlist', props.location)}
                            >
                                {isProductInWishlist ? t('Remove to wishlist') : t('Add to wishlist')}
                            </div>
                        </a>
                        <a className={dropdownContentItem_twClass} data-testid={props.testIdentifier + '-link-share'}>
                            <ToggleContent
                                animation
                                placement="top"
                                hover={false}
                                click
                                label={t('copied to the clipboard')}
                            >
                                <div
                                    className="flex w-full items-center"
                                    onClick={(event) => {
                                        copyUrlPath(`${url}${getSlugFromUrl(props.productSlug)}/`);
                                        event.preventDefault();
                                    }}
                                >
                                    <ShareIcon
                                        className="text-orange"
                                        id={getGtmElementIdByLocation('share', props.location)}
                                    />
                                    <div
                                        className="ml-[9px] text-extraVerySmall"
                                        id={getGtmElementIdByLocation('share', props.location)}
                                    >
                                        {t('Share')}
                                    </div>
                                </div>
                            </ToggleContent>
                        </a>
                    </div>
                }
            >
                <div
                    className={twMerge(
                        'flex h-full w-[40px] cursor-pointer flex-row items-center justify-center',
                        props.productDetailVariant && '!w-10 sm:!w-12 vl:!hidden',
                    )}
                >
                    <ArrowThinIcon
                        className={twMerge(
                            'relative w-3 ui-transition',
                            isContentVisible && '-rotate-180',
                            props.bigVariant && 'w-[14px]',
                        )}
                        dataTestid="basic-icon-iconsvg-ArrowThin"
                    />
                </div>
            </ToggleContent>

            {(isPopupCompareOpen || isPopupWishlistOpen) && (
                <ProductButtonsActionPopup
                    onCloseCallback={
                        isPopupCompareOpen ? () => setIsPopupCompareOpen(false) : () => setIsPopupWishlistOpen(false)
                    }
                    type={isPopupCompareOpen ? 'compare' : 'wishlist'}
                />
            )}
        </>
    );
};

Dropdown.displayName = 'Dropdown';
