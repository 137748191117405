import { ApplicationErrors, ApplicationErrorsType } from './applicationErrors';
import { Translate } from 'next-translate';

export const getErrorMessage = (errorCode: string, t: Translate): string | undefined => {
    const ERROR_MESSAGES: Record<ApplicationErrorsType, string | undefined> = {
        [ApplicationErrors.default]: t('Unknown error.'),
        [ApplicationErrors['cart-not-found']]: t('Cart not found.'),
        [ApplicationErrors['max-allowed-limit']]: t('Max allowed limit reached.'),
        [ApplicationErrors['invalid-credentials']]: t('Invalid credentials.'),
        [ApplicationErrors['invalid-refresh-token']]: t('Invalid refresh token.'),
        [ApplicationErrors['order-emails-not-sent']]: t('Automatic order emails was not sent.'),
        [ApplicationErrors['order-empty-cart']]: t('Cart is empty.'),
        [ApplicationErrors['personal-data-request-type-invalid']]: t('Invalid request type.'),
        [ApplicationErrors['blog-category-not-found']]: t('Category not found.'),
        [ApplicationErrors['image-type-invalid']]: t('Invalid image type.'),
        [ApplicationErrors['image-size-invalid']]: t('Invalid image size.'),
        [ApplicationErrors['order-not-found']]: t('Order not found.'),
        [ApplicationErrors['personal-data-hash-invalid']]: t('Invalid hash.'),
        [ApplicationErrors['product-price-missing']]: t('Product price is missing.'),
        [ApplicationErrors['no-result-found-for-slug']]: t('No result found for slug.'),
        [ApplicationErrors['invalid-token']]: t('Invalid token.'),
        [ApplicationErrors['no-payment-found']]: t('No payment found.'),
        [ApplicationErrors['wishlist-not-found']]: t('Wishlist not found.'),
        [ApplicationErrors['wishlist-item-already-exists']]: t('Product in wishlist already exists.'),
        [ApplicationErrors['wishlist-item-not-found']]: t('Product in wishlist not found.'),
        [ApplicationErrors['user-without-default-billing-address']]: t(
            'Your account is without active company. Please contact administrator',
        ),
    };

    return ERROR_MESSAGES[errorCode as ApplicationErrorsType];
};
