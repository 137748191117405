import Image from 'components/Basic/Image/Image';
import { useNavigationSubCategoriesApi } from 'graphql/generated';
import { useQueryError } from 'hooks/graphQl/UseQueryError';
import { FC } from 'react';
import Skeleton from 'react-loading-skeleton';
import { NavigationSubList } from './NavigationSubList';
import { ExtendedNextLink } from 'components/Basic/ExtendedNextLink/ExtendedNextLink';

const TEST_IDENTIFIER = 'layout-header-navigation-columncategory';

type NavigationColumnCategoriesProps = {
    activeCategoryUuid: string;
};

export const NavigationColumnCategories: FC<NavigationColumnCategoriesProps> = ({ activeCategoryUuid }) => {
    const [{ data, error, fetching }] = useNavigationSubCategoriesApi({ variables: { uuid: activeCategoryUuid } });
    useQueryError(error);

    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!data?.category?.navigationChildren.length && !fetching) {
        return null;
    }

    return (
        <ul className="ml-[-45px] flex flex-row flex-wrap">
            {!fetching
                ? data?.category?.navigationChildren.map((columnCategory, columnCategoryIndex) => (
                      <li
                          className="mb-[25px] flex w-1/4 flex-row pl-[45px] last:mb-0"
                          data-testid={TEST_IDENTIFIER}
                          key={columnCategoryIndex}
                      >
                          <ExtendedNextLink
                              type="category"
                              href={columnCategory.slug}
                              className="mr-[10px] h-[64px] w-[64px] text-[0] [&_img]:!max-h-full [&_img]:mix-blend-multiply"
                          >
                              <Image
                                  src={columnCategory.firstImage?.url}
                                  alt={columnCategory.nameForNavigationPanel}
                                  width={64}
                                  height={56}
                              />
                          </ExtendedNextLink>
                          <div className="flex-1">
                              <ExtendedNextLink
                                  type="category"
                                  href={columnCategory.slug}
                                  className="mb-[2px] block text-default no-underline"
                              >
                                  {columnCategory.nameForNavigationPanel}
                              </ExtendedNextLink>
                              {columnCategory.navigationChildren.length > 0 && (
                                  <NavigationSubList columnCategoryChildren={columnCategory.navigationChildren} />
                              )}
                          </div>
                      </li>
                  ))
                : Array(8)
                      .fill(null)
                      .map((_, index) => (
                          <li className="mb-[25px] flex w-1/4 flex-row pl-[45px] last:mb-0" key={index}>
                              <div className="mr-[10px] h-[64px] w-[64px] text-[0]">
                                  <Skeleton className="h-10 w-full" />
                              </div>
                              <div className="flex-1">
                                  <div className="mb-[2px] text-default no-underline">
                                      <Skeleton className="h-4 w-full" />
                                  </div>
                                  <ul className="mb-[-11px] ml-[-11px] flex flex-wrap">
                                      <li className="mb-[8px] h-4 w-[50px] pl-[11px]">
                                          <Skeleton />
                                      </li>
                                      <li className="mb-[8px] h-4 w-[70px] pl-[11px]">
                                          <Skeleton />
                                      </li>
                                      <li className="mb-[8px] h-4 w-[50px] pl-[11px]">
                                          <Skeleton />
                                      </li>
                                      <li className="mb-[8px] h-4 w-[35px] pl-[11px]">
                                          <Skeleton />
                                      </li>
                                      <li className="mb-[8px] h-4 w-[60px] pl-[11px]">
                                          <Skeleton />
                                      </li>
                                      <li className="mb-[8px] h-4 w-[50px] pl-[11px]">
                                          <Skeleton />
                                      </li>
                                      <li className="mb-[8px] h-4 w-[35px] pl-[11px]">
                                          <Skeleton />
                                      </li>
                                  </ul>
                              </div>
                          </li>
                      ))}
        </ul>
    );
};

NavigationColumnCategories.displayName = 'NavigationColumnCategories';
