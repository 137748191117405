import { useState } from 'react';
import { usePersistStore } from 'store/zustand/usePersistStore';
import { useSessionStore } from 'store/zustand/useSessionStore';
import { DomainConfigType } from 'utils/Domain/Domain';

export const useDomainConfig = (): DomainConfigType => {
    const domainConfig = useSessionStore((state) => state.domainConfig);

    return domainConfig;
};

export const useSetDomainConfig = (initialDomainConfig: DomainConfigType, ipAddress: string): void => {
    const [isConfifSet, setIsConfigSet] = useState(false);

    const setDomainConfig = useSessionStore((state) => state.setDomainConfig);
    const updateUserState = usePersistStore((s) => s.updateUserState);

    /**
     * React complains about too many rerenders, probably because it is setting twice
     * and because it needs to run also on SSR we cannot use useEffect here
     */
    if (!isConfifSet) {
        setDomainConfig(initialDomainConfig);
        updateUserState({ ipAddress });
        setIsConfigSet(true);
    }
};
