import { Adverts } from 'components/Blocks/Adverts/Adverts';
import { Webline } from 'components/Layout/Webline/Webline';
import { useWidgets } from 'connectors/widgets/Widgets';
import { useRouter } from 'next/router';
import { FC } from 'react';
import { FooterBottom } from './FooterBottom';
import { FooterBoxInfo } from './FooterBoxInfo';
import { FooterContact } from './FooterContact';
import { FooterCopyright } from './FooterCopyright';
import { FooterMenu } from './FooterMenu';
import { ShopWithUs } from 'components/Layout/ShopWithUs/ShopWithUs';
import { WidgetPlacementsEnumType } from 'types/widgets';

const TEST_IDENTIFIER = 'layout-footer';

export const Footer: FC = () => {
    const router = useRouter();
    const isHomepage = router.route === '/';
    const { widgets, customStyles } = useWidgets([
        WidgetPlacementsEnumType.FOOTER_TESTIMONIALS,
        WidgetPlacementsEnumType.FOOTER_ADDRESS,
        WidgetPlacementsEnumType.FOOTER_AWARDS,
    ]);

    return (
        <>
            {customStyles !== null && <style>{customStyles}</style>}
            <Adverts positionName="footer" withGapTop withWebline withGapBottom />

            {isHomepage && (
                <Webline>
                    <ShopWithUs htmlContent={widgets?.[WidgetPlacementsEnumType.FOOTER_TESTIMONIALS].content} />
                </Webline>
            )}

            <Webline>
                <div className="relative mt-auto" data-testid={TEST_IDENTIFIER}>
                    <div className="relative flex flex-col pb-[60px] lg:pb-[81px]">
                        <FooterBoxInfo />
                        <div className="mb-[38px] mt-6 vl:mb-8 vl:mt-[56px] vl:flex">
                            <FooterContact htmlContent={widgets?.[WidgetPlacementsEnumType.FOOTER_ADDRESS].content} />
                            <FooterMenu />
                        </div>
                        <FooterBottom htmlContent={widgets?.[WidgetPlacementsEnumType.FOOTER_AWARDS].content} />
                        <FooterCopyright />
                    </div>
                </div>
            </Webline>
        </>
    );
};

Footer.displayName = 'Footer';
