import { GetServerSidePropsContext, NextPageContext } from 'next';
import getConfig from 'next/config';
import { getUrlWithoutGetParameters } from './getUrlWithoutGetParameters';
import { getQueryWithoutAllParameterOnServeSide } from 'helpers/filterOptions/GetQueryWithoutAllParameter';
import { getUrlsOnDomain } from 'config/staticRewritePaths/getUrlsOnDomain';

type Url = string | { url: string; param: string | undefined | null };

const getInternationalizedStaticUrl = (url: Url, currentUrl: string, publicRuntimeConfig: any) => {
    const domainUrls: string[] = publicRuntimeConfig.domains.map((domain: any) => domain.url);
    const urlsOnDomain: any = getUrlsOnDomain(domainUrls, currentUrl);

    if (typeof url === 'string') {
        const result = urlsOnDomain[url];
        return typeof result !== 'undefined' ? result : '';
    }

    const staticUrlTemplate = urlsOnDomain[url.url];
    const staticPart = staticUrlTemplate?.split(':')[0];

    return (staticPart ?? '') + (url.param ?? '');
};

export const getInternationalizedStaticUrls = (urls: Url[], domainUrl: string): string[] => {
    const { publicRuntimeConfig } = getConfig();

    return urls.map((url) => getInternationalizedStaticUrl(url, domainUrl, publicRuntimeConfig));
};

export function getServerSideInternationalizedStaticUrl(
    context: GetServerSidePropsContext | NextPageContext,
    domainUrl: string,
): { trimmedUrlWithoutQueryParams: string; queryParams: string | null } {
    if (!('resolvedUrl' in context)) {
        return { trimmedUrlWithoutQueryParams: '/', queryParams: null };
    }

    const { publicRuntimeConfig } = getConfig();

    const trimmedUrlWithoutQueryParams = getUrlWithoutGetParameters(context.resolvedUrl);
    const result = getInternationalizedStaticUrl(trimmedUrlWithoutQueryParams, domainUrl, publicRuntimeConfig);
    const queryParams = getQueryWithoutAllParameterOnServeSide(context.resolvedUrl.split('?')[1]);

    return {
        trimmedUrlWithoutQueryParams: result !== '' ? result : trimmedUrlWithoutQueryParams,
        queryParams: queryParams ? `?${queryParams}` : null,
    };
}
