import { isElementVisible } from 'components/Helpers/isElementVisible';
import { desktopFirstSizes } from 'components/Theme/mediaQueries';
import { useAdverts } from 'connectors/adverts/Adverts';
import { useGetWindowSize } from 'hooks/ui/UseGetWindowSize';
import { useResizeWidthEffect } from 'hooks/ui/UseResizeWidthEffect';
import { FC, Fragment, HTMLAttributes, ImgHTMLAttributes, useState } from 'react';
import { ExtractNativePropsFromDefault } from 'typeHelpers/ExtractNativePropsFromDefault';
import { AdvertPositionNameType, AdvertType } from 'types/advert';
import { AdvertsContent } from './AdvertsContent';

type NativeProps = ExtractNativePropsFromDefault<HTMLAttributes<HTMLDivElement>, never, 'className'>;

export type AdvertsProps = {
    positionName: AdvertPositionNameType;
    withGapTop?: boolean;
    withGapBottom?: boolean;
    withWebline?: boolean;
    currentCategorySlug?: string;
    currentCategoryOriginalCategorySlug?: string | null;
    secondImageBreakpoint?: 'tablet';
    data?: AdvertType[];
    imageSizes?: string;
    loading?: ImgHTMLAttributes<HTMLImageElement>['loading'];
    wrapperClassName?: string | undefined;
};

export const isAdvertImageMissing = (advertsData: AdvertType[], isMobile: boolean | null): boolean => {
    for (const advertItem of advertsData) {
        if (
            advertItem.__typename === 'AdvertImage' &&
            ((isMobile && advertItem.imageMobile === null) || (!isMobile && advertItem.image === null))
        ) {
            return true;
        }
    }
    return false;
};

export const Adverts: FC<AdvertsProps & NativeProps> = ({
    positionName,
    withGapTop,
    withGapBottom,
    withWebline,
    currentCategorySlug,
    currentCategoryOriginalCategorySlug,
    secondImageBreakpoint,
    data,
    className,
    imageSizes = '(min-width: 480px) 50vw, 33vw',
    wrapperClassName,
    loading = 'lazy',
}) => {
    const { data: adverts } = useAdverts(
        positionName,
        data !== undefined,
        currentCategorySlug,
        currentCategoryOriginalCategorySlug ?? undefined,
    );
    const advertsData = data ?? adverts;
    const [isMobile, setIsMobile] = useState<boolean | null>(null);
    const { width } = useGetWindowSize();
    const secondImageBreakpointValue =
        secondImageBreakpoint === 'tablet' ? desktopFirstSizes.tablet : desktopFirstSizes.mobile;

    useResizeWidthEffect(
        width,
        secondImageBreakpointValue,
        () => setIsMobile(false),
        () => setIsMobile(true),
        () => setIsMobile(isElementVisible([{ min: 0, max: secondImageBreakpointValue }], width)),
    );

    if (advertsData === undefined || advertsData.length === 0) {
        return null;
    }

    const isAdvertHidden = isAdvertImageMissing(advertsData, isMobile);

    return (
        <>
            {!isAdvertHidden && (
                <AdvertsContent
                    advertsData={advertsData}
                    className={className}
                    wrapperClassName={wrapperClassName}
                    loading={loading}
                    imageSizes={imageSizes}
                    withGapTop={withGapTop}
                    withGapBottom={withGapBottom}
                    withWebline={withWebline}
                />
            )}
        </>
    );
};

Adverts.displayName = 'Adverts';
