import { showSuccessMessage } from 'components/Helpers/Toasts';
import { useTypedTranslationFunction } from 'hooks/typescript/UseTypedTranslationFunction';
import { useRouter } from 'next/router';
import { Dispatch, SetStateAction, useState } from 'react';
import { usePersistStore } from 'store/zustand/usePersistStore';

export const useHandleCompare = (
    catalogNumber: string,
): {
    isProductInCompare: boolean;
    removeProductFromCompare: () => void;
    addProductToCompare: () => void;
    handleRemoveAllFromCompare: () => void;
    isPopupCompareOpen: boolean;
    setIsPopupCompareOpen: Dispatch<SetStateAction<boolean>>;
} => {
    const router = useRouter();
    const t = useTypedTranslationFunction();
    const [isPopupCompareOpen, setIsPopupCompareOpen] = useState(false);
    const updateUserState = usePersistStore((s) => s.updateUserState);
    const compareProducts = usePersistStore((s) => s.compareProducts);
    const indexOfProduct = compareProducts.indexOf(catalogNumber);
    const isProductInCompare = indexOfProduct !== -1;
    const compareProductsData = [...compareProducts];

    const removeProductFromCompare = () => {
        if (isProductInCompare) {
            compareProductsData.splice(indexOfProduct, 1);
            updateUserState({ compareProducts: [...compareProductsData] });
            if (router.pathname === '/compare-products') {
                router.push(
                    {
                        pathname: router.pathname,
                        query:
                            compareProductsData.length > 0
                                ? { catnums: [...compareProductsData].toString() }
                                : undefined,
                    },
                    {
                        pathname: router.asPath.split('?')[0],
                        query:
                            compareProductsData.length > 0
                                ? { catnums: [...compareProductsData].toString() }
                                : undefined,
                    },
                    { shallow: true },
                );
            }
            showSuccessMessage(t('Product removed from comparison.'));
        }
    };

    const addProductToCompare = () => {
        if (!isProductInCompare) {
            updateUserState({ compareProducts: [...compareProducts, catalogNumber] });

            if (router.pathname === '/compare-products') {
                router.push(
                    {
                        pathname: router.pathname,
                        query: { catnums: [...compareProducts, catalogNumber].toString() },
                    },
                    {
                        pathname: router.asPath.split('?')[0],
                        query: { catnums: [...compareProducts, catalogNumber].toString() },
                    },
                    { shallow: true },
                );
            } else {
                setIsPopupCompareOpen(true);
            }
        }
    };

    const handleRemoveAllFromCompare = () => {
        updateUserState({ compareProducts: [] });
        router.push({ pathname: router.pathname }, { pathname: router.asPath.split('?')[0] }, { shallow: true });
        showSuccessMessage(t('Comparison products have been deleted.'));
    };

    return {
        isProductInCompare,
        removeProductFromCompare,
        addProductToCompare,
        handleRemoveAllFromCompare,
        isPopupCompareOpen,
        setIsPopupCompareOpen,
    };
};
