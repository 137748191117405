import { CartItemType } from 'types/cart';
import { GtmProductType } from 'types/gtm/enums';
import { GtmCartItemType, GtmListedProductType, GtmProductInterface, GtmProductServiceType } from 'types/gtm/types';
import {
    LastVisitedProductType,
    ListedProductType,
    ProductDetailType,
    ProductInterfaceType,
    SimpleProductType,
    VariantDetailType,
} from 'types/product';
import { ListedProductAccessoriesFragmentApi } from 'graphql/generated';
import { mapBreadcrumbToCategories } from 'connectors/products/Products';

export const mapGtmCartItemType = (
    cartItem: CartItemType,
    listIndex: number,
    domainUrl: string,
    quantity?: number,
): GtmCartItemType => ({
    ...mapGtmProductInterface(cartItem.product, domainUrl, 'list'),
    listIndex: listIndex + 1,
    quantity: quantity ?? cartItem.quantity,
    productType: GtmProductType.product,
});

export const mapGtmListedProductType = (
    product: ListedProductType | SimpleProductType | LastVisitedProductType | ListedProductAccessoriesFragmentApi,
    listIndex: number,
    domainUrl: string,
): GtmListedProductType => ({
    ...mapGtmProductInterface(product, domainUrl),
    listIndex: listIndex + 1,
});

export const mapGtmProductDetailType = (
    product: ProductDetailType | VariantDetailType,
    domainUrl: string,
): GtmProductInterface => mapGtmProductInterface(product, domainUrl);

const mapGtmProductInterface = (
    productInterface: ProductInterfaceType | ListedProductAccessoriesFragmentApi,
    domainUrl: string,
    imageSize: string | null = null,
): GtmProductInterface => {
    let productUrl;

    if (domainUrl.endsWith('/')) {
        const domainUrlWithoutTrailingSlash = domainUrl.slice(0, domainUrl.length - 1);
        productUrl = domainUrlWithoutTrailingSlash + productInterface.slug;
    } else {
        productUrl = domainUrl + productInterface.slug;
    }

    const categories =
        'categories' in productInterface
            ? productInterface.categories
            : mapBreadcrumbToCategories(productInterface.breadcrumb);

    return {
        id: productInterface.catalogNumber,
        name: productInterface.fullName,
        availability: productInterface.availability.name,
        imageUrl: mapGtmProductInterfaceImageUrl(productInterface, imageSize),
        labels: productInterface.flags.map((simpleFlagType) => simpleFlagType.name),
        price:
            typeof productInterface.price.priceWithoutVat === 'string'
                ? Number.parseFloat(productInterface.price.priceWithoutVat)
                : productInterface.price.priceWithoutVat,
        priceWithTax:
            typeof productInterface.price.priceWithVat === 'string'
                ? Number.parseFloat(productInterface.price.priceWithVat)
                : productInterface.price.priceWithVat,
        tax:
            typeof productInterface.price.vatAmount === 'string'
                ? Number.parseFloat(productInterface.price.vatAmount)
                : productInterface.price.vatAmount,
        url: productUrl,
        brand: productInterface.brand?.name ?? '',
        categories: categories.map((category) => category.name),
    };
};

const mapGtmProductInterfaceImageUrl = (
    productInterface: ProductInterfaceType | ListedProductAccessoriesFragmentApi,
    imageSize: string | null = null,
): string | undefined => {
    if ('image' in productInterface) {
        return imageSize !== null ? imageSize : productInterface.image?.url;
    }

    if ('firstImage' in productInterface) {
        return imageSize !== null ? imageSize : productInterface.firstImage?.url;
    }

    return undefined;
};

export const mapGtmProductServices = (cartItems: CartItemType[]): GtmProductServiceType[] => {
    const serviceMap = cartItems.reduce((services, cartItem) => {
        cartItem.selectedProductServices.forEach((service) => {
            const existingService = Object.prototype.hasOwnProperty.call(services, service.catalogNumber)
                ? services[service.catalogNumber]
                : false;
            if (existingService === false) {
                services[service.catalogNumber] = {
                    id: service.catalogNumber,
                    productType: GtmProductType.service,
                    name: service.unitName,
                    price: Number.parseFloat(service.unitPrice.priceWithoutVat),
                    quantity: cartItem.quantity,
                    sourceProductIds: [cartItem.product.catalogNumber],
                };
            } else {
                existingService.quantity += cartItem.quantity;
                existingService.sourceProductIds.push(cartItem.product.catalogNumber);
            }
        });
        return services;
    }, {} as { [key: string]: GtmProductServiceType });

    return Object.values(serviceMap);
};
