import { MINIMAL_SEARCH_QUERY_LENGTH } from 'connectors/search/AutocompleteSearch';
import { getGtmSuggestResultEvent } from 'helpers/gtm/eventFactories';
import { gtmSafePushEvent } from 'helpers/gtm/gtm';
import { useEffect, useRef } from 'react';
import { AutocompleteSearchType } from 'types/search';
import { useGtmContext } from './context/useGtmContext';

export const useGtmSuggestResultEvent = (
    searchResult: AutocompleteSearchType | undefined,
    keyword: string,
    fetching: boolean,
): void => {
    const lastSearchedKeywordRef = useRef<string | null>(null);
    const didPageViewRun = useGtmContext().didPageViewRun;

    useEffect(() => {
        if (
            didPageViewRun &&
            searchResult !== undefined &&
            keyword.length >= MINIMAL_SEARCH_QUERY_LENGTH &&
            lastSearchedKeywordRef.current !== keyword &&
            !fetching
        ) {
            lastSearchedKeywordRef.current = keyword;

            gtmSafePushEvent(getGtmSuggestResultEvent(searchResult, keyword));
        }
    }, [keyword, searchResult, fetching, didPageViewRun]);
};
