import { onGtmMessageEvent } from 'helpers/gtm/eventHandlers';
import { toast } from 'react-toastify';
import { GtmMessageDetailType, GtmMessageOriginType, GtmMessageType } from 'types/gtm/enums';

const showMessage = (message: string, type: 'info' | 'error' | 'success' | 'warning'): void => {
    const options = {
        toastId: message,
        autoClose: 3000,
        pauseOnHover: false,
        closeButton: false,
        onOpen: () => document.addEventListener('click', dismissToast),
        onClose: () => document.removeEventListener('click', dismissToast),
    };

    const dismissToast = () => {
        toast.dismiss(message);
    };

    if (type === 'error') {
        toast.error(() => <span dangerouslySetInnerHTML={{ __html: message }} data-testid={'toast-error'} />, options);
    } else if (type === 'info') {
        toast.info(() => <span dangerouslySetInnerHTML={{ __html: message }} data-testid={'toast-info'} />, options);
    } else if (type === 'success') {
        toast.success(
            () => <span dangerouslySetInnerHTML={{ __html: message }} data-testid={'toast-success'} />,
            options,
        );
    }

    toast.warning(() => <span dangerouslySetInnerHTML={{ __html: message }} data-testid={'toast-warning'} />, options);
};

export const showErrorMessage = (message: string, origin?: GtmMessageOriginType): void => {
    showMessage(message, 'error');
    onGtmMessageEvent(GtmMessageType.error, message, GtmMessageDetailType.flashMessage, origin);
};
export const showInfoMessage = (message: string, origin?: GtmMessageOriginType): void => {
    showMessage(message, 'info');
    onGtmMessageEvent(GtmMessageType.information, message, GtmMessageDetailType.flashMessage, origin);
};
export const showSuccessMessage = (message: string): void => {
    showMessage(message, 'success');
};
export const showWarningMessage = (message: string): void => {
    showMessage(message, 'warning');
};
