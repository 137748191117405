import { useNavigationItems } from 'connectors/navigation/Navigation';
import { FC, useEffect, useState } from 'react';
import { useMouseHoverDebounce } from 'hooks/ui/useMouseHoverDebounce';
import { useRouter } from 'next/router';
import NavigationItem from './NavigationItem';
import { useDomainConfig } from 'hooks/useDomainConfig';
import { twJoin } from 'tailwind-merge';
import { Locale } from 'helpers/locale';

const TEST_IDENTIFIER = 'layout-header-navigation';

export const Navigation: FC = () => {
    const router = useRouter();
    const { defaultLocale } = useDomainConfig();
    const { navigationItems } = useNavigationItems();
    const [onMouseEnterTrigger, setOnMouseEnterTrigger] = useState(false);
    const [onMouseLeaveTrigger, setOnMouseLeaveTrigger] = useState(false);
    const [isHoveredNavigation, setHoveredNavigation] = useState(false);
    const isHoveredNavigationDelayed = useMouseHoverDebounce(onMouseEnterTrigger, onMouseLeaveTrigger, 300, 0);

    useEffect(() => {
        router.events.on('routeChangeComplete', () => setHoveredNavigation(false));
        return () => {
            router.events.off('routeChangeComplete', () => setHoveredNavigation(false));
        };
    });

    return (
        <ul
            className={twJoin(
                'hidden gap-[10px] xl:relative xl:flex xl:w-full xl:flex-row',
                defaultLocale === Locale.DE && 'xl:justify-between',
            )}
            data-testid={TEST_IDENTIFIER}
            onMouseEnter={() => {
                setHoveredNavigation(true);
                setOnMouseEnterTrigger(!onMouseEnterTrigger);
            }}
            onMouseLeave={() => {
                setHoveredNavigation(false);
                setOnMouseLeaveTrigger(!onMouseLeaveTrigger);
            }}
        >
            {navigationItems.length > 0 &&
                navigationItems.map((navigationItem, index) => (
                    <NavigationItem
                        navigationItem={navigationItem}
                        key={index}
                        isHoveredNavigation={isHoveredNavigationDelayed && isHoveredNavigation}
                        defaultLocale={defaultLocale}
                    />
                ))}
        </ul>
    );
};

NavigationItem.displayName = 'Navigation';
