import { useSettingsQueryApi } from 'graphql/generated';
import { useTypedTranslationFunction } from 'hooks/typescript/UseTypedTranslationFunction';
import { useDomainConfig } from 'hooks/useDomainConfig';
import { formatPrice } from 'utils/formatting';
import { FormatPriceFunctionType } from 'types/price';

export const useFormatPrice = (): FormatPriceFunctionType => {
    const t = useTypedTranslationFunction();
    const [{ data }] = useSettingsQueryApi({ requestPolicy: 'cache-first' });
    const { defaultLocale = 'en' } = useDomainConfig();
    const { minimumFractionDigits = 0, defaultCurrencyCode = 'CZK' } = data?.settings?.pricing ?? {};

    return (price: number, options?: { explicitZero?: boolean }) =>
        formatPrice(price, defaultCurrencyCode, t, defaultLocale, minimumFractionDigits, options);
};
