import { DeliveryAddressTypeEnumApi, GenderTypeEnumApi } from 'graphql/generated';
import { BillingAddressDataType } from './billingAddress';

export enum CustomerTypeEnum {
    CommonCustomer = 'commonCustomer',
    CompanyCustomer = 'companyCustomer',
}

export type DeliveryAddressType = {
    uuid: string;
    companyName: string;
    street: string;
    city: string;
    postcode: string;
    telephone: string;
    firstName: string;
    lastName: string;
    country: string;
    type: DeliveryAddressTypeEnumApi;
};

export type CurrentCustomerType = {
    uuid: string;
    companyCustomer: boolean;
    gender: GenderTypeEnumApi;
    firstName: string;
    firstNameVocative: string;
    lastName: string;
    lastNameVocative: string;
    email: string;
    telephone: string;
    street: string;
    city: string;
    postcode: string;
    country: string;
    newsletterSubscription: boolean;
    companyName: string;
    companyNumber: string;
    companyTaxNumber: string;
    companySkVatNumber: string;
    passwordOld: string;
    passwordFirst: string;
    passwordSecond: string;
    pricingGroup: string;
    pricingGroupDiscount: number;
    allowCashOnDeliveryAmount: boolean;
    billingAddresses: BillingAddressDataType[];
    orderApplicant: boolean;
    defaultBillingAddressUuid: string | null;
};
