import { ProductFilterOptionsFragmentApi } from 'graphql/generated';
import { FilterOptionsType } from 'types/productFilter';

export const mapProductFilterOptions = (
    productFilterOptionsApiData: ProductFilterOptionsFragmentApi,
    currencyCode: string,
): FilterOptionsType => {
    return {
        ...productFilterOptionsApiData,
        minimalPrice: Math.round((parseFloat(productFilterOptionsApiData.minimalPrice) + Number.EPSILON) * 100) / 100,
        maximalPrice: Math.round((parseFloat(productFilterOptionsApiData.maximalPrice) + Number.EPSILON) * 100) / 100,
        flags: productFilterOptionsApiData.flags !== null ? productFilterOptionsApiData.flags : [],
        parameters:
            productFilterOptionsApiData.parameters?.map((item) => {
                if (item.__typename === 'ParameterColorFilterOption') {
                    return {
                        ...item,
                        values: item.values.map((value) => ({
                            ...value,
                            colorImage: value.images !== null && value.images.length > 0 ? value.images[0] : null,
                        })),
                    };
                }

                return {
                    ...item,
                };
            }) ?? undefined,
        currencyCode,
        allPrices: (productFilterOptionsApiData.allPrices ?? []).map((price) => parseInt(price)),
        isPanelOpen: false,
        mainCategories: productFilterOptionsApiData.mainCategories ?? [],
    };
};
