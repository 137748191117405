import Head from 'next/head';
import { getDomainConfig } from 'utils/Domain/Domain';

export function getGtmHeadScript(nonce: string, isOrbittourPage: boolean): JSX.Element | null {
    if (typeof window === 'undefined' || isOrbittourPage) {
        return null;
    }

    const domainConfig = getDomainConfig(window.location.host);
    const GTM_ID = domainConfig.gtmId;
    const GTM_URL = domainConfig.gtmUrl;

    if (GTM_ID === undefined || GTM_ID.length === 0) {
        return null;
    }

    if (GTM_URL === undefined || GTM_URL === '') {
        return null;
    }

    return (
        <Head>
            <script
                defer
                id="google-tag-manager"
                nonce={nonce}
                dangerouslySetInnerHTML={{
                    __html: `
                (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                '${GTM_URL}/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','${GTM_ID}');
            `,
                }}
            />
        </Head>
    );
}
