import { CartIcon } from 'components/Basic/Icon/IconsSvg';
import { useCurrentCart } from 'connectors/cart/Cart';
import { useMouseHoverDebounce } from 'hooks/ui/useMouseHoverDebounce';
import { FC, memo, useState } from 'react';
import { getInternationalizedStaticUrls } from 'utils/getInternationalizedStaticUrls';
import twMerge from 'utils/twMerge';
import { LoadingOverlay } from 'components/Basic/LoadingOverlay/LoadingOverlay';
import { useDomainConfig } from 'hooks/useDomainConfig';
import { twJoin } from 'tailwind-merge';
import { useComponentMount } from 'hooks/ui/useComponentMount';
import { ExtendedNextLink } from 'components/Basic/ExtendedNextLink/ExtendedNextLink';
import { usePersistStore } from 'store/zustand/usePersistStore';
import { CartContentProps } from './CartContent';
import dynamic from 'next/dynamic';

const CartContent = dynamic<CartContentProps>(
    () => import('components/Layout/Header/Cart/CartContent').then((component) => component.CartContent),
    { ssr: false },
);

export const Cart: FC = memo(() => {
    const testIdentifier = 'layout-header-cart-';
    const isComponentMount = useComponentMount();
    const { cart, isCartEmpty, isInitiallyLoaded } = useCurrentCart();
    const { url } = useDomainConfig();
    const [cartUrl] = getInternationalizedStaticUrls(['/cart/'], url);
    const [onMouseEnterTrigger, setOnMouseEnterTrigger] = useState(false);
    const [onMouseLeaveTrigger, setOnMouseLeaveTrigger] = useState(false);
    const isCartHovered = useMouseHoverDebounce(onMouseEnterTrigger, onMouseLeaveTrigger);
    const loginLoading = usePersistStore((s) => s.loginLoading);

    return (
        <div
            className="relative lg:flex"
            onMouseEnter={() => setOnMouseEnterTrigger(!onMouseEnterTrigger)}
            onMouseLeave={() => setOnMouseLeaveTrigger(!onMouseLeaveTrigger)}
        >
            {(!isInitiallyLoaded || loginLoading !== 'not-loading' || !isComponentMount) && (
                <LoadingOverlay iconClassName="w-[30px] h-[30px]" />
            )}
            <ExtendedNextLink
                type="static"
                href={cartUrl}
                className={twMerge(
                    'flex items-center rounded-md p-[10px] no-underline ui-transition',
                    'lg:p-[14px]',
                    'hover:no-underline',
                    !isCartEmpty ? 'bg-green text-white hover:text-white' : 'bg-greyVeryLight text-primary',
                    isCartHovered && 'shadow-default',
                    isCartHovered && isCartEmpty && 'rounded-md bg-white',
                    isCartHovered && !isCartEmpty && '!text-white',
                )}
                data-testid={testIdentifier + 'block'}
            >
                <span className="relative flex text-bigger">
                    <CartIcon className={twJoin('h-5 w-5', !isCartEmpty ? 'text-white' : 'text-primary')} />
                    <span
                        className={twJoin(
                            'absolute -top-1 right-[-5px] flex h-[14px] min-w-[14px] items-center justify-center rounded-[10px] px-[2px] text-[10px] font-bold text-white',
                            !isCartEmpty ? 'bg-primary' : 'bg-green',
                        )}
                        data-testid={testIdentifier + 'itemcount'}
                    >
                        {cart?.items.length ?? 0}
                    </span>
                </span>
            </ExtendedNextLink>
            {!isCartEmpty && isCartHovered && <CartContent cart={cart} cartUrl={cartUrl} />}
        </div>
    );
});

Cart.displayName = 'Cart';
