import { Cache, cacheExchange, Data } from '@urql/exchange-graphcache';
import {
    mapAddToCartCacheExchange,
    mapMergeCartItemFromCacheExchange,
    mapRemoveFromCartCacheExchange,
} from 'connectors/cart/CartCacheExchange';
import { IntrospectionQuery } from 'graphql';
import {
    AddToCartMutationApi,
    AddToCartMutationVariablesApi,
    AddToDemandCartMutationVariablesApi,
    AddToDemandCartResultApi,
    ApplyPromoCodeToCartMutationVariablesApi,
    CartApi,
    CartQueryApi,
    CartQueryDocumentApi,
    CartQueryVariablesApi,
    CartTypeEnumApi,
    CartWithoutItemsFragmentApi,
    ChangePaymentInCartMutationVariablesApi,
    ChangeServiceInCartMutationVariablesApi,
    DemandCartApi,
    DemandCartQueryApi,
    DemandCartQueryDocumentApi,
    DemandCartQueryVariablesApi,
    Maybe,
    RemoveFromCartMutationApi,
    RemoveFromCartMutationVariablesApi,
    RemoveFromDemandCartMutationVariablesApi,
    RemovePromoCodeFromCartMutationVariablesApi,
} from 'graphql/generated';
import schema from 'schema.graphql.json';

const keyNull = () => null;
const keyCart = () => 'cart';
const keyDemandCart = () => 'demandCart';
const keyWishlist = () => 'wishlist';
const keyUuid = (data: Data) => data.uuid as string | null;
const keyName = (data: Data) => data.name as string | null;
const keyCode = (data: Data) => data.code as string | null;
const keyUrl = (data: Data) => data.url as string | null;

const cache = cacheExchange({
    schema: schema as unknown as IntrospectionQuery,
    keys: {
        AdditionalSize: keyUrl,
        Advert: keyUuid,
        AdvertCode: keyUuid,
        AdvertImage: keyUuid,
        AdvertPosition: (data) => data.positionName as string | null,
        Article: keyUuid,
        Availability: keyName,
        Benefit: keyNull,
        BlogArticle: keyUuid,
        BlogCategory: keyUuid,
        Brand: keyUuid,
        BrandFilterOption: keyNull,
        Cart: keyCart,
        DemandCart: keyDemandCart,
        CartItem: keyUuid,
        CartItemModificationsResult: keyNull,
        CartModificationsResult: keyNull,
        CartPaymentModificationsResult: keyNull,
        CartPromoCodeModificationsResult: keyNull,
        CartServicesModificationsResult: keyNull,
        Category: keyUuid,
        ColorVariant: keyNull,
        CompanyCustomerUser: keyUuid,
        Country: keyCode,
        CustomerUser: keyUuid,
        DeliveryAddress: keyUuid,
        Flag: keyUuid,
        FlagFilterOption: keyNull,
        GoPayPaymentMethod: (data) => data.identifier as string | null,
        Image: keyNull,
        ImageSize: keyUrl,
        Link: keyNull,
        MainVariant: (data) => {
            const colorVariantFilterMatchPattern =
                Array.isArray(data.colorVariants) && data.colorVariants.length > 0
                    ? data.colorVariants[0]?.filterMatch !== null
                        ? data.colorVariants
                              .map((colorVariant) => {
                                  return (
                                      colorVariant.uuid +
                                      ':' +
                                      [
                                          colorVariant.filterMatch,
                                          colorVariant.userFilter,
                                          colorVariant.collectionFilter,
                                      ].join(':')
                                  );
                              })
                              .join(';')
                        : null
                    : null;

            return `${data.uuid}${colorVariantFilterMatchPattern ? `-${colorVariantFilterMatchPattern}` : ''}`;
        },
        NewsletterSubscriber: keyNull,
        NotificationBar: keyNull,
        Order: keyUuid,
        OrderItem: keyNull,
        Parameter: keyUuid,
        ParameterCheckboxFilterOption: keyNull,
        ParameterSliderFilterOption: keyNull,
        ParameterColorFilterOption: keyNull,
        ParameterValue: keyUuid,
        ParameterValueFilterOption: keyNull,
        ParameterValueColorFilterOption: keyNull,
        Payment: keyUuid,
        PersonalData: keyNull,
        PersonalDataPage: keyNull,
        Price: keyNull,
        PricingSetting: keyNull,
        Product: keyUuid,
        ProductFilterOptions: keyNull,
        ProductPrice: keyNull,
        RegularCustomerUser: keyUuid,
        RegularProduct: keyUuid,
        SeoSetting: keyNull,
        Service: keyUuid,
        ServiceGroup: keyUuid,
        Settings: keyNull,
        ShopInfo: keyNull,
        SliderItem: keyUuid,
        StockOnTheWay: keyNull,
        Transport: keyUuid,
        Unit: keyName,
        Variant: keyUuid,
        Widget: keyNull,
        Wishlist: keyWishlist,
    },
    updates: {
        Mutation: {
            Login(_result, _args, cache) {
                invalidateFields(cache, ['cart', 'demandCart', 'wishlist']);
            },
            Logout(_result, _args, cache) {
                invalidateFields(cache, ['cart', 'demandCart', 'wishlist']);
            },
            CreateOrder(_result, _args, cache) {
                invalidateFields(cache, [
                    'currentCustomerUser',
                    'orders',
                    'cart',
                    'promotedProducts',
                    'category',
                    'product',
                ]);
            },
            AddToCart(result, args: { input: Omit<AddToCartMutationVariablesApi, 'cartType'> }, cache) {
                const newCart =
                    typeof result.AddToCart !== 'undefined'
                        ? mapAddToCartCacheExchange(
                              cache,
                              result.AddToCart as AddToCartMutationApi['AddToCart'],
                              args.input.cartUuid,
                              CartTypeEnumApi.CartApi,
                          )
                        : undefined;
                manuallyUpdateCartFragment(cache, args.input.cartUuid, newCart, CartTypeEnumApi.CartApi);
            },
            RemoveFromCart(result, args: { input: Omit<RemoveFromCartMutationVariablesApi, 'cartType'> }, cache) {
                const newCart =
                    typeof result.RemoveFromCart !== 'undefined'
                        ? mapRemoveFromCartCacheExchange(
                              cache,
                              result.RemoveFromCart as RemoveFromCartMutationApi['RemoveFromCart'],
                              args.input.cartUuid,
                              CartTypeEnumApi.CartApi,
                              args.input.cartItemUuid,
                          )
                        : undefined;
                manuallyUpdateCartFragment(cache, args.input.cartUuid, newCart, CartTypeEnumApi.CartApi);
                invalidateFields(cache, ['cartPayments']);
            },
            ApplyPromoCodeToCart(result, args: { input: ApplyPromoCodeToCartMutationVariablesApi }, cache) {
                const newCart =
                    typeof result.ApplyPromoCodeToCart !== 'undefined'
                        ? mapMergeCartItemFromCacheExchange(
                              cache,
                              result.ApplyPromoCodeToCart as CartWithoutItemsFragmentApi,
                              args.input.cartUuid,
                              args.input.cartType,
                          )
                        : undefined;
                manuallyUpdateCartFragment(cache, args.input.cartUuid, newCart, args.input.cartType);
            },
            RemovePromoCodeFromCart(result, args: { input: RemovePromoCodeFromCartMutationVariablesApi }, cache) {
                const newCart =
                    typeof result.RemovePromoCodeFromCart !== 'undefined'
                        ? mapMergeCartItemFromCacheExchange(
                              cache,
                              result.RemovePromoCodeFromCart as CartWithoutItemsFragmentApi,
                              args.input.cartUuid,
                              args.input.cartType,
                          )
                        : undefined;
                manuallyUpdateCartFragment(cache, args.input.cartUuid, newCart, args.input.cartType);
            },
            ChangePaymentInCart(result, args: { input: ChangePaymentInCartMutationVariablesApi }, cache) {
                const newCart =
                    typeof result.ChangePaymentInCart !== 'undefined'
                        ? mapMergeCartItemFromCacheExchange(
                              cache,
                              result.ChangePaymentInCart as CartWithoutItemsFragmentApi,
                              args.input.cartUuid,
                              args.input.cartType,
                          )
                        : undefined;
                manuallyUpdateCartFragment(cache, args.input.cartUuid, newCart, args.input.cartType);
            },
            ChangeServiceInCart(result, args: { input: ChangeServiceInCartMutationVariablesApi }, cache) {
                const newCart =
                    typeof result.ChangeServiceInCart !== 'undefined'
                        ? mapMergeCartItemFromCacheExchange(
                              cache,
                              result.ChangeServiceInCart as CartWithoutItemsFragmentApi,
                              args.input.cartUuid,
                              args.input.cartType,
                          )
                        : undefined;
                manuallyUpdateCartFragment(cache, args.input.cartUuid, newCart, args.input.cartType);
            },
            AddToDemandCart(result, args: { input: AddToDemandCartMutationVariablesApi }, cache) {
                const newDemandCart =
                    typeof result.AddToDemandCart !== 'undefined'
                        ? (result.AddToDemandCart as AddToDemandCartResultApi)
                        : undefined;
                manuallyUpdateDemandCartFragment(
                    cache,
                    args.input.cartUuid,
                    newDemandCart?.demandCart,
                    args.input.cartType,
                );
            },
            RemoveFromDemandCart(result, args: { input: RemoveFromDemandCartMutationVariablesApi }, cache) {
                const newDemandCart =
                    typeof result.RemoveFromDemandCart !== 'undefined'
                        ? (result.RemoveFromDemandCart as DemandCartApi)
                        : undefined;
                manuallyUpdateDemandCartFragment(cache, args.input.cartUuid, newDemandCart, args.input.cartType);
            },
            RemoveDemandCart(_result, _args, cache) {
                invalidateFields(cache, ['demandCart']);
            },
            EditBillingAddress(_result, _args, cache) {
                invalidateFields(cache, ['currentCustomerUser', 'orderDocuments', 'orders']);
            },
            EditDeliveryAddress(_result, _args, cache) {
                invalidateFields(cache, ['currentCustomerUser']);
            },
            ChangeDefaultBillingAddress(_result, _args, cache) {
                invalidateFields(cache, ['cartPayments', 'currentCustomerUser', 'cart', 'demandCart']);
            },
            ChangeBillingAddressInCart(_result, _args, cache) {
                invalidateFields(cache, ['cartPayments', 'currentCustomerUser', 'orderDocuments', 'orders']);
            },
            DeleteDeliveryAddress(_result, _args, cache) {
                invalidateFields(cache, ['currentCustomerUser']);
            },
            addProductToWishlist(_result, _args, cache) {
                invalidateFields(cache, ['wishlist']);
            },
            removeProductFromWishlist(_result, _args, cache) {
                invalidateFields(cache, ['wishlist']);
            },
            cleanWishlist(_result, _args, cache) {
                invalidateFields(cache, ['wishlist']);
            },
        },
    },
});

const manuallyUpdateCartFragment = (
    cache: Cache,
    cartUuid: Maybe<string>,
    newCart: CartApi | undefined | CartWithoutItemsFragmentApi,
    cartType: CartTypeEnumApi,
) => {
    if (newCart !== undefined) {
        cache.updateQuery<CartQueryApi, CartQueryVariablesApi>(
            { query: CartQueryDocumentApi, variables: { cartUuid, cartType } },
            (data) => {
                if (typeof newCart !== 'undefined') {
                    // eslint-disable-next-line no-param-reassign
                    data = {
                        __typename: 'Query',
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        cart: newCart,
                    };
                }

                return data;
            },
        );
    }
};

const manuallyUpdateDemandCartFragment = (
    cache: Cache,
    demandCartUuid: Maybe<string>,
    newDemandCart: DemandCartApi | undefined,
    cartType: CartTypeEnumApi,
) => {
    if (newDemandCart !== undefined) {
        cache.updateQuery<DemandCartQueryApi, DemandCartQueryVariablesApi>(
            { query: DemandCartQueryDocumentApi, variables: { cartUuid: demandCartUuid, cartType } },
            (data) => {
                if (typeof newDemandCart !== 'undefined') {
                    // eslint-disable-next-line no-param-reassign
                    data = {
                        __typename: 'Query',
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        demandCart: newDemandCart,
                    };
                }

                return data;
            },
        );
    }
};

const invalidateFields = (cache: Cache, fields: string[]): void => {
    const key = 'Query';
    for (const field of cache.inspectFields('Query')) {
        if (fields.includes(field.fieldName)) {
            cache.invalidate(key, field.fieldKey);
        }
    }
};

export default cache;
