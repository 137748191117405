// eslint-disable-next-line no-restricted-imports
import NextLink, { LinkProps } from 'next/link';
import { ComponentPropsWithoutRef, FC, ReactElement, ReactNode } from 'react';
import { FriendlyPagesDestinations, FriendlyPagesTypes, FriendlyPagesTypesKeys } from 'types/friendlyUrl';

type ExtendedNextLinkProps = {
    type: FriendlyPagesTypesKeys | 'static';
    queryParams?: Record<string, string>;
    children?: ReactElement | ReactNode | string | number;
} & Omit<ComponentPropsWithoutRef<'a'>, keyof LinkProps> &
    Omit<LinkProps, 'prefetch'>;

export const ExtendedNextLink: FC<ExtendedNextLinkProps> = ({ children, href, type, queryParams, as, ...props }) => {
    return (
        <NextLink
            as={type !== 'static' ? href : as}
            prefetch={false}
            href={
                type !== 'static'
                    ? {
                          pathname: FriendlyPagesDestinations[type],
                          query: { slugType: FriendlyPagesTypes[type], ...queryParams },
                      }
                    : href
            }
            {...props}
        >
            {children}
        </NextLink>
    );
};
