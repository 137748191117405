import { WishlistType } from 'types/wishlist';
import { useQueryError } from 'hooks/graphQl/UseQueryError';
import {
    ListedProductFragmentApi,
    useSharedWishlistQueryApi,
    useWishlistQueryApi,
    WishlistFragmentApi,
} from 'graphql/generated';
import { mapListedProductType } from 'connectors/products/Products';
import { ListedProductType } from 'types/product';
import { usePersistStore } from 'store/zustand/usePersistStore';
import { isServer } from 'helpers/isServer';

export const useWishlist = (wishlistUuidCookie: string | null = null) => {
    const wishlistUuidStore = usePersistStore((s) => s.wishlistUuid);
    const wishlistUuid = isServer() ? wishlistUuidCookie : wishlistUuidStore;

    const [{ data, error, fetching }, refreshWishlist] = useWishlistQueryApi({
        variables: { wishlistUuid },
        requestPolicy: 'network-only',
    });
    useQueryError(error);

    if (data?.wishlist === undefined || data.wishlist === null) {
        return { wishlist: undefined, fetching, refreshWishlist };
    }

    return { wishlist: mapWishlist(data.wishlist), fetching, refreshWishlist };
};

const mapWishlist = (apiData: WishlistFragmentApi): WishlistType => {
    return {
        ...apiData,
        products: apiData.products.map((apiDataProduct: ListedProductFragmentApi) =>
            mapListedProductType(apiDataProduct),
        ),
    };
};

export const useSharedWishlist = (catnums: string[]): { products: ListedProductType[]; fetching: boolean } => {
    const [{ data, error, fetching }] = useSharedWishlistQueryApi({
        variables: { catnums },
    });
    useQueryError(error);

    if (data?.productsByCatnums === undefined) {
        return { products: [], fetching };
    }

    return {
        products: data.productsByCatnums.map((apiDataProduct: ListedProductFragmentApi) =>
            mapListedProductType(apiDataProduct),
        ),
        fetching,
    };
};
