import { ArticlePlacementTypeEnumApi, SimpleArticleFragmentApi, useArticlesQueryApi } from 'graphql/generated';
import { useTypedTranslationFunction } from 'hooks/typescript/UseTypedTranslationFunction';
import { FC, useMemo } from 'react';
import { footerArticlesQueryVariable } from 'connectors/articleInterface/article/Article';
import { FooterMenuItem } from './FooterMenuItem';

const TEST_IDENTIFIER = 'layout-footer-footermenu';

const filterArticlesByPlacement = (
    array: ({ node: SimpleArticleFragmentApi | null } | null)[] | undefined | null,
    placement: ArticlePlacementTypeEnumApi,
): SimpleArticleFragmentApi[] =>
    array?.reduce(
        (prev, current) => (current?.node?.placement === placement ? [...prev, current.node] : prev),
        [] as SimpleArticleFragmentApi[],
    ) ?? [];

export const FooterMenu: FC = () => {
    const t = useTypedTranslationFunction();

    const [{ data }] = useArticlesQueryApi({
        variables: footerArticlesQueryVariable,
    });

    const items = useMemo(
        () => [
            {
                key: 'about-cc',
                title: t('Shopping'),
                items: filterArticlesByPlacement(data?.articles.edges, ArticlePlacementTypeEnumApi.Footer1Api),
            },
            {
                key: 'services',
                title: t('Services'),
                items: filterArticlesByPlacement(data?.articles.edges, ArticlePlacementTypeEnumApi.Footer2Api),
            },
            {
                key: 'about',
                title: t('About company'),
                items: filterArticlesByPlacement(data?.articles.edges, ArticlePlacementTypeEnumApi.Footer3Api),
            },
        ],
        [data?.articles.edges, t],
    );

    return (
        <div className="-mx-5 vl:m-0 vl:flex vl:flex-1 xl:w-[829px] xl:flex-none" data-testid={TEST_IDENTIFIER}>
            {items.map((item) => (
                <FooterMenuItem key={item.key} title={item.title} items={item.items} />
            ))}
        </div>
    );
};

FooterMenu.displayName = 'FooterMenu';
