import { Webline } from 'components/Layout/Webline/Webline';
import { useTypedTranslationFunction } from 'hooks/typescript/UseTypedTranslationFunction';
import React, { FC } from 'react';
import { twJoin } from 'tailwind-merge';

export const Error503: FC = () => {
    const t = useTypedTranslationFunction();

    return (
        <Webline>
            <div
                className={twJoin(
                    'relative mx-auto my-8 flex w-full max-w-[400px] flex-wrap overflow-hidden rounded-lg bg-blueLight pb-[200px]',
                    'lg:mx-0 lg:mb-20 lg:mt-12 lg:h-[350px] lg:w-full lg:max-w-full lg:bg-white lg:pb-0',
                )}
            >
                <div className="relative z-above px-4 pt-5 text-center lg:pl-12 lg:pr-0 lg:pt-12 lg:text-left">
                    <h3 className="mb-0 w-full text-center font-base normal-case lg:text-left">
                        {t('The page is currently under maintenance.')}
                    </h3>
                    <div className="mt-4 text-[15px] text-base opacity-70 lg:mt-0">
                        <p>
                            {t(
                                'In order to give you a better shopping experience, we decided to update the entire e-shop.',
                            )}
                        </p>
                        <p>{t('It will take only few minutes.')}</p>
                        <p>{t('Thank you for your understanding.')}</p>
                    </div>
                </div>
            </div>
        </Webline>
    );
};
