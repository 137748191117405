import { useFormatPrice } from 'hooks/formatting/useFormatPrice';
import { useTypedTranslationFunction } from 'hooks/typescript/UseTypedTranslationFunction';
import { useRouter } from 'next/router';
import { FC } from 'react';
import { AutocompleteSearchType } from 'types/search';
import { getInternationalizedStaticUrls } from 'utils/getInternationalizedStaticUrls';
import { GtmListNameType, GtmSectionType } from 'types/gtm/enums';
import { onGtmProductClickEventHandler, onGtmSuggestClickEventHandler } from 'helpers/gtm/eventHandlers';
import { SEARCH_QUERY_PARAMETER_NAME } from 'helpers/queryParams/queryParamNames';
import { useDomainConfig } from 'hooks/useDomainConfig';
import { ExtendedNextLink } from 'components/Basic/ExtendedNextLink/ExtendedNextLink';
import { twJoin } from 'tailwind-merge';
import { Button } from 'components/Forms/Button/Button';
import { IconImage } from 'components/Basic/Icon/IconImage';
import Image from 'components/Basic/Image/Image';

export const AUTOCOMPLETE_PRODUCT_LIMIT = 5 as const;
export const AUTOCOMPLETE_BRAND_LIMIT = 3 as const;
export const AUTOCOMPLETE_CATEGORY_LIMIT = 3 as const;
export const AUTOCOMPLETE_ARTICLE_LIMIT = 3 as const;

type AutocompleteProps = {
    autocompleteSearchResults?: AutocompleteSearchType;
    isAutocompleteActive: boolean;
    autocompleteSearchQueryValue: string;
};

export const Autocomplete: FC<AutocompleteProps> = (props) => {
    const testIdentifier = 'layout-header-search-autocomplete';

    const router = useRouter();
    const t = useTypedTranslationFunction();
    const formatPrice = useFormatPrice();
    const { url } = useDomainConfig();
    const [searchUrl] = getInternationalizedStaticUrls(['/search/'], url);

    if (props.autocompleteSearchResults === undefined) {
        return null;
    }

    const searchResultGroup_twClass = 'list-none m-0 mb-[10px] [&>li]:last:border-b-0';
    const searchResultGroupTitle_twClass = 'm-0 text-greyLight text-littleSmall';
    const searchResultLink_twClass =
        'w-full flex items-center py-[10px] px-0 text-small font-bold text-base no-underline';

    return (
        <div
            className={twJoin(
                'absolute left-0 right-0 z-overlay mt-[10px] rounded-md bg-white p-[18px] shadow-default ui-transition',
                props.isAutocompleteActive ? 'pointer-events-auto opacity-100' : 'pointer-events-none opacity-0',
                'vl:left-[70px] vl:right-[70px]',
            )}
            data-testid={testIdentifier}
        >
            {(() => {
                if (areAllResultsEmpty(props.autocompleteSearchResults)) {
                    return (
                        <div className="flex items-center p-0">
                            <IconImage icon="warning" alt="warning" />
                            <span className="flex-1 pl-[15px] text-small">
                                {t('Could not find any results for the given query.')}
                            </span>
                        </div>
                    );
                }

                return (
                    <>
                        {props.autocompleteSearchResults.productsSearch.totalCount > 0 && (
                            <>
                                <p className={searchResultGroupTitle_twClass}>
                                    {`${t('Products')} (${props.autocompleteSearchResults.productsSearch.totalCount})`}
                                </p>
                                <ul
                                    className="-ml-[18px] mb-3 mr-0 mt-0 flex list-none flex-row flex-wrap p-0"
                                    data-testid={testIdentifier + '-products'}
                                >
                                    {props.autocompleteSearchResults.productsSearch.products.map(
                                        (product, index) =>
                                            index < AUTOCOMPLETE_PRODUCT_LIMIT && (
                                                <li
                                                    className="mb-[9px] w-full pl-[18px] text-small lg:mb-[18px] lg:w-1/5"
                                                    key={product.slug}
                                                    data-testid={testIdentifier + '-products-' + index}
                                                >
                                                    <ExtendedNextLink
                                                        type="product"
                                                        href={product.slug}
                                                        className="flex cursor-pointer flex-row items-center leading-[18px] text-grey !no-underline outline-none lg:flex-col lg:items-start"
                                                        onClick={() => {
                                                            onGtmProductClickEventHandler(
                                                                product,
                                                                GtmListNameType.suggest,
                                                                index,
                                                                url,
                                                            );
                                                            onGtmSuggestClickEventHandler(
                                                                props.autocompleteSearchQueryValue,
                                                                GtmSectionType.product,
                                                                product.name,
                                                            );
                                                        }}
                                                    >
                                                        <div className="relative mr-[10px] max-w-[72px]">
                                                            <Image
                                                                className="!max-h-[48px] !max-w-[48px] lg:!max-w-[72px]"
                                                                src={product.firstImage?.url}
                                                                alt={product.fullName}
                                                                width={72}
                                                                height={48}
                                                            />
                                                        </div>
                                                        <span className="mb-[5px] mr-0 flex-1">{product.fullName}</span>
                                                        <span className="font-bold text-primary">
                                                            {formatPrice(Number.parseFloat(product.price.priceWithVat))}
                                                        </span>
                                                    </ExtendedNextLink>
                                                </li>
                                            ),
                                    )}
                                </ul>
                            </>
                        )}
                        {props.autocompleteSearchResults.brandSearch.length > 0 && (
                            <>
                                <p className={searchResultGroupTitle_twClass}>
                                    {`${t('Brands')} (${props.autocompleteSearchResults.brandSearch.length})`}
                                </p>
                                <ul className={searchResultGroup_twClass} data-testid={testIdentifier + '-brands'}>
                                    {props.autocompleteSearchResults.brandSearch.map(
                                        (brand, index) =>
                                            index < AUTOCOMPLETE_BRAND_LIMIT && (
                                                <li key={brand.slug} data-testid={testIdentifier + '-brands-' + index}>
                                                    <ExtendedNextLink
                                                        type="brand"
                                                        href={brand.slug}
                                                        className={searchResultLink_twClass}
                                                        onClick={() =>
                                                            onGtmSuggestClickEventHandler(
                                                                props.autocompleteSearchQueryValue,
                                                                GtmSectionType.brand,
                                                                brand.name,
                                                            )
                                                        }
                                                    >
                                                        {brand.name}
                                                    </ExtendedNextLink>
                                                </li>
                                            ),
                                    )}
                                </ul>
                            </>
                        )}
                        {props.autocompleteSearchResults.categoriesSearch.totalCount > 0 && (
                            <>
                                <p className={searchResultGroupTitle_twClass}>
                                    {`${t('Categories')} (${
                                        props.autocompleteSearchResults.categoriesSearch.totalCount
                                    })`}
                                </p>
                                <ul className={searchResultGroup_twClass} data-testid={testIdentifier + '-categories'}>
                                    {props.autocompleteSearchResults.categoriesSearch.edges?.map(
                                        (category, index) =>
                                            index < AUTOCOMPLETE_CATEGORY_LIMIT &&
                                            category?.node && (
                                                <li
                                                    key={category.node.slug}
                                                    data-testid={testIdentifier + '-categories-' + index}
                                                >
                                                    <ExtendedNextLink
                                                        type="category"
                                                        href={category.node.slug}
                                                        className={searchResultLink_twClass}
                                                        onClick={() =>
                                                            onGtmSuggestClickEventHandler(
                                                                props.autocompleteSearchQueryValue,
                                                                GtmSectionType.category,
                                                                category.node!.name,
                                                            )
                                                        }
                                                    >
                                                        {category.node.name}
                                                    </ExtendedNextLink>
                                                </li>
                                            ),
                                    )}
                                </ul>
                            </>
                        )}
                        {props.autocompleteSearchResults.articlesSearch.length > 0 && (
                            <>
                                <p className={searchResultGroupTitle_twClass}>
                                    {`${t('Articles')} (${props.autocompleteSearchResults.articlesSearch.length})`}
                                </p>
                                <ul className={searchResultGroup_twClass} data-testid={testIdentifier + '-articles'}>
                                    {props.autocompleteSearchResults.articlesSearch.map(
                                        (article, index) =>
                                            index < AUTOCOMPLETE_ARTICLE_LIMIT && (
                                                <li
                                                    key={article.slug}
                                                    data-testid={testIdentifier + '-articles-' + index}
                                                >
                                                    <ExtendedNextLink
                                                        type={
                                                            article.__typename === 'Article' ? 'article' : 'blogArticle'
                                                        }
                                                        href={article.slug}
                                                        className={searchResultLink_twClass}
                                                        onClick={() =>
                                                            onGtmSuggestClickEventHandler(
                                                                props.autocompleteSearchQueryValue,
                                                                GtmSectionType.article,
                                                                article.name,
                                                            )
                                                        }
                                                    >
                                                        {article.name}
                                                    </ExtendedNextLink>
                                                </li>
                                            ),
                                    )}
                                </ul>
                            </>
                        )}
                        <div className="flex justify-center" data-testid={testIdentifier + '-all-button'}>
                            <Button
                                type="button"
                                size="small"
                                onClick={() =>
                                    router.push({
                                        pathname: searchUrl,
                                        query: { [SEARCH_QUERY_PARAMETER_NAME]: props.autocompleteSearchQueryValue },
                                    })
                                }
                            >
                                {t('View all results')}
                            </Button>
                        </div>
                    </>
                );
            })()}
        </div>
    );
};

const areAllResultsEmpty = (autocompleteSearchResults: AutocompleteSearchType | undefined) => {
    if (autocompleteSearchResults === undefined) {
        return false;
    }

    return (
        autocompleteSearchResults.articlesSearch.length === 0 &&
        autocompleteSearchResults.brandSearch.length === 0 &&
        autocompleteSearchResults.categoriesSearch.totalCount === 0 &&
        autocompleteSearchResults.productsSearch.totalCount === 0
    );
};

Autocomplete.displayName = 'Autocomplete';
