import { mapAvailabilityData } from 'connectors/availability/Availability';
import { getFirstImage } from 'connectors/image/Image';
import { mapPageInfoApiData } from 'connectors/pageInfo/PageInfo';
import { mapProductPriceData } from 'connectors/price/Prices';
import {
    ColorVariantFragmentApi,
    LastVisitedProductFragmentApi,
    ListedProductConnectionFragmentApi,
    ListedProductConnectionPreviewFragmentApi,
    ListedProductFragmentApi,
    ProductOrderingModeEnumApi,
    UploadedFilesProductFragmentApi,
    useLastVisitedProductsQueryApi,
    usePromotedProductsQueryApi,
} from 'graphql/generated';
import { mapProductFilterOptions } from 'helpers/filterOptions/MapProductFilterOptions';
import { useQueryError } from 'hooks/graphQl/UseQueryError';
import { useDomainConfig } from 'hooks/useDomainConfig';
import { useMemo } from 'react';
import { SimpleCategoryType } from 'types/category';
import {
    LastVisitedProductType,
    ListedProductConnectionPreviewType,
    ListedProductConnectionType,
    ListedProductType,
    ProductColorChooserType,
    UploadedFileType,
} from 'types/product';

export const mapListedProductType = (apiData: ListedProductFragmentApi): ListedProductType => {
    const productInterface = {
        availability: mapAvailabilityData(apiData.availability),
        categories: mapBreadcrumbToCategories(apiData.breadcrumb),
        catalogNumberOriginal: apiData.catalogNumber,
    };

    if (apiData.__typename === 'MainVariant') {
        return {
            ...apiData,
            ...productInterface,
            __typename: 'MainVariant',
            firstImage: apiData.firstSellableVariant.firstImage,
            secondImage: apiData.firstSellableVariant.secondImage,
            catalogNumber: apiData.firstSellableVariant.catalogNumber,
        };
    }

    if (apiData.__typename === 'Variant') {
        return {
            ...apiData,
            ...productInterface,
            __typename: 'Variant',
        };
    }

    return {
        ...apiData,
        ...productInterface,
        __typename: 'RegularProduct',
    };
};

export const mapBreadcrumbToCategories = (
    breadcrumbs: ListedProductFragmentApi['breadcrumb'],
): SimpleCategoryType[] => {
    return breadcrumbs.slice(0, -1).map((breadcrumb) => {
        return {
            name: breadcrumb.name,
            slug: '',
        };
    });
};

export const usePromotedProducts = (): { products: ListedProductType[]; fetching: boolean } => {
    const [{ data, error, fetching }] = usePromotedProductsQueryApi();
    useQueryError(error);

    const apiData = data?.promotedProducts;
    if (apiData === undefined) {
        return { products: [], fetching };
    }

    return {
        products: mapSliderProductApiData(apiData),
        fetching,
    };
};

export const mapSliderProductApiData = (apiData: ListedProductFragmentApi[]): ListedProductType[] => {
    return apiData.map((apiProduct) => {
        return mapListedProductType(apiProduct);
    });
};

export const mapListedProductConnectionPreviewType = (
    apiData: ListedProductConnectionPreviewFragmentApi,
    currencyCode: string,
): ListedProductConnectionPreviewType => {
    return {
        ...apiData,
        productFilterOptions: mapProductFilterOptions(apiData.productFilterOptions, currencyCode),
        defaultOrderingMode: ProductOrderingModeEnumApi.NameAscApi,
    };
};

export const mapListedProductConnectionType = (
    apiData: ListedProductConnectionFragmentApi,
    currencyCode: string,
): ListedProductConnectionType => {
    return {
        ...apiData,
        pageInfo: mapPageInfoApiData(apiData.pageInfo),
        products: mapListedProductTypes(apiData),
        productFilterOptions: mapProductFilterOptions(apiData.productFilterOptions, currencyCode),
        defaultOrderingMode: ProductOrderingModeEnumApi.NameAscApi,
    };
};

export const mapListedProductTypes = (apiData: ListedProductConnectionFragmentApi): ListedProductType[] => {
    const result = [];

    if (apiData.edges !== null) {
        for (const edge of apiData.edges) {
            if (edge?.node === undefined || edge.node === null) {
                continue;
            }
            result.push(mapListedProductType(edge.node));
        }
    }

    return result;
};

export const mapUploadedFileProductApiData = (apiData: UploadedFilesProductFragmentApi[]): UploadedFileType[] => {
    return apiData.map((apiProduct) => {
        return mapUploadedFileProductType(apiProduct);
    });
};

const mapUploadedFileProductType = (apiData: UploadedFilesProductFragmentApi): UploadedFileType => {
    return {
        ...apiData,
        name: apiData.name,
        url: apiData.url,
        size: apiData.size,
        mimeType: apiData.mimeType,
    };
};

export const mapVariantsColors = (colorVariants: ColorVariantFragmentApi[]): ProductColorChooserType[] => {
    return colorVariants.map((colorVariant) => {
        return {
            color: colorVariant.value,
            name: colorVariant.name,
            variantName: colorVariant.variantName,
            link: '/' + colorVariant.slug,
            variantUuid: colorVariant.uuid,
            variantImage: colorVariant.firstImage,
            variantSecondImage: colorVariant.secondImage,
            image:
                colorVariant.parameterValueImages.length > 0 ? getFirstImage(colorVariant.parameterValueImages) : null,
            parameterName: colorVariant.parameterName,
            variantMatched: colorVariant.filterMatch,
            userFilter: !!colorVariant.userFilter,
            collectionFilter: !!colorVariant.collectionFilter,
            stockOnTheWayOrder: colorVariant.stockOnTheWayOrder,
        };
    });
};

export const mapLastVisitedProducts = (
    apiData: LastVisitedProductFragmentApi[],
    currencyCode: string,
): LastVisitedProductType[] => {
    const result = apiData.reduce(function (mappedItems: LastVisitedProductType[], lastVisitedItem) {
        mappedItems.push({
            ...lastVisitedItem,
            price: mapProductPriceData(lastVisitedItem.price, currencyCode, lastVisitedItem.stockUnitName),
            image: lastVisitedItem.firstImage,
            categories: mapBreadcrumbToCategories(lastVisitedItem.breadcrumb),
            variantsCount:
                lastVisitedItem.__typename === 'Variant' ? lastVisitedItem.mainVariant?.variantsCount : undefined,
            variants: lastVisitedItem.__typename === 'MainVariant' ? lastVisitedItem.variants : undefined,
        });
        return mappedItems;
    }, []);
    return result;
};

export const useLastVisitedDetails = (
    lastVisitedProductCatalogNumbers: string[],
): { lastVisitedProductsDetails: LastVisitedProductType[]; fetching: boolean } => {
    const { currencyCode } = useDomainConfig();
    const [{ data, error, fetching }] = useLastVisitedProductsQueryApi({
        variables: { catnums: lastVisitedProductCatalogNumbers },
        pause: lastVisitedProductCatalogNumbers.length === 0,
    });
    useQueryError(error);

    const sortedLastVisitedProducts: LastVisitedProductFragmentApi[] = useMemo(
        () =>
            lastVisitedProductCatalogNumbers.reduce((acc, lastVisitedProductsCatalogNumber) => {
                const product = data?.productsByCatnums.find(
                    (product) => lastVisitedProductsCatalogNumber === product.catalogNumber,
                );

                if (product) {
                    acc.push(product);
                }
                return acc;
            }, [] as LastVisitedProductFragmentApi[]),
        [data?.productsByCatnums, lastVisitedProductCatalogNumbers],
    );

    return useMemo(
        () => ({
            lastVisitedProductsDetails: mapLastVisitedProducts(sortedLastVisitedProducts, currencyCode),
            fetching,
        }),
        [currencyCode, fetching, sortedLastVisitedProducts],
    );
};
