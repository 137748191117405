import { Cache } from '@urql/exchange-graphcache';
import {
    AddToCartMutationApi,
    CartQueryApi,
    CartQueryDocumentApi,
    CartTypeEnumApi,
    CartWithoutItemsFragmentApi,
    DemandCartQueryApi,
    DemandCartQueryDocumentApi,
    Maybe,
    RemoveFromCartMutationApi,
} from 'graphql/generated';

export const mapAddToCartCacheExchange = (
    cache: Cache,
    addToCartResult: AddToCartMutationApi['AddToCart'],
    cartUuid: Maybe<string>,
    cartType: CartTypeEnumApi,
) => {
    const cartCacheData = cache.readQuery<CartQueryApi | DemandCartQueryApi>({
        query: cartType === CartTypeEnumApi.CartApi ? CartQueryDocumentApi : DemandCartQueryDocumentApi,
        variables: { cartUuid, cartType },
    });

    return {
        ...addToCartResult.cart,
        items: addProductIntoCart(cartCacheData, addToCartResult),
    };
};

export const mapRemoveFromCartCacheExchange = (
    cache: Cache,
    removeFromCartResult: RemoveFromCartMutationApi['RemoveFromCart'],
    cartUuid: Maybe<string>,
    cartType: CartTypeEnumApi,
    cartItemUuid: string,
) => {
    const cartCacheData = cache.readQuery<CartQueryApi | DemandCartQueryApi>({
        query: cartType === CartTypeEnumApi.CartApi ? CartQueryDocumentApi : DemandCartQueryDocumentApi,
        variables: { cartUuid, cartType },
    });

    return {
        ...removeFromCartResult,
        items: removeProductFromCart(cartCacheData, cartItemUuid),
    };
};

export const mapMergeCartItemFromCacheExchange = (
    cache: Cache,
    cartResult: CartWithoutItemsFragmentApi,
    cartUuid: Maybe<string>,
    cartType: CartTypeEnumApi,
) => {
    const cartCacheData = cache.readQuery<CartQueryApi | DemandCartQueryApi>({
        query: cartType === CartTypeEnumApi.CartApi ? CartQueryDocumentApi : DemandCartQueryDocumentApi,
        variables: { cartUuid, cartType },
    });

    return {
        ...cartResult,
        items: cartCacheData?.cart?.items,
    };
};

export const addProductIntoCart = (
    cartData: CartQueryApi | DemandCartQueryApi | null,
    addToCartResult: AddToCartMutationApi['AddToCart'],
) => {
    const cartItems = cartData?.cart?.items ? [...cartData.cart.items] : [];
    const cartAddedProduct = addToCartResult.addProductResult;

    if (cartAddedProduct.isNew) {
        cartItems.push(cartAddedProduct.cartItem);
    } else {
        const indexOfAddedCartItem = cartItems.findIndex((item) => item.uuid === cartAddedProduct.cartItem.uuid);

        if (indexOfAddedCartItem !== -1) {
            cartItems.splice(indexOfAddedCartItem, 1, cartAddedProduct.cartItem);
        }
    }

    return cartItems;
};

export const removeProductFromCart = (cartData: CartQueryApi | DemandCartQueryApi | null, cartItemUuid: string) => {
    const cartItems = cartData?.cart?.items ? [...cartData.cart.items] : [];
    const indexOfAddedCartItem = cartItems.findIndex((item) => item.uuid === cartItemUuid);

    if (indexOfAddedCartItem !== -1) {
        cartItems.splice(indexOfAddedCartItem, 1);
    }

    return cartItems;
};
