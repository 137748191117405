import { ImageType } from 'types/image';

export const getFirstImage = (apiData: ImageType | ImageType[] | null): ImageType | null => {
    if (apiData === null || !(0 in apiData)) {
        return null;
    }

    return apiData[0];
};

export const getVariantOrMainVariantImages = (images: ImageType[], mainVariantImages: ImageType[]): ImageType[] => {
    if (images.length === 0 && mainVariantImages.length !== 0) {
        return mainVariantImages;
    }

    return images;
};
