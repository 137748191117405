import { mapPriceData } from 'connectors/price/Prices';
import { CartTransportFragmentApi } from 'graphql/generated';
import { TransportType } from 'types/transport';
import { getFirstImage } from 'connectors/image/Image';

export const mapTransport = (apiData: CartTransportFragmentApi, currencyCode: string): TransportType => {
    return {
        ...apiData,
        price: mapPriceData(apiData.price, currencyCode),
        image: getFirstImage(apiData.images),
    };
};
