// eslint-disable-next-line no-restricted-imports
import { extendTailwindMerge } from 'tailwind-merge';
import tailwindConfig from 'tailwind.config';

const arbitraryFontSizesValues = Array(99)
    .fill('')
    .map((_, index) => `[${index}px]`);

const twMerge = extendTailwindMerge({
    classGroups: {
        colors: [
            {
                text: Object.keys(tailwindConfig.theme?.colors || {}),
            },
        ],
        fontSizes: [
            {
                text: [...Object.keys(tailwindConfig.theme?.fontSize || {}), ...arbitraryFontSizesValues],
            },
        ],
        fontWeights: [
            {
                font: Object.keys(tailwindConfig.theme?.fontWeight || {}),
            },
        ],
    },
});

export default twMerge;
