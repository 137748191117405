import {
    arrow,
    autoUpdate,
    flip,
    FloatingPortal,
    offset,
    Placement,
    safePolygon,
    shift,
    useClick,
    useDismiss,
    useFloating,
    useFocus,
    useHover,
    useInteractions,
    useRole,
} from '@floating-ui/react';
import { relativeTooltipHandler } from 'helpers/tooltip/relativeTooltipHandler';
import { useRouter } from 'next/router';
import { cloneElement, CSSProperties, FC, ReactElement, useEffect, useRef, useState } from 'react';
import { Transition } from 'react-transition-group';
import { twJoin } from 'tailwind-merge';

type ToggleContentProps = {
    variant?: 'simple' | 'default';
    label?: string;
    component?: ReactElement<any, any>;
    placement?: Placement;
    children: JSX.Element;
    relative?: boolean;
    arrowVisiblity?: boolean;
    maxWidth?: number;
    hover?: boolean;
    click?: boolean;
    offsetValue?: number;
    animation?: boolean;
    setOpenParent?: (open: boolean) => void;
    dropdownIndex?: number;
    hideContentOnClick?: boolean;
    isDisabled?: boolean;
    safePolygonActive?: boolean;
};

export const ToggleContent: FC<ToggleContentProps> = ({
    children,
    variant = 'default',
    setOpenParent,
    label,
    component,
    placement = 'top',
    relative = false,
    arrowVisiblity = true,
    maxWidth = null,
    hover = true,
    click = false,
    offsetValue = 15,
    animation = false,
    dropdownIndex = 11,
    hideContentOnClick = false,
    isDisabled = false,
    safePolygonActive = true,
}) => {
    const router = useRouter();
    const [open, setOpen] = useState(false);
    const [animationValue, setAnimationValue] = useState(animation);
    const arrowRef = useRef(null);
    const cssTransitionRef = useRef<HTMLDivElement>(null);

    const openChangeHandler = (open: boolean) => {
        setOpen(open);
        if (setOpenParent !== undefined) {
            setOpenParent(open);
        }
    };

    const hideContentOnClickHandler = () => {
        if (hideContentOnClick) {
            setAnimationValue(false);
            openChangeHandler(false);
        }
    };

    const {
        x,
        y,
        reference,
        floating,
        context,
        strategy,
        middlewareData: { arrow: { x: arrowX, y: arrowY } = {} },
    } = useFloating({
        placement,
        open,
        onOpenChange: isDisabled ? undefined : (open) => openChangeHandler(open),
        middleware: [
            offset(offsetValue),
            flip(),
            shift({
                padding: 20,
            }),
            arrow({ element: arrowRef }),
        ],
        whileElementsMounted: autoUpdate,
    });

    const { getReferenceProps, getFloatingProps } = useInteractions([
        useHover(context, {
            enabled: hover,
            handleClose: relative || !safePolygonActive ? null : safePolygon(),
        }),
        useClick(context, {
            enabled: click,
        }),
        useFocus(context),
        useRole(context, { role: 'tooltip' }),
        useDismiss(context),
    ]);

    const floatingProps = getFloatingProps({
        ref: floating,
    });

    const staticSide = {
        top: 'bottom',
        right: 'left',
        bottom: 'top',
        left: 'right',
    }[context.placement.split('-')[0]] as string;

    const transitionStyles: { [key: string]: CSSProperties } = {
        exited: { opacity: 0 },
        entering: { opacity: 1 },
        entered: { opacity: 1 },
        exiting: { opacity: 0 },
        unmounted: {},
    };

    useEffect(() => {
        openChangeHandler(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router.pathname]);

    return (
        <>
            {cloneElement(
                children,
                getReferenceProps({
                    ref: reference,
                    onMouseMove: relative
                        ? (event) => relativeTooltipHandler(event, placement, floatingProps)
                        : undefined,
                    ...children.props,
                }),
            )}
            <FloatingPortal id="body">
                <Transition nodeRef={cssTransitionRef} in={open} timeout={animationValue ? 300 : 0} unmountOnExit>
                    {(state) => (
                        <div ref={cssTransitionRef}>
                            <div
                                {...getFloatingProps({
                                    ref: floating,
                                })}
                                style={{
                                    zIndex: dropdownIndex,
                                    position: strategy,
                                    top: relative ? '' : `${y}px`,
                                    left: relative ? '' : `${x}px`,
                                    maxWidth: maxWidth !== null ? `${maxWidth}px` : `calc(100% - 40px)`,
                                    ...transitionStyles[state],
                                }}
                                className={twJoin(
                                    variant === 'default' && 'rounded-md bg-white p-[15px] shadow-default',
                                    animation && 'duration-300',
                                )}
                                onClick={hideContentOnClickHandler}
                            >
                                {label !== undefined && <span dangerouslySetInnerHTML={{ __html: label }} />}
                                {component !== undefined && component}
                                {arrowVisiblity && (
                                    <div
                                        className="absolute h-3 w-3 rotate-45 bg-white"
                                        {...getFloatingProps({
                                            ref: arrowRef,
                                        })}
                                        style={{
                                            top: arrowY !== undefined ? `${arrowY}px` : '',
                                            left: arrowX !== undefined ? `${arrowX}px` : '',
                                            [staticSide]: `calc(12px / -2)`,
                                        }}
                                    ></div>
                                )}
                            </div>
                        </div>
                    )}
                </Transition>
            </FloatingPortal>
        </>
    );
};
