export enum GtmPageType {
    home = 'home',
    crossroad = 'crossroad',
    category = 'category',
    product = 'product',
    cart = 'cart',
    onlineInquiry = 'online inquiry',
    transportPayOrder = 'transport pay order',
    transportPayInquiry = 'transport pay inquiry',
    shippingDataOrder = 'shipping data order',
    shippingDataInquiry = 'shipping data inquiry',
    purchaseOrder = 'purchase order',
    purchaseInquiry = 'purchase inquiry',
    search = 'search',
    blog = 'blog',
    article = 'article',
    text = 'text',
    purchaseFail = 'purchase fail',
    purchaseSuccess = 'purchase success',
    other = 'other',
    notFound = 404,
    seoCategory = 'seo category',
    complaints = 'complaints',
    compare = 'compare',
    registration = 'registration',
    customerAccount = 'customer account',
    cookieConsent = 'cookie_consent',
    flag = 'flag',
    brand = 'brand',
    wishlist = 'wishlist',
}

export enum GtmListNameType {
    category = 'category',
    seoCategory = 'SEO category',
    detail = 'detail',
    variants = 'variants',
    accessory = 'accessory',
    related = 'related',
    alternatives = 'alternatives',
    homepageAction = 'homepage action',
    homepageCarousel = 'homepage carousel',
    searchResult = 'search result',
    lastVisited = 'last visited',
    selectedForYou = 'selected for you',
    bestseller = 'bestseller',
    compare = 'compare',
    crossSellPopup = 'cross sell popup',
    suggest = 'suggest',
    blogArticle = 'blog_article',
    brand = 'brand',
    cart = 'cart',
    flag = 'flag',
    cart_direct_code = 'cart direct code',
    wishlist = 'wishlist',
    sharedWishlist = 'shared_wishlist',
    categoryBestsellers = 'category_bestsellers',
    demand_cart = 'demand_cart',
    imos = 'imos',
}

export enum GtmEventType {
    pageReady = 'page_ready',
    addToCart = 'ec.add_to_cart',
    removeFromCart = 'ec.remove_from_cart',
    cart = 'ec.cart',
    productsList = 'ec.products_list',
    productClick = 'ec.product_click',
    productView = 'ec.product_view',
    paymentShippingInfo = 'ec.payment_shipping_info',
    suggestResult = 'ec.suggest_result',
    suggestClick = 'ec.suggest_click',
    shippingData = 'ec.shipping_data',
    paymentInfo = 'ec.payment_info',
    purchase = 'ec.purchase',
    payment = 'ec.payment',
    paymentFalse = 'ec.payment_false',
    paymentRepeat = 'ec.payment_repeat',
    message = 'ec.message',
    sendForm = 'ec.send_form',
}

export enum GtmMessageOriginType {
    cart = 'cart',
    checkout = 'checkout',
    catalog = 'catalog',
    complaints = 'complaints',
    contact = 'contact',
    userAddressList = 'user_address_list',
    userBillingAddress = 'user_billing_address',
    userDeliveryAddress = 'user_delivery_address',
    userContactInformation = 'user_contact_information',
    userPasswordChange = 'user_password_change',
    userMailingAddress = 'user_mailing_address',
    newPassword = 'new_password',
    personalDataExport = 'personal_data_export',
    personalDataOverview = 'personal_data_overview',
    registration = 'registration',
    resetPassword = 'reset_password',
    onlineInquiry = 'online_inquiry',
    loginPopup = 'login_popup',
    login = 'login',
    detail = 'detail',
    crossSellPopup = 'cross_sell_popup',
    compare = 'compare',
    homepageAction = 'homepage_action',
    blogArticle = 'blogArticle',
    brand = 'brand',
    category = 'category',
    categoryBestsellers = 'category_bestsellers',
    flag = 'flag',
    variants = 'variants',
    searchResults = 'search_results',
    accessory = 'accessory',
    related = 'related',
    alternatives = 'alternatives',
    cartDirectCode = 'cart_direct_code',
    wishlist = 'wishlist',
    sharedWishlist = 'shared_wishlist',
    approveOrRejectOrder = 'approve_or_reject_order',
    watchdog = 'watchdog',
    imos = 'imos',
    //TODO should be deleted before deploying the project to production
    notImplementedYet = 'not_implemented_yet',
}

export enum GtmMessageDetailType {
    flashMessage = 'flash message',
}

export enum GtmSectionType {
    category = 'category',
    product = 'product',
    brand = 'brand',
    article = 'article',
}

export enum GtmUserType {
    b2b = 'B2B',
    b2c = 'B2C',
}

export enum GtmDeviceTypes {
    desktop = 'desktop',
    tablet = 'tablet',
    mobile = 'mobile',
    unknown = 'unknown',
}

export enum GtmConsent {
    granted = 'granted',
    denied = 'denied',
}
export enum GtmUserStatus {
    customer = 'customer',
    visitor = 'visitor',
}

export enum GtmFlowType {
    order = 'order',
    onlineInquiry = 'online inquiry',
}

export enum GtmOriginType {
    first = 'first',
    repeat = 'repeat',
}

export enum GtmFormType {
    complaint = 'reklamace',
    forgottenPassword = 'zapomenute heslo',
    registration = 'registrace',
    catalog = 'katalog',
}

export enum GtmMessageType {
    error = 'error',
    information = 'information',
}

export enum GtmProductType {
    product = 'product',
    shipping = 'shipping',
    service = 'service',
}
