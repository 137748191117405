import { twJoin } from 'tailwind-merge';
import { FC } from 'react';

type FooterContactsProps = {
    htmlContent?: string;
};

const TEST_IDENTIFIER = 'layout-footer-footercontact';

export const FooterContact: FC<FooterContactsProps> = (props) => {
    return (
        <>
            {props.htmlContent !== undefined && (
                <div
                    className={twJoin(
                        'relative mb-[30px] flex flex-col items-start',
                        'lg:items-center',
                        'vl:mb-0 vl:mr-5 vl:w-[27%] vl:items-start vl:pl-5',
                        'xl:w-auto xl:flex-1',
                    )}
                    data-testid={TEST_IDENTIFIER}
                >
                    <div dangerouslySetInnerHTML={{ __html: props.htmlContent }} />
                </div>
            )}
        </>
    );
};

FooterContact.displayName = 'FooterContact';
