import { canUseDom } from 'helpers/canUseDom';
import { Mutate, StoreApi } from 'zustand';
import { PersistStore } from './usePersistStore';

export type StoreWithPersist<T> = Mutate<StoreApi<T>, [['zustand/persist', PersistStore]]>;

export const withStorageDOMEvents = <T>(store: StoreWithPersist<T>): (() => void) | undefined => {
    if (!canUseDom()) {
        return undefined;
    }

    const storageEventCallback = (e: StorageEvent) => {
        if (e.key === store.persist.getOptions().name && e.newValue) {
            store.persist.rehydrate();
        }
    };

    window.addEventListener('storage', storageEventCallback);

    return () => {
        window.removeEventListener('storage', storageEventCallback);
    };
};
