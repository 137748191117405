import { PriceFragmentApi } from 'graphql/generated';
import { ImageType } from 'types/image';
import { PriceType } from 'types/price';

export enum PaymentTypeEnum {
    GoPay = 'goPay',
    Sofort = 'sofort',
    Stripe = 'stripe',
    PayPal = 'payPal',
    Basic = 'basic',
    Invoice = 'invoiceDue',
}

export type PaymentInputType = {
    uuid: string;
    price: PriceFragmentApi;
    goPayBankSwift: string | null;
};

export type GoPayPaymentMethodType = {
    identifier: string;
    name: string;
    paymentGroup: string;
};

export type PaymentType = {
    uuid: string;
    name: string;
    description: string;
    instruction: string;
    goPayPaymentMethod: GoPayPaymentMethodType | undefined;
    price: PriceType;
    image: ImageType | null;
    type: PaymentTypeEnum;
};

export type LastOrderPaymentInfoType = {
    name: string;
    price: PriceType;
    type: PaymentTypeEnum;
};
