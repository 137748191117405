import { AvailabilityFragmentApi } from 'graphql/generated';
import { AvailabilityStatusType, AvailabilityType } from 'types/availability';

const mapAvailabilityStatus = (availabilityStatus: string): AvailabilityStatusType => {
    if (['in-stock', 'out-of-stock', 'immediately'].includes(availabilityStatus)) {
        return availabilityStatus as AvailabilityStatusType;
    }

    return 'out-of-stock' as AvailabilityStatusType;
};

export const mapAvailabilityData = (availabilityApiData: AvailabilityFragmentApi): AvailabilityType => {
    return {
        ...availabilityApiData,
        status: mapAvailabilityStatus(availabilityApiData.status),
    };
};
