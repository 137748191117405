import { RefObject, useEffect } from 'react';
import * as smoothscroll from 'smoothscroll-polyfill';
import { canUseDom } from 'helpers/canUseDom';

export const useScrollTo = (): ((value: RefObject<HTMLElement | undefined> | number) => void) => {
    const headerFixedHeight = canUseDom()
        ? parseInt(document.getElementById('header')?.dataset.headerfixedsize ?? '0')
        : 0;

    const scrollTo = (value: RefObject<HTMLElement | undefined> | number) => {
        if (typeof value === 'number') {
            window.scrollTo({ top: value, behavior: 'smooth' });
        } else if (value.current !== null && value.current !== undefined) {
            window.scrollTo({
                top: value.current.getBoundingClientRect().top + window.scrollY - headerFixedHeight,
                behavior: 'smooth',
            });
        }
    };

    useEffect(() => {
        smoothscroll.polyfill();
    }, []);

    return scrollTo;
};
