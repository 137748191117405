import { useCurrentUserData } from 'hooks/user/useCurrentUserData';
import { FC, memo, useState } from 'react';
import twMerge from 'utils/twMerge';
import { UserIcon, SuccessIcon } from 'components/Basic/Icon/IconsSvg';
import dynamic from 'next/dynamic';
import { ToggleContent } from 'components/Basic/ToggleContent/ToggleContent';

const UserDropdown = dynamic(
    () => import('components/Layout/Header/UserDropdown/UserDropdown').then((component) => component.UserDropdown),
    { ssr: false },
);

const LoginPopup = dynamic(
    () => import('components/Blocks/Popup/LoginPopup/LoginPopup').then((component) => component.LoginPopup),
    { ssr: false },
);

type HeaderUserProps = {
    MenuIconicItemLink_twClass: string;
    MenuIconicItemIcon_twClass: string;
};

export const HeaderUser: FC<HeaderUserProps> = memo(({ MenuIconicItemLink_twClass, MenuIconicItemIcon_twClass }) => {
    const { isUserLoggedIn } = useCurrentUserData();
    const [isLoginPopupOpened, setIsLoginPopupOpened] = useState(false);

    return (
        <ToggleContent
            placement="bottom"
            variant="simple"
            hover={false}
            dropdownIndex={999}
            click
            setOpenParent={setIsLoginPopupOpened}
            component={<>{isLoginPopupOpened && isUserLoggedIn ? <UserDropdown /> : <LoginPopup />}</>}
        >
            <a
                className={twMerge(
                    MenuIconicItemLink_twClass,
                    isLoginPopupOpened && 'bg-white no-underline shadow-secondary',
                )}
            >
                <UserIcon className={MenuIconicItemIcon_twClass} dataTestid="basic-icon-iconsvg-User" />
                {isUserLoggedIn && (
                    <SuccessIcon
                        className="absolute bottom-[9px] right-[7px] text-green"
                        dataTestid="basic-icon-iconsvg-Success"
                    />
                )}
            </a>
        </ToggleContent>
    );
});

HeaderUser.displayName = 'HeaderUser';
