import Image from 'components/Basic/Image/Image';
import { isElementVisible } from 'components/Helpers/isElementVisible';
import { Webline } from 'components/Layout/Webline/Webline';
import { desktopFirstSizes } from 'components/Theme/mediaQueries';
import { useGetWindowSize } from 'hooks/ui/UseGetWindowSize';
import { useResizeWidthEffect } from 'hooks/ui/UseResizeWidthEffect';
import { FC, Fragment, HTMLAttributes, ImgHTMLAttributes, useState } from 'react';
import { ExtractNativePropsFromDefault } from 'typeHelpers/ExtractNativePropsFromDefault';
import { AdvertType } from 'types/advert';
import { ExtendedNextLink } from 'components/Basic/ExtendedNextLink/ExtendedNextLink';
import twMerge from 'utils/twMerge';

type NativeProps = ExtractNativePropsFromDefault<HTMLAttributes<HTMLDivElement>, never, 'className'>;

export type AdvertsContentProps = {
    withGapTop?: boolean;
    withGapBottom?: boolean;
    withWebline?: boolean;
    secondImageBreakpoint?: 'tablet';
    advertsData: AdvertType[];
    imageSizes?: string | undefined;
    className?: string | undefined;
    loading?: ImgHTMLAttributes<HTMLImageElement>['loading'];
    wrapperClassName?: string | undefined;
};

export const isAdvertImageMissing = (advertsData: AdvertType[], isMobile: boolean | null): boolean => {
    for (const advertItem of advertsData) {
        if (
            advertItem.__typename === 'AdvertImage' &&
            ((isMobile && advertItem.imageMobile === null) || (!isMobile && advertItem.image === null))
        ) {
            return true;
        }
    }
    return false;
};

export const AdvertsContent: FC<AdvertsContentProps & NativeProps> = ({
    withGapTop,
    withGapBottom,
    withWebline,
    secondImageBreakpoint,
    advertsData,
    imageSizes,
    className,
    wrapperClassName,
    loading = 'lazy',
}) => {
    const [isMobile, setIsMobile] = useState<boolean | null>(null);
    const { width } = useGetWindowSize();
    const WrapperComponent = withWebline ? Webline : Fragment;
    const secondImageBreakpointValue =
        secondImageBreakpoint === 'tablet' ? desktopFirstSizes.tablet : desktopFirstSizes.mobile;

    useResizeWidthEffect(
        width,
        secondImageBreakpointValue,
        () => setIsMobile(false),
        () => setIsMobile(true),
        () => setIsMobile(isElementVisible([{ min: 0, max: secondImageBreakpointValue }], width)),
    );

    return (
        <WrapperComponent>
            <div className={wrapperClassName}>
                <div
                    className={twMerge(
                        withGapBottom && 'mb-8',
                        withGapTop && 'mt-8',
                        '[&_.login-popup-title]:mb-2 [&_.login-popup-title]:text-[17px] [&_.login-popup-title]:lg:mb-[30px] [&_img]:!relative',
                        '[&_.list-with-plus]:mb-4 [&_.list-with-plus]:flex [&_.list-with-plus]:flex-wrap [&_.list-with-plus]:lg:mb-[30px]',
                        '[&_.list-with-plus_li]:relative [&_.list-with-plus_li]:mb-3 [&_.list-with-plus_li]:w-full [&_.list-with-plus_li]:pl-5 [&_.list-with-plus_li]:text-small [&_.list-with-plus_li]:font-semiBold [&_.list-with-plus_li]:leading-4',
                        '[&_.list-with-plus_li:last-child]:mb-0 [&_.list-with-plus_li]:lg:mb-5',
                        '[&_.list-with-plus_li]:before:absolute [&_.list-with-plus_li]:before:left-0 [&_.list-with-plus_li]:before:top-0 [&_.list-with-plus_li]:before:text-[20px] [&_.list-with-plus_li]:before:font-base [&_.list-with-plus_li]:before:text-green [&_.list-with-plus_li]:before:content-["+"]',
                        className,
                    )}
                >
                    {advertsData.map((item, index) => {
                        return item.__typename === 'AdvertImage' ? (
                            <Fragment key={index}>
                                {(item.imageMobile !== null || item.image !== null) && (
                                    <>
                                        {item.link !== undefined ? (
                                            <ExtendedNextLink href={item.link} type="static">
                                                <>
                                                    {isMobile === true && item.imageMobile !== null && (
                                                        <Image
                                                            fill
                                                            src={item.imageMobile.url}
                                                            alt={item.name}
                                                            loading={loading}
                                                            id={item.gtmId ?? undefined}
                                                            sizes={imageSizes}
                                                        />
                                                    )}
                                                    {isMobile === false && item.image !== null && (
                                                        <Image
                                                            fill
                                                            src={item.image.url}
                                                            sizes={imageSizes}
                                                            loading={loading}
                                                            id={item.gtmId ?? undefined}
                                                            alt={item.name}
                                                        />
                                                    )}
                                                </>
                                            </ExtendedNextLink>
                                        ) : (
                                            <>
                                                {isMobile === true && item.imageMobile !== null && (
                                                    <Image
                                                        fill
                                                        src={item.imageMobile.url}
                                                        alt={item.name}
                                                        loading={loading}
                                                        id={item.gtmId ?? undefined}
                                                        sizes={imageSizes}
                                                    />
                                                )}
                                                {isMobile === false && item.image !== null && (
                                                    <Image
                                                        fill
                                                        src={item.image.url}
                                                        alt={item.name}
                                                        loading={loading}
                                                        id={item.gtmId ?? undefined}
                                                        sizes={imageSizes}
                                                    />
                                                )}
                                            </>
                                        )}
                                    </>
                                )}
                            </Fragment>
                        ) : (
                            <div dangerouslySetInnerHTML={{ __html: item.code }} key={index} />
                        );
                    })}
                </div>
            </div>
        </WrapperComponent>
    );
};

AdvertsContent.displayName = 'AdvertsContent';
