import { useNavigationItems } from 'connectors/navigation/Navigation';
import { createContext, CSSProperties, FC, useEffect, useRef, useState } from 'react';
import { DropdownItemType, DropdownListLevels } from 'types/dropdown';
import * as smoothscroll from 'smoothscroll-polyfill';
import { PrimaryList } from './PrimaryList';
import { SecondaryList } from './SecondaryList';
import { SubMenu } from './SubMenu';
import { twJoin } from 'tailwind-merge';
import { Transition } from 'react-transition-group';
import { useGetWindowSize } from 'hooks/ui/UseGetWindowSize';
import { HamburgerMenu } from '../HamburgerMenu';
import { MenuIconic } from '../MenuIconic';
import { useGetMobileBottomGap } from '../../../../hooks/ui/useGetMobileBottomGap';

export const DropdownMenuContext = createContext<{
    scrollToTop: () => void;
    slideRight: (props: DropdownItemType) => void;
    slideLeft: (props: { goToMenu: DropdownListLevels }) => void;
    onMenuToggleHandler: () => void;
}>({
    scrollToTop: () => undefined,
    slideRight: () => undefined,
    slideLeft: () => undefined,
    onMenuToggleHandler: () => undefined,
});

type DropdownMenuProps = {
    isMenuOpened: boolean;
    actionDiscountsLink: string;
    windowHeight: number | null;
    onMenuToggleHandler: () => void;
    wishlistUuid: string | null;
};

const TEST_IDENTIFIER = 'layout-header-dropdownmenu';

export const DropdownMenu: FC<DropdownMenuProps> = (props) => {
    const { navigationItems } = useNavigationItems();
    const [menuLevel, setMenuLevel] = useState<DropdownListLevels | undefined>('primary');
    const [navigationItemUuid, setNavigationItemUuid] = useState<undefined | string>();
    const cssTransitionRef = useRef<HTMLDivElement>(null);
    const [menuHeight, setMenuHeight] = useState<number>();
    const { height } = useGetWindowSize();

    const calcHeight = () => {
        setMenuHeight(height);
    };

    const slideLeft = (props: { goToMenu: DropdownListLevels }) => {
        setMenuLevel(props.goToMenu);
    };

    const slideRight = (props: DropdownItemType) => {
        setMenuLevel(props.goToMenu);
        setNavigationItemUuid(props.navigationItemUuid);
    };

    const scrollToTop = () => {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        smoothscroll.polyfill();
    }, []);

    const transitionPrimaryStyles: { [key: string]: CSSProperties } = {
        exited: { position: 'fixed', transform: 'translateX(-110%)' },
        entering: { position: 'fixed', transform: 'translateX(0%)' },
        entered: {},
        exiting: { position: 'fixed', transform: 'translateX(-110%)' },
        unmounted: {},
    };

    const transitionSecondaryStyles: { [key: string]: CSSProperties } = {
        exited: { position: 'fixed', transform: 'translateX(110%)' },
        entering: { position: 'fixed', transform: 'translateX(0%)' },
        entered: {},
        exiting: { position: 'fixed', transform: 'translateX(110%)' },
        unmounted: {},
    };

    const transitionDuration = 500;

    useEffect(() => {
        let timeoutID: NodeJS.Timeout;
        if (!props.isMenuOpened) {
            setMenuHeight(0);
            timeoutID = setTimeout(() => setMenuHeight(undefined), transitionDuration);
        } else {
            timeoutID = setTimeout(() => setMenuHeight(height), transitionDuration);
        }

        return () => clearTimeout(timeoutID);
    }, [height, props.isMenuOpened]);

    const dropdownMenuList_twClass = 'w-full overflow-y-auto mt-[60px]';
    const dropdownBottomGap = useGetMobileBottomGap(60);

    return (
        <DropdownMenuContext.Provider
            value={{
                scrollToTop: scrollToTop,
                slideRight: slideRight,
                slideLeft: slideLeft,
                onMenuToggleHandler: props.onMenuToggleHandler,
            }}
        >
            <div
                data-testid={TEST_IDENTIFIER}
                className={twJoin(
                    'fixed left-0 right-0 top-0 z-menu grid cursor-auto overflow-hidden bg-white shadow-menu transition-all !duration-500',
                    props.isMenuOpened ? 'grid-rows-[1fr]' : 'grid-rows-[0fr]',
                )}
                style={{ height: menuHeight }}
            >
                {props.isMenuOpened && (
                    <div className="min-h-0 min-w-0">
                        <div className="absolute left-[10px] right-[10px] top-[10px] flex">
                            <div className="flex">
                                <MenuIconic inDropdownMenu wishlistUuid={props.wishlistUuid} />
                            </div>
                            <div className="ml-auto pl-[10px] md:pl-3">
                                <HamburgerMenu onMenuToggleHandler={props.onMenuToggleHandler} isMenuOpened={true} />
                            </div>
                        </div>

                        <Transition
                            nodeRef={cssTransitionRef}
                            in={menuLevel === 'primary'}
                            timeout={transitionDuration}
                            unmountOnExit
                            onEntering={calcHeight}
                        >
                            {(state) => (
                                <div
                                    ref={cssTransitionRef}
                                    className="left-0 right-0 overflow-hidden ui-transition"
                                    style={{
                                        ...transitionPrimaryStyles[state],
                                    }}
                                >
                                    <div
                                        className={dropdownMenuList_twClass}
                                        style={{ height: `${props.windowHeight ?? 0 - 60}px` }}
                                    >
                                        <PrimaryList
                                            actionDiscountsLink={props.actionDiscountsLink}
                                            navigationItems={navigationItems}
                                        />
                                        <SubMenu />
                                    </div>
                                </div>
                            )}
                        </Transition>

                        <Transition
                            nodeRef={cssTransitionRef}
                            in={menuLevel === 'secondary'}
                            timeout={transitionDuration}
                            unmountOnExit
                            onEntering={calcHeight}
                        >
                            {(state) => (
                                <div
                                    ref={cssTransitionRef}
                                    className="left-0 right-0 overflow-hidden ui-transition"
                                    style={{
                                        ...transitionSecondaryStyles[state],
                                    }}
                                >
                                    <div
                                        className={dropdownMenuList_twClass}
                                        style={{
                                            height: `${props.windowHeight ?? 0 - 60}px`,
                                            paddingBottom: `${dropdownBottomGap}px`,
                                        }}
                                    >
                                        <SecondaryList goToMenu="primary" activeCategoryUuid={navigationItemUuid} />
                                    </div>
                                </div>
                            )}
                        </Transition>
                    </div>
                )}
            </div>
        </DropdownMenuContext.Provider>
    );
};

DropdownMenu.displayName = 'DropdownMenu';
