import { useEffect, useState } from 'react';

export const useComponentMount = (): boolean => {
    const [isComponentMount, setComponentMounted] = useState(false);

    useEffect(() => {
        setComponentMounted(true);
    }, []);

    return isComponentMount;
};
