import {
    getGtmConsentInfo,
    getGtmPageInfoType,
    getGtmPageInfoTypeForFriendlyUrl,
    getGtmUserInfo,
    useGtmCartEventInfo,
} from './gtm';
import { getGtmDeviceType } from './helpers';
import { mapGtmCartItemType, mapGtmListedProductType, mapGtmProductDetailType, mapGtmProductServices } from './mappers';
import { CartItemType, CartType } from 'types/cart';
import { PaymentType } from 'types/payment';
import {
    LastVisitedProductType,
    ListedProductType,
    ProductDetailType,
    SimpleProductType,
    VariantDetailType,
} from 'types/product';
import { AutocompleteSearchType } from 'types/search';
import {
    GtmEventType,
    GtmFlowType,
    GtmFormType,
    GtmListNameType,
    GtmMessageOriginType,
    GtmMessageType,
    GtmOriginType,
    GtmPageType,
    GtmProductType,
    GtmSectionType,
} from 'types/gtm/enums';
import {
    GtmCartEventType,
    GtmChangeCartItemEventType,
    GtmMessageEventType,
    GtmPageViewEventType,
    GtmPaymentEventType,
    GtmPaymentFalseEventType,
    GtmPaymentInfoEventType,
    GtmPaymentRepeatEventType,
    GtmPaymentShippingInfoEventType,
    GtmProductClickEventType,
    GtmProductsListEventType,
    GtmProductViewEventType,
    GtmPurchaseEventOrderPartType,
    GtmPurchaseEventPaymentPartType,
    GtmPurchaseEventType,
    GtmPurchaseEventUserPartType,
    GtmSendFormEventType,
    GtmShippingDataEventType,
    GtmSuggestClickEventType,
    GtmSuggestResultEventType,
} from 'types/gtm/events';
import {
    GtmCartInfoType,
    GtmCartItemAndShippingServiceType,
    GtmPageInfoType,
    GtmReviewConsentsType,
    GtmShippingServiceType,
} from 'types/gtm/types';
import { DomainConfigType } from 'utils/Domain/Domain';
import { CurrentCustomerType } from 'types/customer';
import { useMemo } from 'react';
import { useCurrentUserData } from 'hooks/user/useCurrentUserData';
import { FriendlyUrlPageType } from 'types/friendlyUrl';
import { CartServiceFragmentApi, ListedProductAccessoriesFragmentApi } from 'graphql/generated';
import { useCurrentUserContactInformation } from 'hooks/user/useCurrentUserContactInformation';
import { ContactInformationFormType, ContactInformationType } from 'types/form';
import { useDomainConfig } from 'hooks/useDomainConfig';
import { usePersistStore } from 'store/zustand/usePersistStore';

export const getGtmCartEvent = (
    currency: string,
    value: number,
    valueWithTax: number,
    flowType: GtmFlowType,
    products: GtmCartItemAndShippingServiceType[] | undefined,
): GtmCartEventType => ({
    event: GtmEventType.cart,
    ecommerce: {
        currency,
        value,
        valueWithTax,
        flowType,
        products,
    },
    _clear: true,
});

export const getGtmShippingDataEvent = (cart: GtmCartInfoType, flowType: GtmFlowType): GtmShippingDataEventType => ({
    event: GtmEventType.shippingData,
    ecommerce: {
        currency: cart.currency,
        value: cart.value,
        valueWithTax: cart.valueWithTax,
        flowType,
        coupons: cart.coupons,
        discountAmount: cart.discountAmount,
        products: cart.products,
    },
    _clear: true,
});

export const getGtmPaymentShippingInfoEvent = (
    currency: string,
    cart: GtmCartInfoType,
    flowType: GtmFlowType,
): GtmPaymentShippingInfoEventType => ({
    event: GtmEventType.paymentShippingInfo,
    ecommerce: {
        currency,
        value: cart.value,
        valueWithTax: cart.valueWithTax,
        flowType,
        products: cart.products,
    },
    _clear: true,
});

export const getGtmPurchaseOrPaymentEvent = (
    gtmPurchaseEventOrderPart: GtmPurchaseEventOrderPartType,
    gtmPurchaseEventPaymentPart: GtmPurchaseEventPaymentPartType,
    gtmPurchaseEventUserPart: GtmPurchaseEventUserPartType,
    flowType: GtmFlowType,
    isGtmPaymentEvent = true,
): GtmPurchaseEventType | GtmPaymentEventType => ({
    event: isGtmPaymentEvent ? GtmEventType.payment : GtmEventType.purchase,
    ecommerce: {
        ...gtmPurchaseEventOrderPart,
        ...gtmPurchaseEventPaymentPart,
        flowType,
    },
    user: gtmPurchaseEventUserPart,
    _clear: true,
});

export const getGtmPurchaseEventPaymentPart = (
    isPaymentSuccessful: boolean,
    isRetriedPayment: boolean,
    paymentRetryCount: number,
    paymentType: string,
    paymentPrice: number,
    paymentPriceWithTax: number,
): GtmPurchaseEventPaymentPartType => ({
    isPaymentSuccessful,
    isRetriedPayment,
    paymentRetryCount,
    paymentType,
    paymentPrice,
    paymentPriceWithTax,
});

export const getGtmPurchaseEventOrderPart = (
    cart: CartType,
    promoCode: string | null,
    orderNumber: string,
    origin: GtmOriginType,
    flowType: GtmFlowType,
    reviewConsents: GtmReviewConsentsType,
    shippingServices: CartServiceFragmentApi[],
    domainConfig: DomainConfigType,
): GtmPurchaseEventOrderPartType => ({
    currency: cart.totalPrice.currencyCode,
    origin,
    flowType,
    id: orderNumber,
    value: cart.totalPrice.priceWithoutVat,
    valueWithTax: cart.totalPrice.priceWithVat,
    valueTax: cart.totalPrice.vatAmount,
    coupons: promoCode !== null ? [promoCode] : undefined,
    discountAmount: cart.totalDiscountPrice.priceWithoutVat,
    reviewConsents,
    products: [
        ...cart.items.map((cartItem: CartItemType, index) => mapGtmCartItemType(cartItem, index, domainConfig.url)),
        ...mapGtmProductServices(cart.items),
        ...shippingServices.map<GtmShippingServiceType>((service) => {
            const servicePrice: Pick<GtmShippingServiceType, 'price' | 'priceWithTax' | 'tax'> = {};

            if (service.price !== null) {
                servicePrice.price = Number.parseFloat(service.price.priceWithoutVat);
                servicePrice.priceWithTax = Number.parseFloat(service.price.priceWithVat);
                servicePrice.tax = Number.parseFloat(service.price.vatAmount);
            }

            return {
                id: service.uuid,
                name: service.name,
                productType: GtmProductType.shipping,
                ...servicePrice,
            };
        }),
    ],
});

export const getGtmPurchaseEventUserPart = (
    user: CurrentCustomerType | null | undefined,
    userContactInformation: ContactInformationType | ContactInformationFormType,
    domainConfig: DomainConfigType,
    ipAddress: string,
): GtmPurchaseEventUserPartType => getGtmUserInfo(user, userContactInformation, domainConfig, ipAddress);

export const getGtmPaymentFalseEvent = (orderUuid: string, failReason?: string): GtmPaymentFalseEventType => ({
    event: GtmEventType.paymentFalse,
    paymentFalse: {
        id: orderUuid,
        reason: failReason,
    },
    _clear: true,
});

export const getGtmPaymentRepeatEvent = (orderUuid: string): GtmPaymentRepeatEventType => ({
    event: GtmEventType.paymentRepeat,
    paymentRepeat: {
        id: orderUuid,
    },
    _clear: true,
});

export const getGtmSendFormEvent = (form: GtmFormType): GtmSendFormEventType => ({
    event: GtmEventType.sendForm,
    eventParameters: {
        form,
    },
    _clear: true,
});

export const getGtmProductClickEvent = (
    product: ListedProductType | SimpleProductType | LastVisitedProductType | ListedProductAccessoriesFragmentApi,
    listName: GtmListNameType,
    index: number,
    domainUrl: string,
): GtmProductClickEventType => ({
    event: GtmEventType.productClick,
    ecommerce: {
        listName,
        products: [mapGtmListedProductType(product, index, domainUrl)],
    },
    _clear: true,
});

export const getGtmProductViewEvent = (
    product: ProductDetailType | VariantDetailType,
    currencyCode: string,
    domainUrl: string,
): GtmProductViewEventType => ({
    event: GtmEventType.productView,
    ecommerce: {
        currency: currencyCode,
        value: product.price.priceWithoutVat,
        valueWithTax: product.price.priceWithVat,
        products: [mapGtmProductDetailType(product, domainUrl)],
    },
    _clear: true,
});

export const getGtmSuggestResultEvent = (
    searchResult: AutocompleteSearchType,
    keyword: string,
): GtmSuggestResultEventType => {
    const resultsCount =
        searchResult.categoriesSearch.totalCount +
        searchResult.productsSearch.totalCount +
        searchResult.brandSearch.length +
        searchResult.articlesSearch.length;
    const suggestResult: GtmSuggestResultEventType['suggestResult'] = {
        keyword,
        results: resultsCount,
        sections: {
            category: searchResult.categoriesSearch.totalCount,
            product: searchResult.productsSearch.totalCount,
            brand: searchResult.brandSearch.length,
            article: searchResult.articlesSearch.length,
        },
    };

    return {
        event: GtmEventType.suggestResult,
        suggestResult,
        _clear: true,
    };
};

export const getGtmSuggestClickEvent = (
    keyword: string,
    section: GtmSectionType,
    itemName: string,
): GtmSuggestClickEventType => ({
    event: GtmEventType.suggestClick,
    suggestClick: {
        keyword,
        itemName,
        section,
    },
    _clear: true,
});

export const useGtmStaticPageViewEvent = (pageType: GtmPageType): GtmPageViewEventType => {
    const ipAddress = usePersistStore((s) => s.ipAddress);
    const { cart, isCartLoaded } = useGtmCartEventInfo();
    const domainConfig = useDomainConfig();
    const userContactInformation = useCurrentUserContactInformation();
    const { user } = useCurrentUserData();

    return useMemo(
        () =>
            getGtmPageViewEvent(
                getGtmPageInfoType(pageType),
                cart,
                isCartLoaded,
                user,
                userContactInformation,
                domainConfig,
                ipAddress,
            ),
        [cart, domainConfig, ipAddress, isCartLoaded, pageType, user, userContactInformation],
    );
};

export const useGtmFriendlyPageViewEvent = (
    friendlyUrlPageData: FriendlyUrlPageType | null | undefined,
): GtmPageViewEventType => {
    const ipAddress = usePersistStore((s) => s.ipAddress);
    const { cart, isCartLoaded } = useGtmCartEventInfo();
    const domainConfig = useDomainConfig();
    const userContactInformation = useCurrentUserContactInformation();
    const { user } = useCurrentUserData();

    return useMemo(
        () =>
            getGtmPageViewEvent(
                getGtmPageInfoTypeForFriendlyUrl(friendlyUrlPageData),
                cart,
                isCartLoaded,
                user,
                userContactInformation,
                domainConfig,
                ipAddress,
            ),
        [friendlyUrlPageData, cart, isCartLoaded, user, userContactInformation, domainConfig, ipAddress],
    );
};

export const getGtmPageViewEvent = (
    pageInfo: GtmPageInfoType,
    cart: GtmCartInfoType | null,
    isCartLoaded: boolean,
    user: CurrentCustomerType | null | undefined,
    userContactInformation: ContactInformationType | ContactInformationFormType,
    domainConfig: DomainConfigType,
    ipAddress: string,
): GtmPageViewEventType => ({
    event: GtmEventType.pageReady,
    page: pageInfo,
    user: getGtmUserInfo(user, userContactInformation, domainConfig, ipAddress),
    device: getGtmDeviceType(),
    consent: getGtmConsentInfo(),
    currency: domainConfig.currencyCode,
    language: domainConfig.defaultLocale,
    cart,
    _clear: true,
    _isLoaded: isCartLoaded,
});

export const getGtmChangeCartItemEvent = (
    event: GtmEventType.addToCart | GtmEventType.removeFromCart,
    cartItem: CartItemType,
    listIndex: number,
    quantity: number,
    currencyCode: string,
    eventValue: number,
    eventValueWithTax: number,
    listName: GtmListNameType,
    flowType: GtmFlowType,
    domainUrl: string,
    cart?: GtmCartInfoType | null,
): GtmChangeCartItemEventType => ({
    event,
    ecommerce: {
        listName,
        currency: currencyCode,
        value: eventValue,
        valueWithTax: eventValueWithTax,
        flowType,
        products: [mapGtmCartItemType(cartItem, listIndex, domainUrl, quantity)],
    },
    cart,
    _clear: true,
});

export const getGtmPaymentInfoEvent = (
    cartInfoType: GtmCartInfoType,
    payment: PaymentType,
    flowType: GtmFlowType,
): GtmPaymentInfoEventType => ({
    event: GtmEventType.paymentInfo,
    ecommerce: {
        value: cartInfoType.value,
        valueWithTax: cartInfoType.valueWithTax,
        products: cartInfoType.products ?? [],
        currency: cartInfoType.currency,
        paymentType: payment.name,
        paymentPrice: payment.price.priceWithoutVat,
        paymentPriceWithTax: payment.price.priceWithVat,
        coupons: cartInfoType.coupons,
        discountAmount: cartInfoType.discountAmount,
        flowType,
    },
    _clear: true,
});

export const getGtmProductsListEvent = (
    products: ListedProductType[] | LastVisitedProductType[] | ListedProductAccessoriesFragmentApi[],
    listName: GtmListNameType,
    currentPage: number,
    pageSize: number,
    domainUrl: string,
): GtmProductsListEventType => ({
    event: GtmEventType.productsList,
    ecommerce: {
        listName,
        products: products.map((product, index) => {
            const listedProductIndex = (currentPage - 1) * pageSize + index;

            return mapGtmListedProductType(product, listedProductIndex, domainUrl);
        }),
    },
    _clear: true,
});

export const getGtmMessageEvent = (
    type: GtmMessageType,
    message: string,
    detail: string,
    origin?: GtmMessageOriginType,
): GtmMessageEventType => ({
    event: GtmEventType.message,
    eventParameters: {
        type,
        origin,
        detail,
        message,
    },
    _clear: true,
});
