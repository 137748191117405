import { getRandomPageId } from './helpers';
import { mapGtmCartItemType } from './mappers';
import { useCurrentCart } from 'connectors/cart/Cart';
import SHA256 from 'crypto-js/sha256';
import { useCurrentUserData } from 'hooks/user/useCurrentUserData';
import { useMemo } from 'react';
import { BreadcrumbItemType } from 'types/breadcrumb';
import { CurrentCustomerType, CustomerTypeEnum } from 'types/customer';
import { FriendlyUrlPageType } from 'types/friendlyUrl';
import { gtmElementIds } from 'config/constants';

import {
    GtmCartInfoType,
    GtmCartItemType,
    GtmConsentInfoType,
    GtmPageInfoType,
    GtmReviewConsentsType,
    GtmUserInfoType,
} from 'types/gtm/types';
import { GtmConsent, GtmEventType, GtmListNameType, GtmPageType, GtmUserStatus, GtmUserType } from 'types/gtm/enums';
import { getInternationalizedStaticUrls } from 'utils/getInternationalizedStaticUrls';
import { canUseDom } from 'helpers/canUseDom';
import { GtmEcommerceEventType, GtmEventInterface, GtmPageViewEventType, GtmSearchEventType } from 'types/gtm/events';
import { CartType } from 'types/cart';
import { DomainConfigType } from 'utils/Domain/Domain';
import { ContactInformationFormType, ContactInformationType } from 'types/form';
import { Locale } from 'helpers/locale';
import { usePersistStore } from 'store/zustand/usePersistStore';
import { useDomainConfig } from 'hooks/useDomainConfig';
import { CategoryTypeEnumApi } from '../../graphql/generated';
import { DropdownLocationEnumType } from 'components/Blocks/Product/AddToCart/Dropdown';

export const useGtmCartEventInfo = (): { cart: GtmCartInfoType | null; isCartLoaded: boolean } => {
    const { cart, isInitiallyLoaded, promoCode } = useCurrentCart();
    const cartUuid = usePersistStore((s) => s.cartUuid);
    const { isUserLoggedIn } = useCurrentUserData();
    const domainConfig = useDomainConfig();

    return useMemo(() => {
        if ((cartUuid === null && !isUserLoggedIn) || cart === null) {
            return { cart: null, isCartLoaded: isInitiallyLoaded };
        }

        return {
            cart: getGtmMappedCart(cart, promoCode, isUserLoggedIn, domainConfig, cartUuid),
            isCartLoaded: isInitiallyLoaded,
        };
    }, [cart, cartUuid, domainConfig, isInitiallyLoaded, isUserLoggedIn, promoCode]);
};

export const useGtmDemandCartEventInfo = (
    isDemandCart: boolean,
): { demandCart: GtmCartInfoType | null; isDemandCartLoaded: boolean } => {
    const { cart: demandCart, isInitiallyLoaded, promoCode } = useCurrentCart(true, true, !isDemandCart);
    const demandCartUuid = usePersistStore((s) => s.demandCartUuid);
    const { isUserLoggedIn } = useCurrentUserData();
    const domainConfig = useDomainConfig();

    return useMemo(() => {
        if ((demandCartUuid === null && !isUserLoggedIn) || demandCart === null) {
            return { demandCart: null, isDemandCartLoaded: isInitiallyLoaded };
        }

        return {
            demandCart: getGtmMappedCart(demandCart, promoCode, isUserLoggedIn, domainConfig, demandCartUuid),
            isDemandCartLoaded: isInitiallyLoaded,
        };
    }, [demandCart, demandCartUuid, domainConfig, isInitiallyLoaded, isUserLoggedIn, promoCode]);
};

export const getGtmMappedCart = (
    cart: CartType,
    promoCode: string | null,
    isUserLoggedIn: boolean,
    domain: DomainConfigType,
    cartUuid: string | null,
): GtmCartInfoType => {
    let products: GtmCartItemType[] | undefined = undefined;
    if (cart.items.length > 0) {
        products = cart.items.map((cartItem, index) => mapGtmCartItemType(cartItem, index, domain.url));
    }

    let urlCart;
    if (isUserLoggedIn) {
        const [loginRelativeUrl, cartRelativeUrl] = getInternationalizedStaticUrls(['/login/', '/cart/'], domain.url);
        const loginAbsoluteUrlWithoutLeadingSlash = loginRelativeUrl.slice(1);
        urlCart = domain.url + loginAbsoluteUrlWithoutLeadingSlash + '?r=' + cartRelativeUrl;
    } else {
        const [abandonedCartRelativeUrl] = getInternationalizedStaticUrls(
            [{ url: '/abandoned-cart/:cartUuid/', param: cartUuid }],
            domain.url,
        );
        const abandonedCartRelativeUrlWithoutLeadingSlash = abandonedCartRelativeUrl.slice(1);
        urlCart = domain.url + abandonedCartRelativeUrlWithoutLeadingSlash;
    }

    const mappedCart: GtmCartInfoType = {
        urlCart,
        currency: domain.currencyCode,
        value: cart.totalItemsPrice.priceWithoutVat,
        valueWithTax: cart.totalItemsPrice.priceWithVat,
        discountAmount: cart.totalDiscountPrice.priceWithoutVat,
        products,
    };

    if (promoCode !== null) {
        mappedCart.coupons = [promoCode];
    }

    return mappedCart;
};

export const getGtmPageInfoTypeForFriendlyUrl = (
    friendlyUrlPageData: FriendlyUrlPageType | null | undefined,
): GtmPageInfoType => {
    const defaultPageInfo = getGtmPageInfoType(GtmPageType.notFound, friendlyUrlPageData?.breadcrumb);

    if (friendlyUrlPageData === null || friendlyUrlPageData === undefined) {
        return defaultPageInfo;
    }

    switch (friendlyUrlPageData.__typename) {
        case 'RegularProduct':
        case 'Variant':
            defaultPageInfo.type = GtmPageType.product;
            break;
        case 'BasicCategory':
        case 'PreListCategory':
        case 'PreListFurnitureCategory':
            defaultPageInfo.type = getCategoryOrSeoCategoryGtmPageType(
                friendlyUrlPageData.originalCategorySlug,
                friendlyUrlPageData.type,
            );

            defaultPageInfo.category = friendlyUrlPageData.breadcrumb.map((item: BreadcrumbItemType) => {
                return item.name;
            });
            defaultPageInfo.categoryId = [friendlyUrlPageData.id];
            defaultPageInfo.zboziCategory = friendlyUrlPageData.zboziCzCategoryPath ?? undefined;
            break;
        case 'Article':
            defaultPageInfo.type = GtmPageType.text;
            break;
        case 'BlogArticle':
            defaultPageInfo.type = GtmPageType.article;
            defaultPageInfo.articleId = friendlyUrlPageData.id;
            break;
        case 'BlogCategory':
            defaultPageInfo.type = GtmPageType.blog;
            break;
        case 'Flag':
            defaultPageInfo.type = GtmPageType.flag;
            break;
        case 'Brand':
            defaultPageInfo.type = GtmPageType.brand;
            defaultPageInfo.brandId = friendlyUrlPageData.id;
            break;
        default:
            break;
    }

    return defaultPageInfo;
};

export const getGtmPageInfoType = (pageType: GtmPageType, breadcrumbs?: BreadcrumbItemType[]): GtmPageInfoType => ({
    type: pageType,
    pageId: getRandomPageId(),
    breadcrumbs: breadcrumbs ?? [],
});

export const gtmSafePushEvent = (
    event: GtmEventInterface<GtmEventType, unknown> | GtmPageViewEventType | GtmEcommerceEventType | GtmSearchEventType,
): void => {
    if (canUseDom()) {
        window.dataLayer = window.dataLayer ?? [];
        window.dataLayer.push(event);
    }
};

export const getGtmReviewConsents = (): GtmReviewConsentsType => ({
    google: true,
    seznam: true,
    heureka: true,
});

export const getGtmConsentInfo = (): GtmConsentInfoType => ({
    marketing: GtmConsent.granted,
    statistics: GtmConsent.granted,
    preferences: GtmConsent.granted,
});

export const getGtmUserInfo = (
    currentSignedInCustomer: CurrentCustomerType | null | undefined,
    userContactInformation: ContactInformationType | ContactInformationFormType,
    domainConfig: DomainConfigType,
    ipAddress: string,
): GtmUserInfoType => {
    const userInfo: GtmUserInfoType = getGtmUserInfoForVisitor(userContactInformation, domainConfig, ipAddress);

    if (currentSignedInCustomer !== undefined && currentSignedInCustomer !== null) {
        overwriteGtmUserInfoWithLoggedCustomer(userInfo, currentSignedInCustomer, userContactInformation);
    }

    return userInfo;
};

const getGtmUserInfoForVisitor = (
    userContactInformation: ContactInformationType | ContactInformationFormType,
    domainConfig: DomainConfigType,
    ipAddress: string,
) => ({
    ipAddress,
    status: GtmUserStatus.visitor,
    ...(!!userContactInformation.city.length && { city: userContactInformation.city }),
    ...(!!userContactInformation.email.length && { email: userContactInformation.email }),
    ...(!!userContactInformation.email.length && { emailHash: SHA256(userContactInformation.email).toString() }),
    ...(!!userContactInformation.firstName.length && { name: userContactInformation.firstName }),
    ...(!!userContactInformation.telephone.length && { phoneNumber: userContactInformation.telephone }),
    ...(!!userContactInformation.postcode.length && { psc: userContactInformation.postcode }),
    ...(!!userContactInformation.street.length && { street: userContactInformation.street }),
    ...(!!userContactInformation.lastName.length && { surname: userContactInformation.lastName }),
    ...('country' in userContactInformation &&
        !!userContactInformation.country.length && { country: userContactInformation.country }),
    type: getGtmUserType(userContactInformation, domainConfig),
});

const getGtmUserType = (
    userContactInformation: ContactInformationType | ContactInformationFormType,
    domainConfig: DomainConfigType,
): GtmUserType | undefined => {
    const isGermanDomain = domainConfig.domainId === 4 && domainConfig.defaultLocale === Locale.DE;
    const isCompanyNameFilled = userContactInformation.companyName.length > 0;
    const isCompanyNumberFilled = userContactInformation.companyNumber.length > 0;
    const isCompanyCustomer = userContactInformation.customer === CustomerTypeEnum.CompanyCustomer;

    if (!isCompanyNameFilled && !isCompanyNumberFilled && userContactInformation.customer === undefined) {
        return undefined;
    }

    if (((isGermanDomain && isCompanyNameFilled) || isCompanyNumberFilled) && isCompanyCustomer) {
        return GtmUserType.b2b;
    }

    return GtmUserType.b2c;
};

const overwriteGtmUserInfoWithLoggedCustomer = (
    userInfo: GtmUserInfoType,
    currentSignedInCustomer: CurrentCustomerType,
    userContactInformation: ContactInformationType | ContactInformationFormType,
) => {
    userInfo.status = GtmUserStatus.customer;
    userInfo.id = currentSignedInCustomer.uuid;
    userInfo.group = currentSignedInCustomer.pricingGroup;

    if (!userInfo.street?.length) {
        userInfo.street = currentSignedInCustomer.street;
    }
    if (!userInfo.city?.length) {
        userInfo.city = currentSignedInCustomer.city;
    }
    if (!userInfo.psc?.length) {
        userInfo.psc = currentSignedInCustomer.postcode;
    }
    if (!userInfo.country?.length) {
        userInfo.country = currentSignedInCustomer.country;
    }
    if (!userInfo.email?.length) {
        userInfo.email = userContactInformation.email || currentSignedInCustomer.email;
    }
    if (!userInfo.emailHash?.length) {
        userInfo.emailHash = SHA256(currentSignedInCustomer.email).toString();
    }
    if (!userInfo.phoneNumber?.length) {
        userInfo.phoneNumber = currentSignedInCustomer.telephone;
    }
    if (!userInfo.name?.length) {
        userInfo.name = currentSignedInCustomer.firstName;
    }
    if (!userInfo.surname?.length) {
        userInfo.surname = currentSignedInCustomer.lastName;
    }
};

export const getCategoryOrSeoCategoryGtmListName = (
    originalCategorySlug: string | null,
): GtmListNameType.seoCategory | GtmListNameType.category =>
    originalCategorySlug !== null ? GtmListNameType.seoCategory : GtmListNameType.category;

export const getCategoryOrSeoCategoryGtmPageType = (
    originalCategorySlug: string | null,
    categoryType: CategoryTypeEnumApi,
): GtmPageType.seoCategory | GtmPageType.crossroad | GtmPageType.category => {
    if (originalCategorySlug !== null) {
        return GtmPageType.seoCategory;
    }

    if (categoryType === CategoryTypeEnumApi.PreListApi || categoryType === CategoryTypeEnumApi.PreListFurnitureApi) {
        return GtmPageType.crossroad;
    }

    return GtmPageType.category;
};

export const getGtmElementIdByLocation = (
    gtmElement: 'share' | 'compare' | 'wishlist',
    location: DropdownLocationEnumType | undefined,
): string => {
    if (gtmElement === 'share') {
        return location === DropdownLocationEnumType.PRODUCT_DETAIL
            ? gtmElementIds.SHARE_PRODUCT_DETAIL
            : gtmElementIds.SHARE_PRODUCT_LIST;
    }

    if (gtmElement === 'compare') {
        return location === DropdownLocationEnumType.PRODUCT_DETAIL
            ? gtmElementIds.COMPARE_PRODUCT_DETAIL
            : gtmElementIds.COMPARE_PRODUCT_LIST;
    }

    // wishlist
    return location === DropdownLocationEnumType.PRODUCT_DETAIL
        ? gtmElementIds.WISHLIST_PRODUCT_DETAIL
        : gtmElementIds.WISHLIST_PRODUCT_LIST;
};
