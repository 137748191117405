import { useTypedTranslationFunction } from 'hooks/typescript/UseTypedTranslationFunction';
import { FC } from 'react';
import { useShopInfo } from 'connectors/shopInfo/ShopInfo';
import { twJoin } from 'tailwind-merge';
import { PhoneIcon } from 'components/Basic/Icon/IconsSvg';

const TEST_IDENTIFIER = 'layout-footer-footerboxinfo';

export const FooterBoxInfo: FC = () => {
    const t = useTypedTranslationFunction();
    const shopInfoData = useShopInfo();

    return (
        <div
            className={twJoin(
                'relative flex flex-col items-center justify-center border-b-2 border-border py-7',
                'vl:static vl:flex-row vl:py-[45px]',
            )}
            data-testid={TEST_IDENTIFIER}
        >
            <div className="mb-3 flex items-center vl:mb-0 vl:flex-row">
                <PhoneIcon className="mr-[10px] h-6 w-6 text-green" />
                <a
                    className={twJoin(
                        'text-big font-bold text-green no-underline',
                        'hover:text-green hover:no-underline',
                        'vl:mr-[121px] vl:text-[32px]',
                    )}
                    href={'tel:' + shopInfoData?.telephone}
                >
                    {shopInfoData?.telephone}
                </a>
            </div>
            <div className="flex flex-col items-center vl:flex-row vl:flex-wrap vl:items-baseline">
                <div className="mb-[10px] text-big font-bold text-greyMedium vl:mb-0 vl:mr-6">{t('Call for free')}</div>
                <div className="text-littleSmall text-greyMedium">{shopInfoData?.phoneHours}</div>
            </div>
        </div>
    );
};

FooterBoxInfo.displayName = 'FooterBoxInfo';
