import { mapConnectionEdges } from 'connectors/connection/Connection';
import { getFirstImage } from 'connectors/image/Image';
import { mapPageInfoApiData } from 'connectors/pageInfo/PageInfo';
import {
    BlogArticleConnectionFragmentApi,
    BlogArticleDetailFragmentApi,
    ListedBlogArticleFragmentApi,
    SimpleBlogArticleFragmentApi,
    useBlogArticlesQueryApi,
} from 'graphql/generated';
import { useQueryError } from 'hooks/graphQl/UseQueryError';
import {
    BlogArticleConnectionType,
    BlogArticleDetailType,
    ListedBlogArticleType,
    SimpleBlogArticleType,
} from 'types/blogArticle';
import { DomainConfigType } from 'utils/Domain/Domain';
import { formatDateToLocaleString } from 'helpers/formaters/formatDate';
import { useDomainConfig } from 'hooks/useDomainConfig';

export const useBlogPreviewArticles = (): ListedBlogArticleType[] => {
    const [{ data, error }] = useBlogArticlesQueryApi({ variables: { first: 4, onlyHomepageArticles: true } });
    const domainConfig = useDomainConfig();

    useQueryError(error);

    if (data?.blogArticles.edges === undefined || data.blogArticles.edges === null) {
        return [];
    }

    return mapConnectionEdges<ListedBlogArticleFragmentApi, ListedBlogArticleType>(
        data.blogArticles.edges,
        mapListedBlogArticle,
        domainConfig,
    );
};

export const mapBlogArticleDetail = (
    apiData: BlogArticleDetailFragmentApi,
    domainConfig: DomainConfigType,
): BlogArticleDetailType => {
    return {
        ...apiData,
        __typename: 'BlogArticle',
        perex: apiData.perex ?? '',
        image: getFirstImage(apiData.blogArticlesGridImages),
        publishDate: formatDateToLocaleString(apiData.publishDate, domainConfig.defaultLocale),
        publishDateRaw: apiData.publishDate,
        seoMetaDescription: apiData.seoMetaDescription ?? '',
    };
};

export const mapBlogArticleConnection = (
    apiData: BlogArticleConnectionFragmentApi | null,
    domainConfig: DomainConfigType,
): BlogArticleConnectionType | null => {
    if (apiData === null) {
        return null;
    }

    return {
        ...apiData,
        pageInfo: mapPageInfoApiData(apiData.pageInfo),
        edges: mapConnectionEdges<ListedBlogArticleFragmentApi, ListedBlogArticleType>(
            apiData.edges,
            mapListedBlogArticle,
            domainConfig,
        ),
    };
};

export const mapListedBlogArticle = (
    apiData: ListedBlogArticleFragmentApi,
    domainConfig: DomainConfigType,
): ListedBlogArticleType => {
    return {
        ...apiData,
        image: getFirstImage(apiData.images),
        publishDate: formatDateToLocaleString(apiData.publishDate, domainConfig.defaultLocale),
    };
};

export const mapSimpleBlogArticle = (apiData: SimpleBlogArticleFragmentApi): SimpleBlogArticleType => {
    return {
        ...apiData,
        image: getFirstImage(apiData.images),
    };
};
