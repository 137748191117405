import { PhoneIcon } from 'components/Basic/Icon/IconsSvg';
import { useShopInfo } from 'connectors/shopInfo/ShopInfo';
import { FC, HTMLAttributes, memo } from 'react';
import { twJoin } from 'tailwind-merge';
import { ExtractNativePropsFromDefault } from 'typeHelpers/ExtractNativePropsFromDefault';
import twMerge from 'utils/twMerge';

type NativeProps = ExtractNativePropsFromDefault<HTMLAttributes<HTMLDivElement>, never, 'className'>;

type HeaderContactProps = {
    inFixedHeader?: boolean;
};

export const HeaderContact: FC<NativeProps & HeaderContactProps> = memo(({ inFixedHeader }) => {
    const shopInfoData = useShopInfo();
    const testIdentifier = 'layout-header-contact';

    if (shopInfoData?.telephone === null || shopInfoData?.phoneHours === null) {
        return null;
    }

    return (
        <div
            data-testid={testIdentifier}
            className={twJoin(
                'relative flex flex-1 flex-col items-start lg:flex-row lg:items-center lg:justify-between',
                inFixedHeader && 'mr-3 hidden xl:flex',
            )}
        >
            <div
                className={twMerge(
                    'flex flex-wrap items-baseline lg:flex-1 xl:justify-center',
                    inFixedHeader && 'flex-col pl-[21px]',
                )}
            >
                <div className={twJoin('relative', !inFixedHeader && 'pl-[21px]')}>
                    <PhoneIcon
                        className={twMerge(
                            'absolute left-0 top-[10px] h-[18px] w-[18px] -translate-y-1/2 text-h2 text-green',
                            inFixedHeader && 'left-[-21px]',
                        )}
                    />
                    <a
                        className="mr-[26px] text-small font-bold text-green hover:text-green vl:mr-3"
                        href={`tel:${shopInfoData?.telephone}`}
                    >
                        {shopInfoData?.telephone}
                    </a>
                </div>

                <p className="hidden vl:m-0 vl:block vl:text-littleSmall vl:text-base">{shopInfoData?.phoneHours}</p>
            </div>
        </div>
    );
});

HeaderContact.displayName = 'HeaderContact';
