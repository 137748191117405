const em = (value) => value / 16 + 'em';
const rem = (value) => value / 16 + 'rem';

/** @type {import("tailwindcss").Config} */
module.exports = {
    content: ['./components/**/*.tsx', './pages/**/*.tsx'],
    safelist: ['in-stock', 'immediately', 'out-of-stock', 'to-delivery', 'opacity-0'],
    theme: {
        transitionTimingFunction: {
            default: 'cubic-bezier(.8, .20, .48, 1.0)',
        },
        colors: {
            base: '#08131f',
            baseAlt: '#0d0d0d',
            primary: '#004386',
            primaryDarken: '#002953',
            white: '#fff',
            black: '#000',
            orange: '#f19f26',
            orangeLight: '#fbe2be',
            orangeDarken: '#db9226',
            border: '#d8dee5',
            borderDarker: '#e4e5ec',
            red: '#f12626',
            redLight: '#fddcdc',
            green: '#36ab72',
            greenDark: '#2a8459',
            greenLight: '#5ebb8e',
            greenVeryLight: '#daf2ea',
            grey: '#646a8A',
            greyDark: '#656565',
            greyDark2: '#d5d7da',
            greyMedium: '#8c98ac',
            greyLight: '#a4a7c1',
            greyLight2: '#c4c4c4',
            greyExtremeLight: '#fafafa',
            greyVeryLight: '#f0f1f2',
            greyLighter: '#e3e4ec',
            blueMedium: '#32689e',
            blueMediumLight: '#dfeaf2',
            blueLight: '#f6fAff',
            creamWhite: '#f9f9f9',
            outOfStock: '#989898',
            toDelivery: '#53c4e2',
            purpleLight: '#cdb3ff',
        },
        fontFamily: {
            base: ['var(--font-poppins)'],
        },
        fontSize: {
            h1: [rem(36), rem(42)],
            h2: [rem(28), rem(38)],
            h3: [rem(24), rem(32)],
            h4: [rem(20), rem(26)],
            h5: [rem(16), rem(26)],
            h6: [rem(13), rem(19)],
            description: [rem(16), 1.3],
            big: [rem(20)],
            bigger: [rem(18)],
            default: [rem(16)],
            small: [rem(14)],
            littleSmall: [rem(13)],
            extraSmall: [rem(12)],
            extraVerySmall: [rem(11)],
        },
        fontWeight: {
            base: 400,
            semiBold: 500,
            thick: 600,
            bold: 700,
        },
        screens: {
            xs: em(320),
            sm: em(480),
            md: em(600),
            lg: em(769),
            dlg: { max: '768px' },
            vl: em(1024),
            xl: em(1240),
            xxl: em(1560),
        },
        zIndex: {
            hidden: -1000,
            above: 1,
            'above-1': 2,
            'above-2': 3,
            'above-11': 11,
            'above-12': 12,
            menu: 501,
            'menu-1': 502,
            aboveMenu: 750,
            cart: 6000,
            underOverlay: 9999,
            overlay: 10000,
            aboveOverlay: 10001,
            'aboveOverlay-2': 10003,
            maximum: 10100,
        },
        extend: {
            borderRadius: {
                lg: rem(8),
                md: rem(4),
                sm: rem(2),
            },
            boxShadow: {
                default: '2px 2px 24px rgba(150, 150, 150, 0.328215)',
                dropdown: '5px 5px 59px rgba(101, 101, 101, 0.328215)',
                menu: '0 5px 10px 0 rgba(164, 167, 193, 0.34)',
                green: '0 0 0 4px rgba(0, 236, 177, 0.12)',
                input: '0 0 0 1000px #fff inset',
                secondary: '0px 2px 17px rgba(184, 184, 184, 0.5)',
                productItem: '2px 2px 24px rgba(0, 0, 0, 0.1)',
                popup: '0 30px 50px rgba(0, 0, 0, 0.2)',
            },
            animation: {
                fadeIn: 'fadeIn .2s linear',
                strokeAnimationCircle: 'strokeAnimation 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards',
                strokeAnimationCheck: 'strokeAnimation 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.3s forwards',
                scaleAnimation: 'scaleAnimation 0.3s ease-in-out 0.3s both',
                toggle: 'toggleContentShow 300ms',
            },
            keyframes: {
                fadeIn: {
                    '0%': { opacity: 0 },
                    '25%': { opacity: 0.25 },
                    '50%': { opacity: 0.5 },
                    '75%': { opacity: 0.75 },
                    '100%': { opacity: 1 },
                },
                strokeAnimation: {
                    '100%': { strokeDashoffset: 0 },
                },
                scaleAnimation: {
                    '0%': { transform: 'none' },
                    '50%': { transform: 'scale3d(1.1, 1.1, 1)' },
                    '100%': { transform: 'none' },
                },
                toggleContentShow: {
                    '0%': { opacity: '0', transform: 'scale(0.9)' },
                    '100%': { opacity: '1', transform: 'translateX(0)' },
                },
            },
            transitionProperty: {
                'border-shadow-bg': 'border, box-shadow, background',
            },
        },
    },
    plugins: [
        function ({ addUtilities, addVariant, matchUtilities, theme }) {
            addUtilities({
                '.ui-transition': {
                    '@apply duration-200 ease-default': {},
                },
                '.in-stock': {
                    '@apply text-green hover:text-green': {},
                },
                '.immediately': {
                    '@apply text-green hover:text-green': {},
                },
                '.out-of-stock': {
                    '@apply text-outOfStock hover:text-outOfStock': {},
                },
                '.to-delivery': {
                    '@apply text-toDelivery hover:text-toDelivery': {},
                },
                '.ui-text-shadow': {
                    'text-shadow': '0 0 1px',
                },
            });
            addVariant('webkit-slider-thumb', '&::-webkit-slider-thumb');
            addVariant('moz-range-thumb', '&::-moz-range-thumb');
            addVariant('ms-thumb', '&::-ms-thumb');
            matchUtilities(
                {
                    borderInset: (value) => {
                        return { boxShadow: `inset 0 0 0 1px ${value}` };
                    },
                },
                {
                    values: theme('colors'),
                },
            );
            addUtilities({
                '.w-appearance-none': {
                    '-webkit-appearance': 'none;',
                },
            });
            matchUtilities(
                {
                    'bg-pos-x': (value) => ({
                        'background-position-x': value,
                    }),
                },
                { values: theme('backgroundPosition') },
            );
            matchUtilities(
                {
                    'bg-pos-y': (value) => ({
                        'background-position-y': value,
                    }),
                },
                { values: theme('backgroundPosition') },
            );
        },
    ],
};
