import { useMinimalCartQueryApi, useMinimalDemandCartQueryApi } from 'graphql/generated';
import { useTypedTranslationFunction } from 'hooks/typescript/UseTypedTranslationFunction';
import { useCurrentUserData } from 'hooks/user/useCurrentUserData';
import { usePersistStore } from 'store/zustand/usePersistStore';
import { CurrentMinimalCartType } from 'types/cart';
import { useMemo } from 'react';
import { handleCartError } from './Cart';

export const useCurrentMinimalCart = (isDemandCart = false): CurrentMinimalCartType => {
    const t = useTypedTranslationFunction();
    const { isUserLoggedIn } = useCurrentUserData();
    const { cartUuid, demandCartUuid } = usePersistStore((s) => s);
    const cartUuidValue = isDemandCart ? demandCartUuid : cartUuid;
    const [resultCartValue, refetchCartValue] = useMinimalCartQueryApi({
        variables: { cartUuid },
        pause: (cartUuid === null && !isUserLoggedIn) || isDemandCart,
        requestPolicy: 'network-only',
    });

    const [resultDemandCartValue, refetchDemandCartValue] = useMinimalDemandCartQueryApi({
        variables: { cartUuid: demandCartUuid },
        pause: (demandCartUuid === null && !isUserLoggedIn) || !isDemandCart,
        requestPolicy: 'network-only',
    });

    const result = isDemandCart ? resultDemandCartValue : resultCartValue;
    const refetchCart = isDemandCart ? refetchDemandCartValue : refetchCartValue;

    return useMemo(() => {
        if (result.error !== undefined) {
            // EXTEND CART ERRORS HERE
            handleCartError(result.error, t);
        }

        if (
            result.data === undefined ||
            result.fetching ||
            (cartUuidValue === null && !isUserLoggedIn) ||
            result.error !== undefined ||
            result.data.cart === null
        ) {
            return { modifications: null, refetchCart };
        }

        return {
            modifications: result.data.cart.modifications,
            refetchCart,
        };
    }, [cartUuidValue, isUserLoggedIn, refetchCart, result.data, result.error, result.fetching, t]);
};
