import { useShopInfo } from 'connectors/shopInfo/ShopInfo';
import { useTypedTranslationFunction } from 'hooks/typescript/UseTypedTranslationFunction';
import { FC, memo, useContext } from 'react';
import { getInternationalizedStaticUrls } from 'utils/getInternationalizedStaticUrls';
import twMerge from 'utils/twMerge';
import { twJoin } from 'tailwind-merge';
import { CompareIcon, HeartFullIcon, HeartIcon, ChatIcon } from 'components/Basic/Icon/IconsSvg';
import { usePersistStore } from 'store/zustand/usePersistStore';
import { useDomainConfig } from 'hooks/useDomainConfig';
import { useProductsCompare } from 'connectors/products/ProductsByCatnum';
import { useComponentMount } from 'hooks/ui/useComponentMount';
import { useWishlist } from 'connectors/wishlist/Wishlist';
import { ExtendedNextLink } from 'components/Basic/ExtendedNextLink/ExtendedNextLink';
import { HeaderUser } from './HeaderUser';
import { DropdownMenuContext } from './DropdownMenu/DropdownMenu';

type MenuIconicProps = {
    inDropdownMenu?: boolean;
    isFixedHeader?: boolean;
    wishlistUuid: string | null;
};

const TEST_IDENTIFIER = 'layout-header-menuiconic';

export const MenuIconic: FC<MenuIconicProps> = memo(({ inDropdownMenu, isFixedHeader, wishlistUuid }) => {
    const t = useTypedTranslationFunction();
    const context = useContext(DropdownMenuContext);
    const { url } = useDomainConfig();
    const isComponentMount = useComponentMount();
    const productsCompareStore = usePersistStore((s) => s.compareProducts);
    const { productsCompareCount } = useProductsCompare(productsCompareStore);
    const productsCompareStoreMount = isComponentMount ? productsCompareStore : [];
    const productsCompareCountMount = isComponentMount ? productsCompareCount : 0;
    const [compareProductsUrl, wishlistUrl] = getInternationalizedStaticUrls(['/compare-products/', '/wishlist/'], url);
    const shopInfoData = useShopInfo();
    const { wishlist } = useWishlist(wishlistUuid ?? null);
    const wishlistProductsCount = wishlist?.products.length ?? 0;

    const MenuIconicItem_twClass = twJoin('flex relative mr-[10px]', 'md:mr-3', 'last:mr-0');
    const MenuIconicItemLink_twClass = twJoin(
        'flex flex-row justify-center items-center h-full px-[10px] rounded-md text-littleSmall font-semiBold no-underline ui-transition bg-greyVeryLight',
        'lg:text-small lg:px-[14px]',
        '[@media(hover:hover)_and_(pointer:fine)]:hover:bg-white [@media(hover:hover)_and_(pointer:fine)]:hover:no-underline [@media(hover:hover)_and_(pointer:fine)]:hover:shadow-secondary',
    );
    const MenuIconicItemLinkWrapper_twClass = 'relative flex';
    const MenuIconicItemIcon_twClass = 'w-5 h-5 text-primary';
    const MenuIconicItemCount_twClass = twJoin(
        'flex items-center justify-center absolute right-[7px] top-[6px] h-[14px] min-w-[14px] px-[2px] rounded-[10px] bg-green text-white font-bold text-[10px]',
        'lg:-top-1 lg:-right-[5px]',
    );

    return (
        <>
            <ul className="flex" data-testid={TEST_IDENTIFIER}>
                {inDropdownMenu ? (
                    <>
                        <li className={MenuIconicItem_twClass} data-testid={TEST_IDENTIFIER + '-0'}>
                            <ExtendedNextLink
                                type="static"
                                href={{
                                    pathname: compareProductsUrl,
                                    query:
                                        productsCompareStoreMount.length > 0
                                            ? { catnums: productsCompareStoreMount.toString() }
                                            : undefined,
                                }}
                                className={MenuIconicItemLink_twClass}
                                onClick={context.onMenuToggleHandler}
                            >
                                <div className={MenuIconicItemLinkWrapper_twClass}>
                                    <CompareIcon className={MenuIconicItemIcon_twClass} />
                                    {productsCompareCountMount > 0 && (
                                        <span className={MenuIconicItemCount_twClass}>{productsCompareCountMount}</span>
                                    )}
                                </div>
                            </ExtendedNextLink>
                        </li>
                        <li className={MenuIconicItem_twClass} data-testid={TEST_IDENTIFIER + '-5'}>
                            <ExtendedNextLink
                                type="static"
                                href={wishlistUrl}
                                className={MenuIconicItemLink_twClass}
                                onClick={context.onMenuToggleHandler}
                            >
                                <div className={MenuIconicItemLinkWrapper_twClass}>
                                    {wishlistProductsCount > 0 ? (
                                        <HeartFullIcon
                                            className={twMerge(
                                                MenuIconicItemIcon_twClass,
                                                '-mx-[1px] h-[22px] w-[22px]',
                                            )}
                                        />
                                    ) : (
                                        <HeartIcon
                                            className={twMerge(
                                                MenuIconicItemIcon_twClass,
                                                '-mx-[1px] h-[22px] w-[22px]',
                                            )}
                                        />
                                    )}
                                    {wishlistProductsCount > 0 && (
                                        <span
                                            className={twMerge(MenuIconicItemCount_twClass, '-right-[4px] -top-[2px]')}
                                        >
                                            {wishlistProductsCount}
                                        </span>
                                    )}
                                </div>
                            </ExtendedNextLink>
                        </li>
                        {shopInfoData && shopInfoData.allAboutShoppingUrl && (
                            <li className={MenuIconicItem_twClass} data-testid={TEST_IDENTIFIER + '-1'}>
                                <ExtendedNextLink
                                    type="article"
                                    href={shopInfoData.allAboutShoppingUrl}
                                    className={MenuIconicItemLink_twClass}
                                    onClick={context.onMenuToggleHandler}
                                >
                                    <ChatIcon className={MenuIconicItemIcon_twClass} />
                                    <span className="ml-[6px] flex-1 leading-3">{t('All about shopping')}</span>
                                </ExtendedNextLink>
                            </li>
                        )}
                    </>
                ) : (
                    <>
                        <li
                            className={twMerge(MenuIconicItem_twClass, 'hidden xl:flex')}
                            data-testid={TEST_IDENTIFIER + '-2'}
                        >
                            <ExtendedNextLink
                                type="static"
                                href={{
                                    pathname: compareProductsUrl,
                                    query:
                                        productsCompareStoreMount.length > 0
                                            ? { catnums: productsCompareStoreMount.toString() }
                                            : undefined,
                                }}
                                className={MenuIconicItemLink_twClass}
                            >
                                <div className={MenuIconicItemLinkWrapper_twClass}>
                                    <CompareIcon className={MenuIconicItemIcon_twClass} />
                                    {productsCompareCountMount > 0 && (
                                        <span className={MenuIconicItemCount_twClass}>{productsCompareCountMount}</span>
                                    )}
                                </div>
                            </ExtendedNextLink>
                        </li>
                        <li
                            className={twMerge(MenuIconicItem_twClass, 'hidden xl:flex')}
                            data-testid={TEST_IDENTIFIER + '-5'}
                        >
                            <ExtendedNextLink type="static" href={wishlistUrl} className={MenuIconicItemLink_twClass}>
                                <div className={MenuIconicItemLinkWrapper_twClass}>
                                    {wishlistProductsCount > 0 ? (
                                        <HeartFullIcon
                                            className={twMerge(
                                                MenuIconicItemIcon_twClass,
                                                '-mx-[1px] h-[22px] w-[22px]',
                                            )}
                                        />
                                    ) : (
                                        <HeartIcon
                                            className={twMerge(
                                                MenuIconicItemIcon_twClass,
                                                '-mx-[1px] h-[22px] w-[22px]',
                                            )}
                                        />
                                    )}
                                    {wishlistProductsCount > 0 && (
                                        <span className={MenuIconicItemCount_twClass}>{wishlistProductsCount}</span>
                                    )}
                                </div>
                            </ExtendedNextLink>
                        </li>

                        {shopInfoData && shopInfoData.allAboutShoppingUrl && (
                            <li
                                className={twMerge(MenuIconicItem_twClass, 'hidden lg:flex')}
                                data-testid={TEST_IDENTIFIER + '-3'}
                            >
                                <ExtendedNextLink
                                    type="article"
                                    href={shopInfoData.allAboutShoppingUrl}
                                    className={MenuIconicItemLink_twClass}
                                >
                                    <ChatIcon className={MenuIconicItemIcon_twClass} />
                                    <span
                                        className={twJoin(
                                            'ml-[6px] flex-1 leading-3',
                                            isFixedHeader && 'hidden xl:inline',
                                        )}
                                    >
                                        {t('All about shopping')}
                                    </span>
                                </ExtendedNextLink>
                            </li>
                        )}
                        <li className={MenuIconicItem_twClass} data-testid={TEST_IDENTIFIER + '-4'}>
                            <HeaderUser
                                MenuIconicItemIcon_twClass={MenuIconicItemIcon_twClass}
                                MenuIconicItemLink_twClass={MenuIconicItemLink_twClass}
                            />
                        </li>
                    </>
                )}
            </ul>
        </>
    );
});

MenuIconic.displayName = 'MenuIconic';
