import { NavigationQueryApi, useNavigationQueryApi } from 'graphql/generated';
import { useQueryError } from 'hooks/graphQl/UseQueryError';

export function useNavigationItems(): {
    navigationItems: NavigationQueryApi['navigationPanelCategories'];
    fetching: boolean;
} {
    const [{ data, error, fetching }] = useNavigationQueryApi();
    useQueryError(error);

    if (data?.navigationPanelCategories === undefined) {
        return { navigationItems: [], fetching };
    }

    return { navigationItems: data.navigationPanelCategories, fetching };
}
