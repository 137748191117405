import AutocompleteSearch from './AutocompleteSearch/AutocompleteSearch';
import { desktopFirstSizes } from 'components/Theme/mediaQueries';
import { useGetWindowSize } from 'hooks/ui/UseGetWindowSize';
import { FC, useEffect, useRef, useState } from 'react';
import { use100vh } from 'react-div-100vh';
import { Webline } from 'components/Layout/Webline/Webline';
import { twJoin } from 'tailwind-merge';
import twMerge from 'utils/twMerge';
import { Locale } from 'helpers/locale';
import { useDomainConfig } from 'hooks/useDomainConfig';
import HeaderTabletBlock from './HeaderTabletBlock';
import HeaderNotDesktopBlock from './HeaderNotDesktopBlock';
import { Cart } from './Cart/Cart';
import { HeaderContact } from './HeaderContact';
import { Logo } from './Logo';
import { MenuIconic } from './MenuIconic';
import { canUseDom } from 'helpers/canUseDom';

type HeaderProps = {
    wishlistUuid: string | null;
};

const TEST_IDENTIFIER = 'layout-header';

export const Header: FC<HeaderProps> = ({ wishlistUuid }) => {
    const height = use100vh();
    const [isFixedHeader, setIsFixedHeader] = useState(false);
    const [isMenuOpened, setIsMenuOpened] = useState(false);
    const { width } = useGetWindowSize();
    const { defaultLocale, url } = useDomainConfig();
    const searchRef = useRef<HTMLDivElement>(null);
    const headerPositionRef = useRef<HTMLDivElement>(null);
    const actionDiscountsLink =
        defaultLocale === Locale.CS
            ? 'akce-a-slevy'
            : defaultLocale === Locale.SK
            ? 'akcie-a-zlavy'
            : defaultLocale === Locale.PL
            ? 'oferty-specjalne'
            : 'sonderangebote';

    useEffect(() => {
        const appHeight = () => {
            const doc = document.documentElement;
            doc.style.overflow = isMenuOpened ? 'hidden' : 'visible';
            doc.style.height = isMenuOpened ? `${height}px` : '100%';
        };

        window.addEventListener('resize', appHeight, { passive: true });
        appHeight();
    }, [height, isMenuOpened]);

    useEffect(() => {
        if (canUseDom()) {
            const updateSize = () => {
                setIsFixedHeader(
                    -1 * (headerPositionRef.current?.offsetHeight ?? 0) >
                        (headerPositionRef.current?.getBoundingClientRect().top ?? 0),
                );
            };
            window.addEventListener('scroll', updateSize);
            updateSize();
            return () => window.removeEventListener('scroll', updateSize);
        }
        return undefined;
    }, [headerPositionRef]);

    return (
        <div className="header_twClass">
            <div
                className="pb-[var(--headerHeightMobile)] lg:pb-[var(--headerHeightTablet)] vl:pb-[var(--headerHeightDesktop)]"
                ref={headerPositionRef}
            />
            <Webline
                type="white"
                id="header"
                data-headerfixedsize={width <= desktopFirstSizes.tablet ? 60 : 68}
                className={twMerge(
                    'border-b-4 border-border lg:border-0',
                    !isFixedHeader && 'relative',
                    isFixedHeader && 'fixed left-0 right-0 top-0 z-menu animate-fadeIn lg:border-b-4',
                )}
            >
                <div
                    data-testid={TEST_IDENTIFIER}
                    className={twMerge(
                        'relative flex flex-wrap',
                        `${
                            !isFixedHeader &&
                            '-mt-[var(--headerHeightMobile)] lg:-mt-[var(--headerHeightTablet)] vl:-mt-[var(--headerHeightDesktop)]'
                        }`,
                        `${isFixedHeader && 'lg:py-2'}`,
                    )}
                >
                    <HeaderTabletBlock
                        isFixedHeader={isFixedHeader}
                        actionDiscountsLink={url + actionDiscountsLink + '/'}
                    />
                    <div
                        className={twMerge(
                            'order-1 mr-auto flex max-w-[var(--headerLogoMobileWidth)] flex-1 items-center',
                            'lg:my-3 lg:mr-0 lg:mt-[25px] lg:w-[var(--headerLogoTabletWidth)] lg:max-w-none lg:flex-none lg:self-end',
                            'vl:order-2 vl:mb-0 vl:mr-[var(--headerItemGap)] vl:mt-0 vl:w-[var(--headerLogoDesktopWidth)]',
                            'xl:mr-9',
                            isFixedHeader && 'my-0 lg:my-0 lg:mr-[var(--headerItemGap)] lg:self-center',
                        )}
                    >
                        <Logo />
                    </div>
                    <div
                        className={twMerge(
                            twJoin(
                                'order-2 m-[var(--headerMobileItemGaps)] w-10 lg:order-3 lg:my-0 lg:ml-0 lg:mr-[var(--headerItemGap)] lg:flex-1',
                                isFixedHeader && 'lg:m-0 lg:w-12 lg:flex-1',
                            ),
                        )}
                        ref={searchRef}
                    >
                        <AutocompleteSearch inFixedHeader={isFixedHeader} searchRef={searchRef} />
                    </div>
                    <div
                        className={twMerge(
                            'order-3 m-[var(--headerMobileItemGaps)] flex',
                            'lg:my-0 lg:ml-auto lg:mr-[var(--headerItemGap)]',
                            'xl:order-4',
                            isFixedHeader && 'lg:ml-0 vl:ml-auto',
                        )}
                    >
                        {isFixedHeader && <HeaderContact inFixedHeader />}
                        <MenuIconic isFixedHeader={isFixedHeader} wishlistUuid={wishlistUuid} />
                    </div>
                    <HeaderNotDesktopBlock
                        setIsMenuOpened={setIsMenuOpened}
                        actionDiscountsLink={url + actionDiscountsLink + '/'}
                        isMenuOpened={isMenuOpened}
                        wishlistUuid={wishlistUuid}
                    />
                    <div className="relative order-4 m-[var(--headerMobileItemGaps)] flex lg:m-0 xl:order-5">
                        <Cart />
                    </div>
                </div>
            </Webline>
        </div>
    );
};

Header.displayName = 'Header';
