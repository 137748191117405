import { canUseDom } from 'helpers/canUseDom';
import { useEffect } from 'react';
import { v4 as uuid } from 'uuid';

interface BaseBroadcastChannel {
    name: string;
    data?: any;
}

interface BroadcastChannelWithCallback<T extends BaseBroadcastChannel> {
    name: T['name'];
    callBack: (data: T['data']) => void;
}

type BroadcastChannelsType = 'reloadPage' | 'refetchCart' | 'orderCompleteBroadcast';

type CurrentCustomerUserBroadcast = {
    name: 'currentCustomerUserBroadcast';
    data: {
        currentCustomerUser: 'userInformation' | 'deliveryInformation' | 'contactInformation';
    };
};

type GlobalBroadcast = {
    name: BroadcastChannelsType;
};

type DispatchBroadcastChannelType = CurrentCustomerUserBroadcast | GlobalBroadcast;

type ChangeBroadcastChannelType =
    | BroadcastChannelWithCallback<CurrentCustomerUserBroadcast>
    | BroadcastChannelWithCallback<GlobalBroadcast>;

const tabId = uuid();

export const dispatchBroadcastChannel = (value: DispatchBroadcastChannelType): void => {
    if (!canUseDom()) {
        return;
    }

    const channel = new BroadcastChannel(value.name);
    channel.postMessage({ tabId, ...('data' in value && { ...value.data }) });
    channel.close();
};

export const useBroadcastChannel = (value: ChangeBroadcastChannelType): void => {
    useEffect(() => {
        const channel = canUseDom() ? new BroadcastChannel(value.name) : null;

        if (!channel) {
            return void null;
        }

        channel.onmessage = (event) => {
            if (event.data.tabId !== tabId) {
                value.callBack(event.data);
            }
        };

        return () => {
            channel.close();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};
