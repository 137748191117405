import { SpinnerIcon } from 'components/Basic/Icon/IconsSvg';
import { FC } from 'react';

type LoadingOverlayProps = {
    iconClassName: string;
};

export const LoadingOverlay: FC<LoadingOverlayProps> = ({ iconClassName }) => {
    return (
        <div className="absolute inset-0 z-above-1 flex h-full w-full items-center justify-center bg-greyVeryLight opacity-50">
            <SpinnerIcon className={iconClassName} />
        </div>
    );
};
