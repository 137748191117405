import {
    ListedProductFragmentApi,
    ProductsCompareFragmentApi,
    useProductsByCatnumsApi,
    useProductsCompareApi,
} from 'graphql/generated';
import { useQueryError } from 'hooks/graphQl/UseQueryError';
import { mapListedProductType } from './Products';
import { ListedProductCompareType, ListedProductType } from 'types/product';

export const useProductsByCatnums = (
    compareProducts: string[],
): { products: ListedProductType[]; fetching: boolean } => {
    const [{ data, error, fetching }] = useProductsByCatnumsApi({
        variables: { catnums: compareProducts },
        pause: compareProducts.length === 0,
    });
    useQueryError(error);

    const apiData = data?.productsByCatnums;
    if (apiData === undefined) {
        return { products: [], fetching };
    }

    return {
        products: apiData.map((apiProduct: ListedProductFragmentApi) => {
            return mapListedProductType(apiProduct);
        }),
        fetching,
    };
};

export const useProductsCompare = (
    compareProducts: string[],
): { productsCompareCount: number; productsCompare: ListedProductCompareType[] } => {
    const [{ data, error }] = useProductsCompareApi({
        variables: { catnums: compareProducts },
        pause: compareProducts.length === 0,
    });
    useQueryError(error);

    const apiData = data?.productsByCatnums;
    if (apiData === undefined || compareProducts.length === 0) {
        return { productsCompareCount: 0, productsCompare: [] };
    }

    return { productsCompareCount: apiData.length, productsCompare: mapProductsCompareApiData(apiData) };
};

export const mapProductsCompareApiData = (apiData: ProductsCompareFragmentApi[]): ListedProductCompareType[] => {
    return apiData.map((apiProduct) => {
        return {
            ...mapListedProductType(apiProduct),
            parameters: apiProduct.parameters,
        };
    });
};
