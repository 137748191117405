import Error500 from 'components/Pages/ErrorPage/500';
import ShopsysGlobalProvider from 'context/ShopsysGlobalProvider';
import { showSuccessMessage } from 'components/Helpers/Toasts';
import { extend, locale } from 'dayjs';
import 'dayjs/locale/cs';
import 'dayjs/locale/sk';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import { useReloadCart } from 'hooks/cart/useReloadCart';
import i18nConfig from 'i18n';
import appWithI18n from 'next-translate/appWithI18n';
import { withUrqlClient } from 'next-urql';
import { AppProps as NextAppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Nprogress from 'nprogress';
import 'nprogress/nprogress.css';
import { ReactElement, useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'globals.css';
import { getUrqlExchanges } from 'urql/exchanges';
import { fetcher } from 'urql/fetcher';
import { getDomainConfig } from 'utils/Domain/Domain';
import { useTypedTranslationFunction } from 'hooks/typescript/UseTypedTranslationFunction';
import { getGtmHeadScript } from 'components/Helpers/GtmHeadScript';
import 'react-loading-skeleton/dist/skeleton.css';
import { ServerSidePropsType } from 'helpers/InitServerSideProps';
import { Error503 } from 'components/Pages/ErrorPage/503/Error503';
import { generateNonce } from 'helpers/contentSecurityPolicy';
import { useShopInfo } from '../connectors/shopInfo/ShopInfo';
import Script from 'next/script';
import { Canonical } from 'components/Basic/Head/Canonical/Canonical';
import getConfig from 'next/config';
import { usePersistStore } from 'store/zustand/usePersistStore';
import { LoadingHandler } from 'components/Layout/LoadingHandler';
import { useSetDomainConfig } from 'hooks/useDomainConfig';
import { NotificationBars } from 'components/Layout/NotificationBars/NotificationBars';
import { ScrollTop } from 'components/Layout/ScrollTop/ScrollTop';
import { Webline } from 'components/Layout/Webline/Webline';
import { Navigation } from 'components/Layout/Header/Navigation/Navigation';
import { Header } from 'components/Layout/Header/Header';
import { Footer } from 'components/Layout/Footer/Footer';
import { twJoin } from 'tailwind-merge';
import dynamic from 'next/dynamic';
import { AddToCartPopupProps } from 'components/Blocks/Product/AddToCartPopup/AddToCartPopup';
import { Poppins } from 'next/font/google';
import { useBroadcastChannel } from 'hooks/useBroadcastChannel';

const AddToCartPopup = dynamic<AddToCartPopupProps>(
    () =>
        import('components/Blocks/Product/AddToCartPopup/AddToCartPopup').then((component) => component.AddToCartPopup),
    { ssr: false },
);

extend(LocalizedFormat);

const robotoFont = Poppins({
    weight: ['400', '500', '600', '700'],
    subsets: ['latin-ext', 'latin'],
    variable: '--font-poppins',
});

type ErrorProps = { err?: any };

type AppProps = {
    pageProps: ServerSidePropsType;
} & Omit<NextAppProps<ErrorProps>, 'pageProps'> &
    ErrorProps;

function MyApp({ Component, pageProps, err }: AppProps): ReactElement {
    const router = useRouter();
    const { loginLoading, isLogoutLoading, updateUserState, addToCartPopupData, addToCartFetching } = usePersistStore(
        (s) => s,
    );
    const t = useTypedTranslationFunction();
    const { defaultLocale } = pageProps.domainConfig;
    const shopInfoData = useShopInfo();
    const { publicRuntimeConfig } = getConfig();
    const cdnUrl = publicRuntimeConfig.cdnUrl;
    const isOrbittourPage = router.asPath.split('/')[1] === 'orbittour';

    useSetDomainConfig(pageProps.domainConfig, pageProps.ipAddress);

    useBroadcastChannel({
        name: 'reloadPage',
        callBack: () => {
            router.reload();
        },
    });

    useReloadCart();
    useReloadCart(true);

    locale(defaultLocale);

    useEffect(() => {
        if (loginLoading !== 'not-loading') {
            showSuccessMessage(t('Successfully logged in'));
            updateUserState({ loginLoading: 'not-loading' });
        } else if (isLogoutLoading) {
            showSuccessMessage(t('Successfully logged out'));
            updateUserState({ isLogoutLoading: false });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        Nprogress.configure({ showSpinner: false, minimum: 0.2 });

        const onRouteChangeStart = (_targetUrl: string, { shallow }: { shallow: boolean }) => {
            if (!shallow) {
                Nprogress.start();
            }
        };
        const onRouteChangeStop = (_targetUrl: string, { shallow }: { shallow: boolean }) => {
            if (!shallow) {
                Nprogress.done();
            }
        };

        router.events.on('routeChangeStart', onRouteChangeStart);
        router.events.on('routeChangeComplete', onRouteChangeStop);
        router.events.on('routeChangeError', onRouteChangeStop);

        return () => {
            router.events.off('routeChangeStart', onRouteChangeStart);
            router.events.off('routeChangeComplete', onRouteChangeStop);
            router.events.off('routeChangeError', onRouteChangeStop);
        };
    }, [router.events]);

    const nonce = pageProps.nonce ? pageProps.nonce : generateNonce();
    const optimonkAccountId = pageProps.domainConfig.optimonkAccountId;

    return (
        <>
            <Head>
                <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
                <link rel="shortcut icon" href={`${cdnUrl}/favicons/favicon.ico`} type="image/x-icon" />
                <link rel="apple-touch-icon" href={`${cdnUrl}/favicons/apple-touch-icon.png`} />
                <link rel="apple-touch-icon" sizes="57x57" href={`${cdnUrl}/favicons/apple-touch-icon-57x57.png`} />
                <link rel="apple-touch-icon" sizes="60x60" href={`${cdnUrl}/favicons/apple-touch-icon-60x60.png`} />
                <link rel="apple-touch-icon" sizes="72x72" href={`${cdnUrl}/favicons/apple-touch-icon-72x72.png`} />
                <link rel="apple-touch-icon" sizes="76x76" href={`${cdnUrl}/favicons/apple-touch-icon-76x76.png`} />
                <link rel="apple-touch-icon" sizes="114x114" href={`${cdnUrl}/favicons/apple-touch-icon-114x114.png`} />
                <link rel="apple-touch-icon" sizes="120x120" href={`${cdnUrl}/favicons/apple-touch-icon-120x120.png`} />
                <link rel="apple-touch-icon" sizes="144x144" href={`${cdnUrl}/favicons/apple-touch-icon-144x144.png`} />
                <link rel="apple-touch-icon" sizes="152x152" href={`${cdnUrl}/favicons/apple-touch-icon-152x152.png`} />
                <link rel="apple-touch-icon" sizes="180x180" href={`${cdnUrl}/favicons/apple-touch-icon-180x180.png`} />
                <link rel="icon" type="image/png" href={`${cdnUrl}/favicons/favicon-16x16.png`} sizes="16x16" />
                <link rel="icon" type="image/png" href={`${cdnUrl}/favicons/favicon-32x32.png`} sizes="32x32" />
                <link rel="icon" type="image/png" href={`${cdnUrl}/favicons/favicon-96x96.png`} sizes="96x96" />
                <meta name="msapplication-TileColor" content="#ffffff" />
                <meta name="msapplication-config" content={`${cdnUrl}/favicons/browserconfig.xml`} />
                <meta name="theme-color" content="#ffffff" />
                <link rel="manifest" href={`${cdnUrl}/favicons/site.webmanifest`} />
                <link rel="mask-icon" href={`${cdnUrl}/favicons/safari-pinned-tab.svg`} color="#ffffff" />
                {optimonkAccountId && (
                    <script
                        type="text/javascript"
                        src={`https://onsite.optimonk.com/script.js?account=${optimonkAccountId}`}
                        async
                    ></script>
                )}
            </Head>
            {/* eslint-disable-next-line react/no-unknown-property */}
            <style jsx global>{`
                html {
                    font-family: ${robotoFont.style.fontFamily};
                }
            `}</style>
            {shopInfoData && shopInfoData.luigisBoxCode !== null && (
                <Script src={`https://scripts.luigisbox.com/LBX-${shopInfoData.luigisBoxCode}.js`} />
            )}
            {getGtmHeadScript(nonce, isOrbittourPage)}
            <Canonical />
            <ShopsysGlobalProvider>
                <div
                    className={twJoin(
                        'absolute left-0 top-0 z-overlay h-[1px] w-[1px]',
                        '[&_.overlay:nth-last-child(2)]:left-0 [&_.overlay:nth-last-child(2)]:top-0 [&_.overlay:nth-last-child(2)]:ui-transition [&_.overlay]:-left-full [&_.overlay]:-top-full [&_.overlay]:transition-none',
                        '[&_.popupHolder:nth-last-child(1)]:left-[52%] [&_.popupHolder:nth-last-child(1)]:top-[50%] [&_.popupHolder]:-left-full [&_.popupHolder]:-top-full [&_.popupHolder]:transition-none',
                    )}
                    id="portal"
                />
                <LoadingHandler />
                <ToastContainer autoClose={6000} position="top-center" theme="colored" />
                <ErrorBoundary FallbackComponent={Error500}>
                    {!isOrbittourPage && (
                        <>
                            <NotificationBars />
                            <Header
                                wishlistUuid={'wishlist' in pageProps.cookies ? pageProps.cookies.wishlist : null}
                            />
                            <Webline type="light" className="mt-[17px] lg:mt-5 xl:mb-6">
                                <Navigation />
                            </Webline>
                        </>
                    )}
                    {pageProps.isMaintenance ? <Error503 /> : <Component {...pageProps} err={err} />}
                    {!isOrbittourPage && (
                        <>
                            <Footer />
                            <ScrollTop />
                        </>
                    )}
                    {addToCartPopupData && (
                        <AddToCartPopup
                            gtmListName={addToCartPopupData.gtmListName}
                            listIndex={addToCartPopupData.listIndex}
                            onCloseCallback={() => updateUserState({ addToCartPopupData: null })}
                            product={addToCartPopupData.product}
                            fetching={addToCartFetching}
                            continueText={addToCartPopupData.continueText}
                            succesMessage={addToCartPopupData.succesMessage}
                            demandCart={addToCartPopupData.demandCart}
                            imosSessionId={addToCartPopupData.imosSessionId}
                        />
                    )}
                </ErrorBoundary>
            </ShopsysGlobalProvider>
        </>
    );
}

/**
 * We need to define "something" on the server side, even though it is not used at all.
 * On the server side, the URL is actually defined in initUrqlClient in InitServerSideProps.
 */
const getApiUrl = () => {
    let apiUrl = 'defaultUrl';
    if (typeof window !== 'undefined') {
        apiUrl = getDomainConfig(window.location.host).publicGraphqlEndpoint;
    }
    return apiUrl;
};

export default withUrqlClient(
    (ssrExchange) => ({
        url: getApiUrl(),
        exchanges: getUrqlExchanges(ssrExchange),
        fetch: fetcher(null),
    }),
    { ssr: false },
)(
    // eslint-disable-next-line
    // @ts-ignore
    appWithI18n(MyApp, { ...i18nConfig }),
);
