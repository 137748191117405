import { AnchorHTMLAttributes } from 'react';
import { ExtractNativePropsFromDefault } from 'typeHelpers/ExtractNativePropsFromDefault';
import { ButtonType } from 'types/button';
import { ExtendedNextLink } from '../ExtendedNextLink/ExtendedNextLink';
import twMerge from 'utils/twMerge';
import { Button } from 'components/Forms/Button/Button';

type NativePropsAnchor = ExtractNativePropsFromDefault<
    AnchorHTMLAttributes<HTMLAnchorElement>,
    'href',
    'rel' | 'target' | 'className' | 'onMouseEnter' | 'onClick'
>;

type LinkProps = NativePropsAnchor &
    ButtonType & {
        linkType?: 'external';
        isButton?: boolean;
        classNameButton?: string;
        onClickLoading?: boolean;
    };

const getDataTestId = (isExternal?: boolean, isButton?: boolean) =>
    'basic-link' + (isExternal ? '-external' : '') + (isButton ? '-button' : '');

const Link: FC<LinkProps> = (props) => {
    if (props.isButton) {
        return (
            <ExtendedNextLink href={props.href} rel={props.rel} target={props.target} passHref type="static">
                <Button
                    {...props}
                    className={twMerge(props.className, props.classNameButton)}
                    data-testid={getDataTestId(props.linkType === 'external', props.isButton)}
                    onClickLoading={props.onClickLoading}
                >
                    {props.children}
                </Button>
            </ExtendedNextLink>
        );
    }

    return (
        <ExtendedNextLink
            {...props}
            className={twMerge('ui-link', props.className)}
            data-testid={getDataTestId(props.linkType === 'external', props.isButton)}
            type="static"
        >
            {props.children}
        </ExtendedNextLink>
    );
};

/* @component */
export default Link;
