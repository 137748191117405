import { computePosition, flip, offset, Placement, shift } from '@floating-ui/react';

export const relativeTooltipHandler = (
    { clientX, clientY }: { clientX: number; clientY: number },
    placement: Placement,
    floatingProps: Record<string, unknown>,
): void => {
    const floatingTooltip = document.getElementById(floatingProps.id as string);
    if (floatingTooltip === null) {
        return;
    }

    const virtualEl = {
        getBoundingClientRect() {
            return {
                width: 0,
                height: 0,
                x: clientX,
                y: clientY,
                left: clientX,
                right: clientX,
                top: clientY,
                bottom: clientY,
            };
        },
    };

    computePosition(virtualEl, floatingTooltip, {
        placement: placement,
        middleware: [
            offset(15),
            flip(),
            shift({
                padding: 20,
            }),
        ],
    }).then(({ x, y }) => {
        Object.assign(floatingTooltip.style, {
            top: `${y}px`,
            left: `${x}px`,
        });
    });
};
