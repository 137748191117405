import { Locale } from 'helpers/locale';

export const DEFAULT_PAGE_SIZE = 15;
export const BLOG_CATEGORY_PAGE_SIZE = 10;
export const MAX_ITEMS_COUNT = 100;

export const FILTER_SINGLE_COLUMN_LAYOUT_LIMIT_VALUE = 10;

export const PLACES_AUTOCOMPLETE_ACTIVE_DOMAIN = Locale.DE;
export const PLACES_AUTOCOMPLETE_API_KEY = 'AIzaSyBxNltMl56S18zvrww4d3vegJ8Oy0XH-d4';

export const gtmElementIds = {
    IMOS_PRODUCT_DETAIL: 'gtm-g01-p00',
    SHARE_PRODUCT_DETAIL: 'gtm-g01-p01',
    COMPARE_PRODUCT_DETAIL: 'gtm-g01-p02',
    WISHLIST_PRODUCT_DETAIL: 'gtm-g01-p03',
    VARIANTS_PRODUCT_DETAIL: 'gtm-g02-p01',
    DESCRIPTION_PARAMETERS_PRODUCT_DETAIL: 'gtm-g02-p02',
    TUTORIALS_PRODUCT_DETAIL: 'gtm-g02-p03',
    ACCESSORIES_PRODUCT_DETAIL: 'gtm-g02-p04',
    RELATED_PRODUCT_DETAIL: 'gtm-g02-p05',
    DESCRIPTION_PRODUCT_DETAIL_MOBILE: 'gtm-g02-p06',
    PARAMETERS_PRODUCT_DETAIL_MOBILE: 'gtm-g02-p07',
    PARAMETERS_BLOG_ARTICLES_MOBILE: 'gtm-g02-p09',
    BESTSELLER_PRODUCT_DETAIL: 'gtm-g02-p08',
    CATEGORIES_PRODUCT_DETAIL: 'gtm-g03',
    SHARE_PRODUCT_LIST: 'gtm-g03-p01',
    COMPARE_PRODUCT_LIST: 'gtm-g03-p02',
    WISHLIST_PRODUCT_LIST: 'gtm-g03-p03',
} as const;
