import { getFirstImage } from 'connectors/image/Image';
import { mapPriceData, mapPriceInputData } from 'connectors/price/Prices';
import { CartTypeEnumApi, SimplePaymentFragmentApi, useCartPaymentsQueryApi } from 'graphql/generated';
import { useQueryError } from 'hooks/graphQl/UseQueryError';
import { useDomainConfig } from 'hooks/useDomainConfig';
import { useCurrentUserData } from 'hooks/user/useCurrentUserData';
import { useMemo } from 'react';
import { usePersistStore } from 'store/zustand/usePersistStore';
import { PaymentInputType, PaymentType, PaymentTypeEnum } from 'types/payment';

export const useCartPayments = (
    isDemandCart = false,
    lastOrderCartUuidValue: string | null = null,
): [PaymentType[] | undefined, boolean, typeof refreshCartPayments] => {
    const { currencyCode } = useDomainConfig();
    const { isUserLoggedIn } = useCurrentUserData();
    const { cartUuid: cartUuidValue, demandCartUuid: demandCartUuidValue } = usePersistStore((s) => s);
    const cartUuid = isDemandCart
        ? demandCartUuidValue
        : lastOrderCartUuidValue !== null
        ? lastOrderCartUuidValue
        : cartUuidValue;
    const [{ data, error, fetching }, refreshCartPayments] = useCartPaymentsQueryApi({
        variables: {
            cartUuid,
            cartType: isDemandCart ? CartTypeEnumApi.DemandCartApi : CartTypeEnumApi.CartApi,
        },
        pause: cartUuid === null && !isUserLoggedIn,
    });

    useQueryError(error);

    return useMemo(
        () => [data?.cartPayments.map((payment) => mapPayment(payment, currencyCode)), fetching, refreshCartPayments],
        [data?.cartPayments, currencyCode, fetching, refreshCartPayments],
    );
};

export const mapPayment = (apiData: SimplePaymentFragmentApi, currencyCode: string): PaymentType => {
    return {
        ...apiData,
        description: apiData.description !== null ? apiData.description : '',
        instruction: apiData.instruction !== null ? apiData.instruction : '',
        image: getFirstImage(apiData.images),
        price: mapPriceData(apiData.price, currencyCode),
        goPayPaymentMethod: apiData.goPayPaymentMethod !== null ? apiData.goPayPaymentMethod : undefined,
        type: getPaymentTypeAsEnum(apiData.type),
    };
};

export const getPaymentTypeAsEnum = (paymentType: string): PaymentTypeEnum => {
    switch (paymentType) {
        case 'goPay':
            return PaymentTypeEnum.GoPay;
        case 'sofort':
            return PaymentTypeEnum.Sofort;
        case 'stripe':
            return PaymentTypeEnum.Stripe;
        case 'payPal':
            return PaymentTypeEnum.PayPal;
        case 'basic':
            return PaymentTypeEnum.Basic;
        case 'invoiceDue':
            return PaymentTypeEnum.Invoice;
        default:
            return PaymentTypeEnum.Basic;
    }
};

export const mapPaymentToPaymentInput = (payment: PaymentType, goPayBankSwift: string | null): PaymentInputType => {
    return {
        uuid: payment.uuid,
        price: mapPriceInputData(payment.price),
        goPayBankSwift,
    };
};
