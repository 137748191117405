import { useEffect, useState } from 'react';

function useDebounce<T>(value: T, delay?: number): T {
    const [debouncedValue, setDebouncedValue] = useState<T>(value);
    const [firstTime, setFirstTime] = useState(true);

    useEffect(() => {
        if (firstTime) {
            setDebouncedValue(value);
            setFirstTime(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setDebouncedValue(value);
            setFirstTime(true);
        }, delay);

        return () => {
            clearTimeout(timer);
        };
    }, [delay, value]);
    return debouncedValue;
}

export default useDebounce;
