const STATIC_PATHS_DOMAIN_1 = {
    '/search/': '/hledani/',
    '/cart/': '/kosik/',
    '/demandCart/': '/on-line-poptavka/',
    '/order/': '/objednavka/',
    '/demand/': '/poptavka/',
    '/reset-password/': '/zapomenute-heslo/',
    '/order-confirmation/': '/objednavka-dokoncena/',
    '/brands-overview/': '/prehled-znacek/',
    '/login/': '/prihlaseni/',
    '/customer/': '/zakaznik/',
    '/customer/edit-profile/': '/zakaznik/upravit-udaje/',
    '/customer/orders/': '/zakaznik/objednavky/',
    '/customer/order-detail/': '/zakaznik/detail-objednavky/',
    '/customer/documents/': '/zakaznik/doklady/',
    '/customer/user-management/': '/zakaznik/sprava-uzivatelu/',
    '/registration/': '/registrace/',
    '/new-password/': '/nove-heslo/',
    '/order-detail/:urlHash/': '/detail-objednavky/:urlHash/',
    '/order-payment-confirmation/': '/objednavka-platba-dokoncena/',
    '/order/payment-status-notify/': '/order/payment-status-notify/',
    '/catalog-order/': '/objednavka-katalogu/',
    '/catalog-order-confirmation/': '/objednavka-katalogu-odeslana/',
    '/complaints/': '/reklamace/',
    '/complaints-confirmation/': '/reklamace-odeslana/',
    '/compare-products/': '/porovnani/',
    '/wishlist/': '/oblibene-produkty/',
    '/abandoned-cart/:cartUuid/': '/opusteny-kosik/:cartUuid/',
};

const STATIC_PATHS_DOMAIN_2 = {
    '/search/': '/hladanie/',
    '/cart/': '/kosik/',
    '/demandCart/': '/online-dopyt/',
    '/demand/': '/dopyt/',
    '/order/': '/objednavka/',
    '/reset-password/': '/zabudnute-heslo/',
    '/order-confirmation/': '/objednavka-dokoncena/',
    '/brands-overview/': '/prehlad-znaciek/',
    '/login/': '/prihlasenie/',
    '/customer/': '/zakaznik/',
    '/customer/edit-profile/': '/zakaznik/upravit-udaje/',
    '/customer/orders/': '/zakaznik/objednavky/',
    '/customer/order-detail/': '/zakaznik/detail-objednavky/',
    '/customer/documents/': '/zakaznik/doklady/',
    '/customer/user-management/': '/zakaznik/sprava-uzivatelov/',
    '/registration/': '/registracia/',
    '/new-password/': '/nove-heslo/',
    '/order-detail/:urlHash/': '/detail-objednavky/:urlHash/',
    '/order-payment-confirmation/': '/objednavka-platba-dokoncena/',
    '/order/payment-status-notify/': '/order/payment-status-notify/',
    '/catalog-order/': '/objednavka-katalogu/',
    '/catalog-order-confirmation/': '/objednavka-katalogu-odoslana/',
    '/complaints/': '/reklamacia/',
    '/complaints-confirmation/': '/reklamacia-odoslana/',
    '/compare-products/': '/porovnanie/',
    '/wishlist/': '/zoznam-priani/',
    '/abandoned-cart/:cartUuid/': '/opusteny-kosik/:cartUuid/',
};

const STATIC_PATHS_DOMAIN_3 = {
    '/search/': '/wyszukiwanie/',
    '/cart/': '/koszyk/',
    '/demandCart/': '/zapytanie-online/',
    '/order/': '/zamowienie/',
    '/demand/': '/zapytanie/',
    '/reset-password/': '/ustalanie-nowego-hasla/',
    '/order-confirmation/': '/zamowienie-zrealizowana/',
    '/brands-overview/': '/lista-producentow/',
    '/login/': '/logowanie/',
    '/customer/': '/sekcja-uzytkownika/',
    '/customer/edit-profile/': '/sekcja-uzytkownika/dane-kontaktowe/',
    '/customer/orders/': '/sekcja-uzytkownika/zamowienia/',
    '/customer/order-detail/': '/sekcja-uzytkownika/detail-zamowienia/',
    '/customer/documents/': '/sekcja-uzytkownika/dokumkenty/',
    '/customer/user-management/': '/sekcja-uzytkownika/uzytkownicy/',
    '/registration/': '/rejestracja/',
    '/new-password/': '/nowego-haslo/',
    '/order-detail/:urlHash/': '/detail-zamowienia/:urlHash/',
    '/order-payment-confirmation/': '/zamowienie-platnosc-zrealizowana/',
    '/order/payment-status-notify/': '/order/payment-status-notify/',
    '/catalog-order/': '/katalogi/',
    '/catalog-order-confirmation/': '/zamowienie-katalogu-wyslane/',
    '/complaints/': '/reklamacje/',
    '/complaints-confirmation/': '/reklamacja-wyslana/',
    '/compare-products/': '/porownanie/',
    '/wishlist/': '/lista-zyczen/',
    '/abandoned-cart/:cartUuid/': '/opusteny-kosik/:cartUuid/',
};

const STATIC_PATHS_DOMAIN_4 = {
    '/search/': '/suchen/',
    '/cart/': '/warekorb/',
    '/demandCart/': '/online-nachfrage/',
    '/order/': '/bestellung/',
    '/demand/': '/nachfrage/',
    '/reset-password/': '/passwort-setzen/',
    '/order-confirmation/': '/bestellung-abgeschlossen/',
    '/brands-overview/': '/hersteller-liste/',
    '/login/': '/login/',
    '/customer/': '/benutzer-sektion/',
    '/customer/edit-profile/': '/benutzer-sektion/personendaten/',
    '/customer/orders/': '/benutzer-sektion/bestellungen/',
    '/customer/order-detail/': '/benutzer-sektion/bestellung/',
    '/customer/documents/': '/benutzer-sektion/dokumente/',
    '/customer/user-management/': '/benutzer-sektion/benutzerverwaltung/',
    '/registration/': '/registrierung/',
    '/new-password/': '/passwort-senden/',
    '/order-detail/:urlHash/': '/bestellung/:urlHash/',
    '/order-payment-confirmation/': '/bestellung-zahlung-abgeschlossen/',
    '/order/payment-status-notify/': '/order/payment-status-notify/',
    '/catalog-order/': '/katalogen/',
    '/catalog-order-confirmation/': '/katalog-bestellungbestaetigung/',
    '/complaints/': '/reklamation/',
    '/complaints-confirmation/': '/reklamationbestaetigung/',
    '/compare-products/': '/produktvergleich/',
    '/wishlist/': '/wunschzettel/',
    '/abandoned-cart/:cartUuid/': '/opusteny-kosik/:cartUuid/',
};

module.exports = {
    STATIC_PATHS_DOMAIN_1,
    STATIC_PATHS_DOMAIN_2,
    STATIC_PATHS_DOMAIN_3,
    STATIC_PATHS_DOMAIN_4,
};
