import {
    getGtmChangeCartItemEvent,
    getGtmMessageEvent,
    getGtmPaymentFalseEvent,
    getGtmPaymentInfoEvent,
    getGtmPaymentRepeatEvent,
    getGtmProductClickEvent,
    getGtmPurchaseOrPaymentEvent,
    getGtmPurchaseEventPaymentPart,
    getGtmSendFormEvent,
    getGtmSuggestClickEvent,
} from './eventFactories';
import { gtmSafePushEvent } from './gtm';
import { mapPayment } from 'connectors/payments/Payment';
import { ListedProductAccessoriesFragmentApi, SimplePaymentFragmentApi } from 'graphql/generated';
import { CartItemType } from 'types/cart';
import { LastVisitedProductType, ListedProductType, SimpleProductType } from 'types/product';
import {
    GtmEventType,
    GtmFlowType,
    GtmFormType,
    GtmListNameType,
    GtmMessageOriginType,
    GtmMessageType,
    GtmSectionType,
} from 'types/gtm/enums';
import { GtmCartInfoType } from 'types/gtm/types';
import {
    GtmPurchaseEventOrderPartType,
    GtmPurchaseEventPaymentPartType,
    GtmPurchaseEventUserPartType,
} from 'types/gtm/events';
import {
    increaseGtmPurchasePaymentCounterInLocalStorageByOne,
    removeGtmPurchaseEventFromLocalStorage,
    removeGtmPurchasePaymentCounterFromLocalStorage,
} from './helpers';

export const onGtmPurchaseEventHandler = (
    gtmPurchasePaymentInfo: Pick<
        GtmPurchaseEventPaymentPartType,
        'paymentRetryCount' | 'paymentPrice' | 'paymentPriceWithTax' | 'paymentType'
    >,
    gtmPurchaseEventOrderPart: GtmPurchaseEventOrderPartType | undefined,
    gtmPurchaseEventUserPart: GtmPurchaseEventUserPartType | undefined,
    isPaymentSuccessful: boolean,
    flowType: GtmFlowType | undefined,
    isPaymentThroughPaymentGate: boolean,
    isEmptyPurchaseWithoutPayment = false,
    failReason: string | undefined = undefined,
): void => {
    if (gtmPurchaseEventOrderPart === undefined || gtmPurchaseEventUserPart === undefined || flowType === undefined) {
        return;
    }

    const isGtmRepeatedPayment =
        gtmPurchasePaymentInfo.paymentRetryCount !== undefined && gtmPurchasePaymentInfo.paymentRetryCount > 0;
    const isGtmPaymentEvent = (isGtmRepeatedPayment || isPaymentThroughPaymentGate) && !isEmptyPurchaseWithoutPayment;
    let gtmPurchaseEventPaymentPart: GtmPurchaseEventPaymentPartType;

    if (isEmptyPurchaseWithoutPayment) {
        gtmPurchaseEventPaymentPart = {
            isPaymentSuccessful: undefined,
            isRetriedPayment: undefined,
            paymentRetryCount: undefined,
            paymentPrice: gtmPurchasePaymentInfo.paymentPrice,
            paymentPriceWithTax: gtmPurchasePaymentInfo.paymentPriceWithTax,
            paymentType: gtmPurchasePaymentInfo.paymentType,
        };
    } else {
        gtmPurchaseEventPaymentPart = getGtmPurchaseEventPaymentPart(
            isPaymentSuccessful,
            isGtmRepeatedPayment,
            gtmPurchasePaymentInfo.paymentRetryCount ?? 0,
            gtmPurchasePaymentInfo.paymentType,
            gtmPurchasePaymentInfo.paymentPrice,
            gtmPurchasePaymentInfo.paymentPriceWithTax,
        );
    }

    const gtmPurchaseEvent = getGtmPurchaseOrPaymentEvent(
        gtmPurchaseEventOrderPart,
        gtmPurchaseEventPaymentPart,
        gtmPurchaseEventUserPart,
        flowType,
        isGtmPaymentEvent,
    );

    gtmSafePushEvent(gtmPurchaseEvent);
    if (isGtmRepeatedPayment) {
        onGtmPaymentRepeatEventHandler(gtmPurchaseEventOrderPart.id);
    }

    if (isEmptyPurchaseWithoutPayment) {
        return;
    }

    if (isPaymentSuccessful) {
        removeGtmPurchasePaymentCounterFromLocalStorage();
        removeGtmPurchaseEventFromLocalStorage();

        return;
    }

    increaseGtmPurchasePaymentCounterInLocalStorageByOne();
    onGtmPaymentFalseEventHandler(gtmPurchaseEventOrderPart.id, failReason);
};

export const onGtmPaymentFalseEventHandler = (orderUuid: string, failReason?: string): void => {
    gtmSafePushEvent(getGtmPaymentFalseEvent(orderUuid, failReason));
};

export const onGtmPaymentRepeatEventHandler = (orderUuid: string): void => {
    gtmSafePushEvent(getGtmPaymentRepeatEvent(orderUuid));
};

export const onGtmSendFormEventHandler = (form: GtmFormType): void => {
    gtmSafePushEvent(getGtmSendFormEvent(form));
};

export const onGtmProductClickEventHandler = (
    product: ListedProductType | SimpleProductType | LastVisitedProductType | ListedProductAccessoriesFragmentApi,
    listName: GtmListNameType,
    index: number,
    domainUrl: string,
): void => {
    gtmSafePushEvent(getGtmProductClickEvent(product, listName, index, domainUrl));
};

export const onGtmSuggestClickEventHandler = (keyword: string, section: GtmSectionType, itemName: string): void => {
    gtmSafePushEvent(getGtmSuggestClickEvent(keyword, section, itemName));
};

export const onGtmRemoveFromCartEventHandler = (
    removedCartItem: CartItemType,
    currencyCode: string,
    eventValue: number,
    eventValueWithTax: number,
    listIndex: number,
    listName: GtmListNameType,
    flowType: GtmFlowType,
    domainUrl: string,
    cart?: GtmCartInfoType | null,
): void => {
    gtmSafePushEvent(
        getGtmChangeCartItemEvent(
            GtmEventType.removeFromCart,
            removedCartItem,
            listIndex,
            removedCartItem.quantity,
            currencyCode,
            eventValue,
            eventValueWithTax,
            listName,
            flowType,
            domainUrl,
            cart,
        ),
    );
};

export const onGtmChangeCartItemEventHandler = (
    addedCartItem: CartItemType,
    currencyCode: string,
    eventValue: number,
    eventValueWithTax: number,
    listIndex: number,
    quantityDifference: number,
    listName: GtmListNameType,
    flowType: GtmFlowType,
    domainUrl: string,
    cart?: GtmCartInfoType | null,
): void => {
    const absoluteQuantity = Math.abs(quantityDifference);
    const event = getGtmChangeCartItemEvent(
        GtmEventType.addToCart,
        addedCartItem,
        listIndex,
        absoluteQuantity,
        currencyCode,
        eventValue,
        eventValueWithTax,
        listName,
        flowType,
        domainUrl,
        cart,
    );

    if (quantityDifference < 0) {
        event.event = GtmEventType.removeFromCart;
    }

    gtmSafePushEvent(event);
};

export const onGtmPaymentInfoEventHandler = (
    gtmCartInfo: GtmCartInfoType | undefined | null,
    updatedPayment: SimplePaymentFragmentApi | null,
    currencyCode: string,
    flowType: GtmFlowType,
): void => {
    if (gtmCartInfo && updatedPayment !== null) {
        const mappedPayment = mapPayment(updatedPayment, currencyCode);
        gtmSafePushEvent(getGtmPaymentInfoEvent(gtmCartInfo, mappedPayment, flowType));
    }
};

export const onGtmMessageEvent = (
    type: GtmMessageType,
    message: string,
    detail: string,
    origin?: GtmMessageOriginType,
): void => {
    gtmSafePushEvent(getGtmMessageEvent(type, message, detail, origin));
};
