import { useWishlist } from 'connectors/wishlist/Wishlist';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
    useAddProductToWishlistMutationApi,
    useCleanWishlistMutationApi,
    useRemoveProductFromWishlistMutationApi,
} from 'graphql/generated';
import { showErrorMessage, showSuccessMessage } from 'components/Helpers/Toasts';
import { useTypedTranslationFunction } from 'hooks/typescript/UseTypedTranslationFunction';

import { useCurrentUserData } from 'hooks/user/useCurrentUserData';
import { cleanWishlistCookie, setWishlistUuidCookie } from './useHandleWishlistCookie';
import { usePersistStore } from 'store/zustand/usePersistStore';

export const useHandleProductInWishlist = (
    productUuid: string,
): {
    isProductInWishlist: boolean;
    handleProductInWishlist: () => void;
    isPopupWishlistOpen: boolean;
    setIsPopupWishlistOpen: Dispatch<SetStateAction<boolean>>;
} => {
    const [isPopupWishlistOpen, setIsPopupWishlistOpen] = useState(false);
    const { wishlist, refreshWishlist } = useWishlist();
    const [isProductInWishlist, setIsProductInWishlist] = useState(false);
    const [, addProductToWishlist] = useAddProductToWishlistMutationApi();
    const [, removeProductFromWishlist] = useRemoveProductFromWishlistMutationApi();
    const t = useTypedTranslationFunction();
    const { isUserLoggedIn } = useCurrentUserData();
    const wishlistUuid = usePersistStore((s) => s.wishlistUuid);
    const updateUserState = usePersistStore((s) => s.updateUserState);

    useEffect(() => {
        if (wishlist?.products.find((product) => product.uuid === productUuid) !== undefined) {
            setIsProductInWishlist(true);
        } else {
            setIsProductInWishlist(false);
        }
    }, [productUuid, wishlist?.products]);

    const handleAddToWishlist = async () => {
        const addProductToWishlistResult = await addProductToWishlist({ productUuid, wishlistUuid });

        if (addProductToWishlistResult.error !== undefined) {
            showErrorMessage(t('Unable to add product to wishlist.'));
        } else {
            setIsPopupWishlistOpen(true);

            if (isUserLoggedIn) {
                cleanWishlistCookie();
                updateUserState({ wishlistUuid: null });
            } else {
                setWishlistUuidCookie(addProductToWishlistResult.data?.addProductToWishlist.uuid ?? '');
                updateUserState({ wishlistUuid: addProductToWishlistResult.data?.addProductToWishlist.uuid ?? '' });
            }
            refreshWishlist();
        }
    };

    const handleRemoveFromWishlist = async () => {
        const removeProductFromWishlistResult = await removeProductFromWishlist({ productUuid, wishlistUuid });

        if (removeProductFromWishlistResult.error !== undefined) {
            showErrorMessage(t('Unable to remove product from wishlist.'));
        } else {
            if (!removeProductFromWishlistResult.data?.removeProductFromWishlist) {
                cleanWishlistCookie();
                updateUserState({ wishlistUuid: null });
            }
            showSuccessMessage(t('The item has been removed from your wishlist.'));
        }
    };

    const handleProductInWishlist = async () => {
        if (isProductInWishlist) {
            handleRemoveFromWishlist();
        } else {
            handleAddToWishlist();
        }
    };

    return { isProductInWishlist, handleProductInWishlist, isPopupWishlistOpen, setIsPopupWishlistOpen };
};

export const useCleanWishlist = (): { handleCleanWishlist: () => void } => {
    const t = useTypedTranslationFunction();
    const [, cleanWishlist] = useCleanWishlistMutationApi();
    const wishlistUuid = usePersistStore((s) => s.wishlistUuid);
    const updateUserState = usePersistStore((s) => s.updateUserState);

    const handleCleanWishlist = async () => {
        const cleanWishlistResult = await cleanWishlist({ wishlistUuid });

        if (cleanWishlistResult.error !== undefined) {
            showErrorMessage(t('Unable to clean wishlist.'));
        } else {
            showSuccessMessage(t('Wishlist was cleaned.'));
            cleanWishlistCookie();
            updateUserState({ wishlistUuid: null });
        }
    };

    return { handleCleanWishlist };
};
