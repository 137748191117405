import { FC } from 'react';

type FooterBottomProps = {
    htmlContent?: string;
};

export const FooterBottom: FC<FooterBottomProps> = (props) => {
    return (
        <>
            {props.htmlContent !== undefined && (
                <div
                    className="mb-6 hidden flex-row flex-wrap items-start justify-between rounded-md bg-greyVeryLight p-[26px] vl:flex"
                    dangerouslySetInnerHTML={{ __html: props.htmlContent }}
                />
            )}
        </>
    );
};

FooterBottom.displayName = 'FooterBottom';
