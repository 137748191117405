import { NavigationQueryApi } from 'graphql/generated';
import { useTypedTranslationFunction } from 'hooks/typescript/UseTypedTranslationFunction';
import { FC, useContext } from 'react';
import { DropdownMenuContext } from './DropdownMenu';
import { ArrowIcon, PercentIcon } from 'components/Basic/Icon/IconsSvg';
import { ExtendedNextLink } from 'components/Basic/ExtendedNextLink/ExtendedNextLink';

type PrimaryListProps = {
    navigationItems: NavigationQueryApi['navigationPanelCategories'];
    actionDiscountsLink: string;
};

const TEST_IDENTIFIER = 'layout-header-dropdownmenu-primarylist';

export const PrimaryList: FC<PrimaryListProps> = (props) => {
    const context = useContext(DropdownMenuContext);
    const t = useTypedTranslationFunction();

    return (
        <>
            {props.navigationItems.length > 0 &&
                props.navigationItems.map((navigationItem, index) => (
                    <div
                        key={navigationItem.uuid}
                        className="relative flex flex-wrap border-b border-greyLighter last:border-b-[0]"
                        onClick={context.scrollToTop}
                        data-testid={`${TEST_IDENTIFIER}-${index}`}
                    >
                        <ExtendedNextLink
                            type="category"
                            href={navigationItem.slug}
                            className="relative flex flex-1 items-center pb-[18px] pl-[30px] pr-[45px] pt-[20px] text-default font-semiBold text-primary no-underline"
                            onClick={context.onMenuToggleHandler}
                        >
                            {navigationItem.nameForNavigationPanel}
                        </ExtendedNextLink>
                        {navigationItem.hasChildren && (
                            <div
                                className="flex w-[45px] cursor-pointer items-center justify-center"
                                onClick={() =>
                                    context.slideRight({
                                        goToMenu: 'secondary',
                                        navigationItemUuid: navigationItem.uuid,
                                    })
                                }
                            >
                                <ArrowIcon className="h-4 w-4 -rotate-90 text-primary" />
                            </div>
                        )}
                    </div>
                ))}

            <div
                className="relative flex flex-wrap"
                onClick={context.scrollToTop}
                data-testid={`${TEST_IDENTIFIER}-actionDiscountsLink`}
            >
                <ExtendedNextLink
                    type="category"
                    href={props.actionDiscountsLink}
                    className="relative flex flex-1 items-center pb-[18px] pl-[30px] pr-[45px] pt-[20px] text-default font-semiBold text-red no-underline hover:text-red"
                    onClick={context.onMenuToggleHandler}
                >
                    <PercentIcon className="mr-[7px] h-[18px] w-[18px] text-red" />
                    {t('Promotions and Discounts')}
                    <div className="absolute bottom-0 right-0 top-0 flex w-[45px] items-center justify-center">
                        <ArrowIcon className="h-6 w-4 -rotate-90 text-primary" />
                    </div>
                </ExtendedNextLink>
            </div>
        </>
    );
};

PrimaryList.displayName = 'PrimaryList';
