import {
    BillingAddressFragmentApi,
    ContactAddressTypeEnumApi,
    CurrentCustomerUserQueryApi,
    DeliveryAddressTypeEnumApi,
    GenderTypeEnumApi,
    useCurrentCustomerUserQueryApi,
} from 'graphql/generated';
import { BillingAddressDataType } from 'types/billingAddress';
import { CustomerTypeEnum, DeliveryAddressType } from 'types/customer';
import { ContactInformationApiType } from 'types/form';
import { mapDeliveryAddress } from './CurrentCustomer';
import { useBroadcastChannel } from 'hooks/useBroadcastChannel';
import { usePersistStore } from 'store/zustand/usePersistStore';
import { useEffect, useState } from 'react';

export function useCurrentCustomerContactInformationQuery(): {
    data: ContactInformationApiType | null | undefined;
    fetchingAfterBroadcastChange: boolean;
} {
    const [changeBroadcast, setChangeBroadcast] = useState(false);
    const [fetchingAfterBroadcastChange, setFetchingAfterBroadcastChange] = useState(false);
    const [{ data, stale }, refetch] = useCurrentCustomerUserQueryApi();
    const resetContactInformationState = usePersistStore((s) => s.resetContactInformationState);
    const resetUserInformationState = usePersistStore((s) => s.resetUserInformationState);
    const resetDeliveryInformationState = usePersistStore((s) => s.resetDeliveryInformationState);

    useBroadcastChannel({
        name: 'currentCustomerUserBroadcast',
        callBack: (data) => {
            if (data.currentCustomerUser === 'userInformation') {
                resetUserInformationState(false);
            } else if (data.currentCustomerUser === 'deliveryInformation') {
                resetDeliveryInformationState(false);
            } else {
                resetContactInformationState(false);
            }

            refetch({ requestPolicy: 'network-only' });
            setChangeBroadcast(true);
        },
    });

    useEffect(() => {
        if (changeBroadcast && stale) {
            setFetchingAfterBroadcastChange(true);
            setChangeBroadcast(false);
        }

        if (fetchingAfterBroadcastChange && !stale) {
            setFetchingAfterBroadcastChange(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stale]);

    if (data?.currentCustomerUser === undefined) {
        return { data: undefined, fetchingAfterBroadcastChange };
    }

    return {
        data: mapCurrentCustomerContactInformationApiData(data.currentCustomerUser),
        fetchingAfterBroadcastChange,
    };
}

const mapCurrentCustomerContactInformationApiData = (
    apiCurrentCustomerUserData: CurrentCustomerUserQueryApi['currentCustomerUser'],
): ContactInformationApiType | null => {
    if (apiCurrentCustomerUserData === null) {
        return null;
    }

    const currentCustomerUserData = {
        ...apiCurrentCustomerUserData,
        billingAddresses: mapCurrentCustomerBillingAddressesApiData(apiCurrentCustomerUserData.billingAddresses),
    };

    const billingAddressData = () => {
        const defaultBillingAddress = currentCustomerUserData.billingAddresses.find(
            (billingAddress) => billingAddress.uuid === currentCustomerUserData.defaultBillingAddressUuid,
        );

        if (defaultBillingAddress !== undefined) {
            return {
                street: defaultBillingAddress.street,
                city: defaultBillingAddress.city,
                postcode: defaultBillingAddress.postcode,
                companyName: defaultBillingAddress.companyName,
                companyNumber: defaultBillingAddress.companyNumber,
                companyTaxNumber: defaultBillingAddress.companyTaxNumber,
                companySkVatNumber: defaultBillingAddress.companySkVatNumber,
                billingAddressUuid: defaultBillingAddress.uuid,
                contactCity: defaultBillingAddress.city,
                contactPostcode: defaultBillingAddress.postcode,
                contactStreet: defaultBillingAddress.street,
                deliveryAddresses: defaultBillingAddress.deliveryAddresses,
                defaultDeliveryAddressUuid: defaultBillingAddress.defaultDeliveryAddressUuid,
                contactAddresses: defaultBillingAddress.contactAddresses,
            };
        }

        return {
            street: currentCustomerUserData.billingAddresses[0].street,
            city: currentCustomerUserData.billingAddresses[0].city,
            postcode: currentCustomerUserData.billingAddresses[0].postcode,
            companyName: currentCustomerUserData.billingAddresses[0].companyName,
            companyNumber: currentCustomerUserData.billingAddresses[0].companyNumber,
            companyTaxNumber: currentCustomerUserData.billingAddresses[0].companyTaxNumber,
            companySkVatNumber: currentCustomerUserData.billingAddresses[0].companySkVatNumber,
            billingAddressUuid: currentCustomerUserData.billingAddresses[0].uuid,
            contactCity: currentCustomerUserData.billingAddresses[0].city,
            contactPostcode: currentCustomerUserData.billingAddresses[0].postcode,
            contactStreet: currentCustomerUserData.billingAddresses[0].street,
            deliveryAddresses: currentCustomerUserData.billingAddresses[0].deliveryAddresses,
            defaultDeliveryAddressUuid: currentCustomerUserData.billingAddresses[0].defaultDeliveryAddressUuid,
            contactAddresses: currentCustomerUserData.billingAddresses[0].contactAddresses,
        };
    };

    const deliveryAddressData = () => {
        const defaultDeliveryAddress = billingAddressData().deliveryAddresses?.find(
            (deliveryAddress) => deliveryAddress.uuid === billingAddressData().defaultDeliveryAddressUuid,
        );

        if (defaultDeliveryAddress !== undefined) {
            return {
                deliveryType: defaultDeliveryAddress.type,
                deliveryFirstName: defaultDeliveryAddress.firstName,
                deliveryLastName: defaultDeliveryAddress.lastName,
                deliveryCompanyName: defaultDeliveryAddress.companyName,
                deliveryTelephone: defaultDeliveryAddress.telephone,
                deliveryStreet: defaultDeliveryAddress.street,
                deliveryCity: defaultDeliveryAddress.city,
                deliveryPostcode: defaultDeliveryAddress.postcode,
                deliveryCountry: defaultDeliveryAddress.country,
                deliveryAddressUuid: defaultDeliveryAddress.uuid,
            };
        }

        return {
            deliveryType:
                currentCustomerUserData.billingAddresses[0].deliveryAddresses?.[0].type ??
                DeliveryAddressTypeEnumApi.SameAsBillingApi,
            deliveryFirstName: currentCustomerUserData.billingAddresses[0].deliveryAddresses?.[0].firstName ?? '',
            deliveryLastName: currentCustomerUserData.billingAddresses[0].deliveryAddresses?.[0].lastName ?? '',
            deliveryCompanyName: currentCustomerUserData.billingAddresses[0].deliveryAddresses?.[0].companyName ?? '',
            deliveryTelephone: currentCustomerUserData.billingAddresses[0].deliveryAddresses?.[0].telephone ?? '',
            deliveryStreet: currentCustomerUserData.billingAddresses[0].deliveryAddresses?.[0].street ?? '',
            deliveryCity: currentCustomerUserData.billingAddresses[0].deliveryAddresses?.[0].city ?? '',
            deliveryPostcode: currentCustomerUserData.billingAddresses[0].deliveryAddresses?.[0].postcode ?? '',
            deliveryCountry: currentCustomerUserData.billingAddresses[0].deliveryAddresses?.[0].country ?? '',
            deliveryAddressUuid: currentCustomerUserData.billingAddresses[0].deliveryAddresses?.[0].uuid ?? null,
        };
    };

    const currentCustomerContactAddress = billingAddressData().contactAddresses.find(
        (address) => address.customerUser.email === currentCustomerUserData.email,
    );

    // EXTEND CUSTOMER CONTACT INFORMATION HERE

    return {
        ...currentCustomerUserData,
        street: billingAddressData().street,
        city: billingAddressData().city,
        postcode: billingAddressData().postcode,
        companyName: billingAddressData().companyName,
        companyNumber: billingAddressData().companyNumber,
        companyTaxNumber: billingAddressData().companyTaxNumber,
        companySkVatNumber: billingAddressData().companySkVatNumber,
        billingAddressUuid: billingAddressData().billingAddressUuid,
        defaultBillingAddressUuid: currentCustomerUserData.defaultBillingAddressUuid,
        deliveryType: deliveryAddressData().deliveryType,
        deliveryFirstName: deliveryAddressData().deliveryFirstName,
        deliveryLastName: deliveryAddressData().deliveryLastName,
        deliveryCompanyName: deliveryAddressData().deliveryCompanyName,
        deliveryTelephone: deliveryAddressData().deliveryTelephone,
        deliveryStreet: deliveryAddressData().deliveryStreet,
        deliveryCity: deliveryAddressData().deliveryCity,
        deliveryPostcode: deliveryAddressData().deliveryPostcode,
        deliveryCountry: deliveryAddressData().deliveryCountry,
        deliveryAddressUuid: deliveryAddressData().deliveryAddressUuid,
        defaultDeliveryAddressUuid: billingAddressData().defaultDeliveryAddressUuid,
        addNewDeliveryAddress: deliveryAddressData().deliveryAddressUuid === null ? true : false,
        contactType: currentCustomerContactAddress?.type ?? ContactAddressTypeEnumApi.SameAsBillingApi,
        contactCity: currentCustomerContactAddress?.city ?? billingAddressData().contactCity,
        contactPostcode: currentCustomerContactAddress?.postcode ?? billingAddressData().contactPostcode,
        contactStreet: currentCustomerContactAddress?.street ?? billingAddressData().contactStreet,
        contactCountry: currentCustomerUserData.country.code,
        telephone: currentCustomerUserData.telephone ?? '',
        country: currentCustomerUserData.country.code,
        register: false,
        customer:
            currentCustomerUserData.__typename === 'CompanyCustomerUser'
                ? CustomerTypeEnum.CompanyCustomer
                : CustomerTypeEnum.CommonCustomer,
        gender:
            currentCustomerUserData.gender === GenderTypeEnumApi.MrApi
                ? GenderTypeEnumApi.MrApi
                : GenderTypeEnumApi.MrsApi,
        firstName: currentCustomerUserData.firstName ?? '',
        lastName: currentCustomerUserData.lastName ?? '',
        firstNameVocative: currentCustomerUserData.firstNameVocative ?? '',
        lastNameVocative: currentCustomerUserData.lastNameVocative ?? '',
        genderHelper: '',
        note: '',
        questionaryConsent: false,
        dealerCash: '',
        customerReference: '',
        selectedBillingAddressUuid: '',
    };
};

export const mapCurrentCustomerBillingAddressesApiData = (
    currentCustomerUserBillingAddresses: BillingAddressFragmentApi[],
): BillingAddressDataType[] => {
    const getBillingAddressesFormData = currentCustomerUserBillingAddresses.reduce(
        (result: BillingAddressDataType[], billingAddress) => {
            result.push({
                uuid: billingAddress.uuid,
                street: billingAddress.street ?? '',
                city: billingAddress.city ?? '',
                postcode: billingAddress.postcode ?? '',
                companyCustomer: billingAddress.companyCustomer ?? false,
                companyName: billingAddress.companyName ?? '',
                companyNumber: billingAddress.companyNumber ?? '',
                companyTaxNumber: billingAddress.companyTaxNumber ?? '',
                companySkVatNumber: billingAddress.companySkVatNumber ?? '',
                deliveryAddresses: mapCurrentCustomerDeliveryAddressesApiData(billingAddress.deliveryAddresses),
                defaultDeliveryAddressUuid: billingAddress.defaultDeliveryAddressUuid,
                contactAddresses: billingAddress.contactAddresses,
            });

            return result;
        },
        [],
    );

    return getBillingAddressesFormData;
};

export function mapCurrentCustomerDeliveryAddressesApiData(
    apiDeliveryAddressesData: BillingAddressFragmentApi['deliveryAddresses'],
): DeliveryAddressType[] | null {
    if (apiDeliveryAddressesData.length === 0) {
        return null;
    }

    const currentCustomerDeliveryAddressesData = apiDeliveryAddressesData.reduce(
        (result: DeliveryAddressType[], deliveryAddress) => {
            result.push(mapDeliveryAddress(deliveryAddress));
            return result;
        },
        [],
    );

    return currentCustomerDeliveryAddressesData;
}
