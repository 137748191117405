import getConfig from 'next/config';
import { v4 } from 'uuid';

export const getCSPHeaders = (nonce: string): string => {
    const { publicRuntimeConfig } = getConfig();
    const contentSecurityPolicyPages = publicRuntimeConfig.contentSecurityPolicyPages;

    let value = '';
    for (const key in publicRuntimeConfig.contentSecurityPolicyPages) {
        value = `${value}${key} ${mapRow(contentSecurityPolicyPages[key])};`;
    }

    return value.replaceAll('NONCE_PLACEHOLDER', nonce);
};

const mapRow = (values: string[]): string => {
    let result = '';
    for (const key in values) {
        result = `${result} ${values[key]}`;
    }

    return result;
};

export const generateNonce = (): string => {
    return v4();
};
