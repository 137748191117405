import { LogoMetadata } from 'components/Basic/Head/LogoMetadata/LogoMetadata';
import { ReactElement, memo } from 'react';
import getConfig from 'next/config';
import { ExtendedNextLink } from 'components/Basic/ExtendedNextLink/ExtendedNextLink';

const { publicRuntimeConfig } = getConfig();
const cdnUrl = publicRuntimeConfig.cdnUrl;

export const Logo = memo(
    (): ReactElement => (
        <ExtendedNextLink type="static" href="/">
            <LogoMetadata />
            <img src={`${cdnUrl}/images/logo.png`} width={247} height={44} alt="B2Bpartner" />
        </ExtendedNextLink>
    ),
);

Logo.displayName = 'Logo';
