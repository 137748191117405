import { AnchorHTMLAttributes, ButtonHTMLAttributes, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { ExtractNativePropsFromDefault } from 'typeHelpers/ExtractNativePropsFromDefault';
import { ButtonType } from 'types/button';
import { LinkProps } from 'next/link';
import { ExtendedNextLink } from 'components/Basic/ExtendedNextLink/ExtendedNextLink';
import twMerge from 'utils/twMerge';
import { SpinnerIcon } from 'components/Basic/Icon/IconsSvg';

type NativeButtonProps = ExtractNativePropsFromDefault<
    ButtonHTMLAttributes<HTMLButtonElement>,
    never,
    'onClick' | 'style' | 'onMouseEnter' | 'className'
>;

type NativeAnchorProps = ExtractNativePropsFromDefault<
    AnchorHTMLAttributes<HTMLAnchorElement>,
    never,
    'onClick' | 'style' | 'target' | 'className' | 'onMouseEnter'
>;

type ButtonProps = ButtonType &
    (
        | ({
              tagName?: 'button';
              href?: never;
              passHref?: never;
              shallow?: never;
              scroll?: never;
              isExternalLink?: never;
              target?: never;
              name?: string;
              id?: string;
              onClickLoading?: boolean;
              loading?: boolean;
          } & NativeButtonProps)
        | ({
              isExternalLink?: boolean;
              tagName?: 'a';
              name?: never;
              id?: string;
              onClickLoading?: boolean;
              loading?: boolean;
          } & NativeAnchorProps &
              LinkProps)
    );

/**
 * Global component for Buttons.
 * We can combine variants, sizes and border radius.
 * The component is connected to the Link component.
 */
export const Button: FC<ButtonProps> = (props) => {
    const formProviderMethods = useFormContext();
    const TagName = props.tagName ?? 'button';
    const [isLoading, setIsLoading] = useState(props.loading);

    useEffect(() => {
        setIsLoading(props.loading);
    }, [props.loading]);

    let buttonClassName = '';

    if (props.isLink === true) {
        buttonClassName = 'ui-link';
    } else {
        buttonClassName = twMerge(
            'inline-flex min-h-[47px] w-auto cursor-pointer items-center justify-center rounded-md border-0 bg-primary px-7 py-4 text-center text-small font-semiBold leading-4 text-white no-underline outline-none ui-transition hover:bg-primaryDarken hover:text-white hover:no-underline [&:hover_svg]:text-white',
            '[&_svg:first-child]:mr-3 [&_svg:last-child]:ml-3 [&_svg]:h-4 [&_svg]:w-4 [&_svg]:text-white [&_svg]:ui-transition',
            props.size === 'small' &&
                'min-h-[30px] px-7 py-[7px] [&_svg:first-child]:mr-[6px] [&_svg:last-child]:ml-[6px]',
            props.size === 'square' && 'min-h-[30px] p-[17px] [&_svg:first-child]:mr-0 [&_svg:last-child]:ml-0',
            props.size === 'smallSecondary' &&
                'min-h-[30px] px-[18px] py-[7px] [&_svg:first-child]:mr-[6px] [&_svg:last-child]:ml-[6px]',
            props.size === 'medium' && 'min-h-[40px] px-[28px] py-3',
            props.size === 'veryBig' &&
                'min-h-[70px] px-[55px] py-[26px] leading-[18px] [&_svg:first-child]:mr-4 [&_svg:last-child]:ml-4',
            props.variant === 'primary' && 'bg-green hover:bg-greenDark',
            props.variant === 'primaryLight' && 'bg-greenLight hover:bg-green',
            props.variant === 'secondary' &&
                'bg-greyLight text-grey hover:bg-greyMedium hover:text-grey [&:hover_svg]:text-grey [&_svg]:text-grey',
            props.variant === 'tertiary' && 'bg-grey hover:bg-[#41455B]',
            props.variant === 'outline' &&
                'bg-[transparent] text-primary borderInset-primary hover:bg-primary hover:text-white [&:hover_svg]:text-white [&_svg]:text-primary',
            props.variant === 'defaultLight' && 'bg-blueMedium hover:bg-primary',
            props.fontSize === 'big' && 'text-big leading-[18px] [&_svg]:h-[18px] [&_svg]:w-[18px]',
            props.fontSize === 'veryBig' && 'text-[26px] tracking-[1.2px] [&_svg]:h-[23px] [&_svg]:w-[23px]',
            props.styleType === 'bold' && 'font-bold',
            (props.isDisabled ||
                // formProviderMethods may be null probably when it is not used in FormProvider context - see https://github.com/react-hook-form/react-hook-form/discussions/3894
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                (props.type === 'submit' && formProviderMethods?.formState.isSubmitting)) &&
                'cursor-no-drop opacity-50',
            props.hasDisabledLook && 'cursor-no-drop opacity-50',
            props.className,
        );
    }

    const combinedOnClickFunction = (event: any) => {
        if (props.onClickLoading) {
            setIsLoading(true);
        }
        if (props.onClick !== undefined) {
            props.onClick(event);
        }
    };

    const button = (
        <TagName
            type={props.type}
            name={props.name}
            disabled={props.disabled}
            style={props.style}
            onMouseEnter={props.onMouseEnter as any}
            onClick={(e) => combinedOnClickFunction(e)}
            data-testid={props['data-testid']}
            className={buttonClassName}
            id={props.id}
        >
            {isLoading && <SpinnerIcon className="h-5 w-5" />}
            {props.children}
        </TagName>
    );

    if (props.tagName === 'a') {
        if (props.isExternalLink) {
            return button;
        }

        return (
            <ExtendedNextLink
                scroll={props.scroll}
                shallow={props.shallow}
                href={props.href}
                legacyBehavior
                type="static"
            >
                {button}
            </ExtendedNextLink>
        );
    }

    return button;
};

Button.displayName = 'Button';
