import { ArrowIcon } from 'components/Basic/Icon/IconsSvg';
import { useNavigationSubCategoriesApi } from 'graphql/generated';
import { useQueryError } from 'hooks/graphQl/UseQueryError';
import { FC, useContext } from 'react';
import { DropdownListLevels } from 'types/dropdown';
import { DropdownMenuContext } from './DropdownMenu';
import { twJoin } from 'tailwind-merge';
import { TertiaryList } from './TertiaryList';
import Skeleton from 'react-loading-skeleton';
import { ExtendedNextLink } from 'components/Basic/ExtendedNextLink/ExtendedNextLink';

type SecondaryListProps = {
    activeCategoryUuid: string | undefined;
    goToMenu: DropdownListLevels;
};

const TEST_IDENTIFIER = 'layout-header-dropdownmenu-secondarylist';

export const SecondaryList: FC<SecondaryListProps> = (props) => {
    const context = useContext(DropdownMenuContext);

    const [{ data, error, fetching }] = useNavigationSubCategoriesApi({
        variables: { uuid: props.activeCategoryUuid ?? '' },
        pause: props.activeCategoryUuid === undefined,
    });
    useQueryError(error);

    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!data?.category?.navigationChildren.length && !fetching) {
        return null;
    }

    return (
        <>
            <div
                className={twJoin(
                    'flex cursor-pointer items-center border-b border-greyLighter px-[26px] py-[18px] text-default font-semiBold text-primary',
                    'lg:px-[46px] lg:py-[18px]',
                )}
                onClick={() => context.slideLeft({ goToMenu: 'primary' })}
            >
                <ArrowIcon className="mr-[27px] h-[14px] w-[14px] rotate-90 text-primary" />
                {!fetching ? (
                    data?.category?.nameForNavigationPanel
                ) : (
                    <div className="w-full">
                        <Skeleton className="h-4" />
                    </div>
                )}
            </div>

            {!fetching
                ? data?.category?.navigationChildren.map((columnCategory, index) => (
                      <div
                          key={columnCategory.uuid}
                          className="relative mx-[10px] flex flex-wrap border-b border-greyLighter px-[16px] last:border-b-[0] lg:mx-[20px] lg:px-[26px]"
                          onClick={context.scrollToTop}
                          data-testid={`${TEST_IDENTIFIER}-${index}`}
                      >
                          <ExtendedNextLink
                              type="category"
                              href={columnCategory.slug}
                              className="relative flex flex-1 items-center pb-[14px] pt-[15px] text-small font-semiBold text-primary no-underline"
                              onClick={context.onMenuToggleHandler}
                          >
                              {columnCategory.nameForNavigationPanel}
                          </ExtendedNextLink>

                          <TertiaryList columnCategoryChildren={columnCategory.navigationChildren} />
                      </div>
                  ))
                : Array(8)
                      .fill(null)
                      .map((_, index) => (
                          <div
                              className="mx-[10px] border-b border-greyLighter px-[16px] last:border-b-[0] lg:mx-[20px] lg:px-[26px]"
                              key={index}
                          >
                              <div className="w-full py-[13px]">
                                  <Skeleton className="h-4" />
                              </div>
                          </div>
                      ))}
        </>
    );
};

SecondaryList.displayName = 'SecondaryList';
