import { createDomainSlice, DomainSlice } from './slices/createDomainSlice';
import { create } from 'zustand';
import { createPagingSlice, PagingSlice } from 'store/zustand/slices/createPagingSlice';

type SessionStore = DomainSlice & PagingSlice;

export const useSessionStore = create<SessionStore>()((...store) => ({
    ...createDomainSlice(...store),
    ...createPagingSlice(...store),
}));
