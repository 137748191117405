import { useEffect, useState } from 'react';

export const useIsScrollToTop = (): boolean => {
    const [isScrollToTop, setIsScrollToTop] = useState(false);
    const [lastScrollTopPosition, setLastScrollTopPosition] = useState(0);
    const [lastCheckedPosition, setLastCheckedPosition] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            const offsetToChange = 50;
            const currentScrollTop = window.scrollY;

            if (Math.abs(lastCheckedPosition - lastScrollTopPosition) > offsetToChange) {
                setIsScrollToTop(lastScrollTopPosition > currentScrollTop);
                setLastCheckedPosition(currentScrollTop);
            }
            setLastScrollTopPosition(currentScrollTop);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [lastCheckedPosition, lastScrollTopPosition]);

    return isScrollToTop;
};
