import { PriceFragmentApi, PriceInterfaceApi, ProductPriceApi } from 'graphql/generated';
import { PriceType, ProductPriceType } from 'types/price';

export const mapPriceData = (price: PriceInterfaceApi, currencyCode: string): PriceType => {
    return {
        priceWithVat: Number.parseFloat(price.priceWithVat),
        priceWithoutVat: Number.parseFloat(price.priceWithoutVat),
        vatAmount: Number.parseFloat(price.vatAmount),
        currencyCode,
    };
};

export const mapPriceInputData = (price: PriceType): PriceFragmentApi => {
    return {
        priceWithVat: price.priceWithVat.toString(),
        priceWithoutVat: price.priceWithoutVat.toString(),
        vatAmount: price.vatAmount.toString(),
        __typename: 'Price',
    };
};

//HOTFIX for service prices, do not use in the project
type SimplePriceTypeApi = {
    priceWithVat: string;
    priceWithoutVat: string;
    vatAmount: string;
};
export const mapServicePriceInputData = (price: PriceType): SimplePriceTypeApi => {
    return {
        priceWithVat: price.priceWithVat.toString(),
        priceWithoutVat: price.priceWithoutVat.toString(),
        vatAmount: price.vatAmount.toString(),
    };
};
// end of HOTFIX

export const mapProductPriceData = (
    price: ProductPriceApi,
    currencyCode: string,
    stockUnitName: string,
): ProductPriceType => {
    return {
        ...mapPriceData(price, currencyCode),
        isPriceFrom: price.isPriceFrom,
        discountPercent: Number.parseFloat(price.discountPercent),
        recommendedPrice:
            Number.parseFloat(price.recommendedPrice) !== 0 ? Number.parseFloat(price.recommendedPrice) : null,
        unitPrice:
            price.unitPrice !== null
                ? {
                      priceWithVat: Number.parseFloat(price.unitPrice.priceWithVat),
                      priceWithoutVat: Number.parseFloat(price.unitPrice.priceWithoutVat),
                      vatAmount: Number.parseFloat(price.unitPrice.vatAmount),
                      currencyCode,
                  }
                : null,
        discountPrice: Number.parseFloat(price.discountPrice),
        stockUnitName: stockUnitName,
    };
};
