import { ArrowCheckIcon } from 'components/Basic/Icon/IconsSvg';
import { LabelHTMLAttributes, ReactNode } from 'react';
import { ExtractNativePropsFromDefault } from 'typeHelpers/ExtractNativePropsFromDefault';
import twMerge from 'utils/twMerge';

type NativeProps = ExtractNativePropsFromDefault<LabelHTMLAttributes<HTMLLabelElement>, never, 'htmlFor' | 'className'>;

type LabelWrapperProps = NativeProps & {
    /**
     * Display Label of the given HTML element
     */
    label: string | ReactNode | ReactNode[];
    /**
     * Display count of items. This is an optional prop primary from the parameters filter.
     */
    count?: number;
    /**
     * A prop based on which the CSS stzling is applied, as there is a slightly different
     * styling for each of the elements below.
     */
    inputType: 'textarea' | 'text-input' | 'checkbox' | 'radio' | 'selectbox';
    /**
     * A prop based on which the "required symbol" (star) is displayed next to the label
     */
    required?: boolean;
    /**
     * Type of placeholder for check if the placeholder is static or adaptive.
     */
    placeholderType?: 'static' | 'none';
    /**
     * check if checkbox is checked
     */
    checked?: boolean;
    /**
     * Set floating label if the selectbox has a value or if the menu is open.
     */
    selectBoxLabelIsFloated?: boolean;
    inputSize?: 'small' | 'smallerMedium' | 'medium';
    $color?: string;
    $fontSize?: 'big' | 'medium';
    isEmpty?: boolean;
    disabled?: boolean;
    classNameLabel?: string;
    dataTestId?: string;
};

export const LabelWrapper: FC<LabelWrapperProps> = ({
    inputType,
    $fontSize,
    $color,
    label,
    htmlFor,
    count,
    required,
    children,
    className,
    placeholderType,
    selectBoxLabelIsFloated,
    isEmpty,
    inputSize,
    checked,
    disabled,
    classNameLabel,
    dataTestId,
}) => {
    return (
        <div
            className={twMerge(
                'relative flex w-full items-center',
                '[&.foxentry-input-loading>div_i]:hidden [&_.foxentry-input-icon]:!z-[50]',
                className,
            )}
        >
            {children}
            {placeholderType !== 'static' && (
                <label
                    data-testid={dataTestId ?? `${htmlFor}-label`}
                    className={twMerge(
                        'group',
                        (inputType === 'text-input' || inputType === 'selectbox' || inputType === 'textarea') &&
                            placeholderType !== 'none' && [
                                'absolute left-3 z-above-1 block text-small leading-[14px] text-grey ui-transition',
                                'peer-read-only:cursor-not-allowed peer-read-only:opacity-50',
                                'peer-disabled:cursor-not-allowed peer-disabled:opacity-50',
                            ],
                        (inputType === 'text-input' || inputType === 'selectbox' || inputType === 'textarea') &&
                            (selectBoxLabelIsFloated === undefined || selectBoxLabelIsFloated === true) && [
                                'peer-focus:transform-none',
                                !isEmpty && 'transform-none',
                            ],
                        inputType === 'radio' && [
                            'relative inline min-h-[18px] cursor-pointer select-none pl-[30px] text-base',
                            'peer-checked:font-bold',
                            'peer-disabled:cursor-not-allowed peer-disabled:[&>span]:text-greyMedium',
                        ],
                        inputType === 'textarea' && 'pointer-events-none top-5 translate-y-0 pr-3',
                        inputType === 'text-input' &&
                            placeholderType !== 'none' &&
                            'pointer-events-none top-1/2 -translate-y-1/2 pr-3',
                        (inputType === 'text-input' || inputType === 'textarea') && [
                            inputSize === 'smallerMedium' ? 'peer-focus:top-[5px]' : 'peer-focus:top-[9px]',
                            'peer-focus:text-extraVerySmall',
                            !isEmpty && 'text-extraVerySmall',
                            !isEmpty && inputSize === 'smallerMedium' && 'top-[5px]',
                            !isEmpty && inputSize !== 'smallerMedium' && 'top-[9px]',
                        ],
                        inputType === 'selectbox' &&
                            'pointer-events-none top-1/2 -translate-y-1/2 pr-3 peer-read-only:cursor-auto peer-read-only:opacity-100',
                        inputType === 'selectbox' && selectBoxLabelIsFloated && 'top-[9px] text-extraVerySmall',
                        inputType === 'checkbox' && [
                            'relative inline min-h-[18px] cursor-pointer select-none pl-[30px] pt-[1px] text-littleSmall text-base',
                            $fontSize === 'big' && 'text-default',
                            $fontSize === 'medium' && 'text-small',
                            'peer-checked:font-bold',
                            'peer-disabled:cursor-not-allowed peer-disabled:text-greyMedium peer-disabled:before:cursor-not-allowed',
                            '[&>a]:underline [&>a]:hover:no-underline [&>a]:focus:no-underline [&>a]:active:no-underline',
                        ],
                        classNameLabel,
                    )}
                    htmlFor={htmlFor}
                    style={{ color: inputType === 'checkbox' && $color !== undefined ? $color : undefined }}
                >
                    {(inputType === 'checkbox' || inputType === 'radio') && (
                        <div
                            className={twMerge(
                                'absolute left-0 flex h-[18px] w-[18px] border border-base bg-white text-white transition group-hover:border-primary group-active:border-primary',
                                inputType === 'checkbox' &&
                                    'top-0 rounded-[3px] px-[3px] pb-[2px] pt-[4px] group-active:border-[2px]',
                                checked &&
                                    inputType === 'checkbox' &&
                                    'border-[2px] border-primary px-[2px] pb-[1px] pt-[3px]',
                                inputType === 'radio' && 'top-[1px] rounded-full group-active:border-[2px]',
                                checked && inputType === 'radio' && 'border-[6px] border-primary',
                                disabled &&
                                    'group-active:border-1 border-greyMedium outline-0 group-hover:border-greyMedium group-active:border-greyMedium',
                                disabled && inputType === 'radio' && 'bg-greyVeryLight',
                                checked && disabled && inputType === 'checkbox' && 'bg-greyMedium',
                                checked && disabled && inputType === 'radio' && 'bg-greyVeryLight',
                            )}
                        >
                            {inputType === 'checkbox' && (
                                <ArrowCheckIcon
                                    className={twMerge(
                                        'h-full w-full text-orange opacity-0 transition',
                                        checked && 'opacity-100',
                                        disabled && 'text-white',
                                    )}
                                />
                            )}
                        </div>
                    )}
                    {label}
                    {count !== undefined && count > 0 && `\u00A0(${count})`}
                    {required && <span className="ml-[5px] text-red">*</span>}
                </label>
            )}
        </div>
    );
};

LabelWrapper.displayName = 'LabelWrapper';
