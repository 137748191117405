import getConfig from 'next/config';

const domainsConfig = getConfig().publicRuntimeConfig.domains as DomainConfigType[];

export type DomainConfigType = {
    url: string;
    publicGraphqlEndpoint: string;
    defaultLocale: string;
    currencyCode: string;
    domainId: number;
    mapSetting: {
        latitude: number;
        longitude: number;
        zoom: number;
    };
    gtmId?: string;
    inputDateFormat: string;
    gtmUrl?: string;
    optimonkAccountId?: string;
};

export function getDomainConfig(domainUrl: string): DomainConfigType {
    const replacedDomain = domainUrl.replace(':3000', ':8000');

    for (const domain of domainsConfig) {
        const publicDomainUrl = new URL(domain.url || '').host;

        if (publicDomainUrl === replacedDomain) {
            return { ...domain, publicGraphqlEndpoint: domain.publicGraphqlEndpoint || '', url: domain.url || '' };
        }
    }

    throw new Error('Domain `' + replacedDomain + '` is not known domain');
}
