import { getUrlWithoutGetParameters } from './getUrlWithoutGetParameters';

export const getSlugFromUrl = (originalUrl: string): string => {
    return getUrlWithoutGetParameters(originalUrl).slice(1, -1);
};

export const getSlugFromServerSideUrl = (originalUrl: string): string => {
    const urlSegments = originalUrl.split('/');

    return urlSegments[urlSegments.length - 2].split('.')[0].split('?')[0]; // remove extension from segment
};
