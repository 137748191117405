import { StateCreator } from 'zustand/esm';

export type PagingSlice = {
    isClientPaging: boolean;
    setPagingState: (value: Partial<PagingSlice>) => void;
};
export const createPagingSlice: StateCreator<PagingSlice> = (set) => ({
    isClientPaging: false,

    setPagingState: (value) => {
        set(value);
    },
});
