import { ChatIcon, BookIcon, CatalogIcon, CalculatorIcon } from 'components/Basic/Icon/IconsSvg';
import { useTypedTranslationFunction } from 'hooks/typescript/UseTypedTranslationFunction';
import { FC, useContext } from 'react';
import { getInternationalizedStaticUrls } from 'utils/getInternationalizedStaticUrls';
import { Locale } from 'helpers/locale';
import { DropdownMenuContext } from './DropdownMenu';
import { useDomainConfig } from 'hooks/useDomainConfig';
import { ExtendedNextLink } from 'components/Basic/ExtendedNextLink/ExtendedNextLink';
import { useGetMobileBottomGap } from '../../../../hooks/ui/useGetMobileBottomGap';

const TEST_IDENTIFIER = 'layout-header-dropdownmenu-submenu';

export const SubMenu: FC = () => {
    const context = useContext(DropdownMenuContext);
    const t = useTypedTranslationFunction();
    const { url, defaultLocale } = useDomainConfig();
    const [demandCartUrl, complaintUrl] = getInternationalizedStaticUrls(['/demandCart/', '/complaints/'], url);

    const SubMenuItem_twClass =
        'mb-[26px] flex items-center px-[30px] text-small text-baseAlt no-underline last:mb-0 hover:text-baseAlt';
    const SubMenuItemIcon_twClass = 'mr-[14px] h-[18px] w-[18px] text-baseAlt';

    const menuBottomGap = useGetMobileBottomGap();

    return (
        <div
            className="mt-[30px] flex flex-col"
            data-testid={TEST_IDENTIFIER}
            style={{ paddingBottom: `${menuBottomGap}px` }}
        >
            <ExtendedNextLink
                type="article"
                href="/kontakt/"
                className={SubMenuItem_twClass}
                onClick={context.onMenuToggleHandler}
                data-testid={`${TEST_IDENTIFIER}-0`}
            >
                <ChatIcon className={SubMenuItemIcon_twClass} />
                {t('Contacts')}
            </ExtendedNextLink>
            <ExtendedNextLink
                type="static"
                href={complaintUrl}
                className={SubMenuItem_twClass}
                onClick={context.onMenuToggleHandler}
                data-testid={`${TEST_IDENTIFIER}-1`}
            >
                <BookIcon className={SubMenuItemIcon_twClass} />
                {t('Complaint')}
            </ExtendedNextLink>
            <ExtendedNextLink
                type="static"
                href={demandCartUrl}
                className={SubMenuItem_twClass}
                onClick={context.onMenuToggleHandler}
                data-testid={`${TEST_IDENTIFIER}-2`}
            >
                <CalculatorIcon className={SubMenuItemIcon_twClass} />
                {t('Online demand')}
            </ExtendedNextLink>
            {(defaultLocale === Locale.CS || defaultLocale === Locale.SK) && (
                <ExtendedNextLink
                    type="article"
                    href="/nase-katalogy/"
                    className={SubMenuItem_twClass}
                    onClick={context.onMenuToggleHandler}
                    data-testid={`${TEST_IDENTIFIER}-3`}
                >
                    <CatalogIcon className={SubMenuItemIcon_twClass} />
                    {t('Catalog')}
                </ExtendedNextLink>
            )}
        </div>
    );
};

SubMenu.displayName = 'SubMenu';
