import { ArticleDetailFragmentApi, ArticlePlacementTypeEnumApi, SimpleArticleFragmentApi } from 'graphql/generated';
import { ArticleDetailType, SimpleArticleType } from 'types/article';

export const footerArticlesQueryVariable = {
    placement: [
        ArticlePlacementTypeEnumApi.Footer1Api,
        ArticlePlacementTypeEnumApi.Footer2Api,
        ArticlePlacementTypeEnumApi.Footer3Api,
    ],
    first: 100,
};

export const mapArticleDetail = (apiData: ArticleDetailFragmentApi): ArticleDetailType => {
    return {
        ...apiData,
        __typename: 'Article',
        seoMetaDescription: apiData.seoMetaDescription ?? '',
    };
};

export const mapSimpleArticle = (apiData: SimpleArticleFragmentApi): SimpleArticleType => {
    return {
        ...apiData,
    };
};
