import { useCurrentCustomerContactInformationQuery } from 'connectors/customer/CurrentCustomerUser';
import { ContactAddressTypeEnumApi, DeliveryAddressTypeEnumApi, GenderTypeEnumApi } from 'graphql/generated';
import { useMemo } from 'react';
import { usePersistStore } from 'store/zustand/usePersistStore';
import { ContactInformationApiType, ContactInformationFormType } from 'types/form';
import { useDomainConfig } from 'hooks/useDomainConfig';
import { countryCurrentCode } from 'helpers/country';

export const useCurrentUserContactInformation = (): ContactInformationApiType => {
    const { defaultLocale } = useDomainConfig();
    const { data: apiData } = useCurrentCustomerContactInformationQuery();
    const {
        email,
        register,
        customer,
        telephone,
        gender,
        genderHelper,
        firstName,
        firstNameVocative,
        lastName,
        lastNameVocative,
        street,
        city,
        postcode,
        companyName,
        companyNumber,
        companyTaxNumber,
        companySkVatNumber,
        deliveryType,
        deliveryFirstName,
        deliveryLastName,
        deliveryCompanyName,
        deliveryTelephone,
        deliveryStreet,
        deliveryCity,
        deliveryPostcode,
        contactStreet,
        contactCity,
        contactPostcode,
        contactType,
        note,
        questionaryConsent,
        customerReference,
        dealerCash,
        selectedBillingAddressUuid,
    } = usePersistStore((s) => s);

    const currentUserContactInformationFromApi = useMemo<Partial<ContactInformationApiType>>(
        () => ({
            ...(apiData ? apiData : {}),
        }),
        [apiData],
    );

    const currentUserContactInformationFromRedux = useMemo<ContactInformationFormType>(
        () => ({
            email,
            register,
            customer,
            telephone,
            gender,
            genderHelper,
            firstName,
            firstNameVocative,
            lastName,
            lastNameVocative,
            street,
            city,
            postcode,
            companyName,
            companyNumber,
            companyTaxNumber,
            companySkVatNumber,
            deliveryType,
            deliveryFirstName,
            deliveryLastName,
            deliveryCompanyName,
            deliveryTelephone,
            deliveryStreet,
            deliveryCity,
            deliveryPostcode,
            contactStreet,
            contactCity,
            contactPostcode,
            contactType,
            note,
            questionaryConsent,
            customerReference,
            dealerCash,
            selectedBillingAddressUuid,
            addNewDeliveryAddress: false,
            billingAddresses: [],
            billingAddressUuid: null,
            defaultBillingAddressUuid: null,
            deliveryAddressUuid: null,
            defaultDeliveryAddressUuid: null,
        }),
        [
            city,
            companyName,
            companyNumber,
            companySkVatNumber,
            companyTaxNumber,
            contactCity,
            contactPostcode,
            contactStreet,
            contactType,
            customer,
            customerReference,
            dealerCash,
            deliveryCity,
            deliveryCompanyName,
            deliveryFirstName,
            deliveryLastName,
            deliveryPostcode,
            deliveryStreet,
            deliveryTelephone,
            deliveryType,
            email,
            firstName,
            firstNameVocative,
            gender,
            genderHelper,
            lastName,
            lastNameVocative,
            note,
            postcode,
            questionaryConsent,
            register,
            selectedBillingAddressUuid,
            street,
            telephone,
        ],
    );

    return useMemo(
        () =>
            mergeCurrentUserContactInformationFromApiAndRedux(
                currentUserContactInformationFromApi,
                currentUserContactInformationFromRedux,
                defaultLocale,
            ),
        [currentUserContactInformationFromApi, currentUserContactInformationFromRedux, defaultLocale],
    );
};

const mergeCurrentUserContactInformationFromApiAndRedux = (
    currentUserContactInformationFromApi: Partial<ContactInformationApiType>,
    currentUserContactInformationFromRedux: ContactInformationFormType,
    defaultLocale: string,
): ContactInformationApiType => {
    const filteredCurrentUserContactInformationFromRedux: ContactInformationFormType = {
        ...currentUserContactInformationFromRedux,
    };

    for (const key in filteredCurrentUserContactInformationFromRedux) {
        const filteredProperty =
            filteredCurrentUserContactInformationFromRedux[key as keyof ContactInformationFormType];

        const isEmptyString = typeof filteredProperty === 'string' && filteredProperty.length === 0;
        if (
            (isEmptyString ||
                filteredProperty === undefined ||
                filteredProperty === GenderTypeEnumApi.MrApi ||
                filteredProperty === ContactAddressTypeEnumApi.SameAsBillingApi ||
                filteredProperty === DeliveryAddressTypeEnumApi.SameAsBillingApi) &&
            key in currentUserContactInformationFromApi
        ) {
            delete filteredCurrentUserContactInformationFromRedux[key as keyof ContactInformationFormType];
        }
    }

    const { country, deliveryCountry, contactCountry, ...currentUserContactInformationWithoutCountryFromApi } =
        currentUserContactInformationFromApi;

    return {
        ...currentUserContactInformationWithoutCountryFromApi,
        ...filteredCurrentUserContactInformationFromRedux,
        addNewDeliveryAddress:
            currentUserContactInformationFromApi.addNewDeliveryAddress !== undefined
                ? currentUserContactInformationFromApi.addNewDeliveryAddress
                : currentUserContactInformationFromRedux.addNewDeliveryAddress,
        billingAddresses:
            currentUserContactInformationFromApi.billingAddresses !== undefined
                ? currentUserContactInformationFromApi.billingAddresses
                : currentUserContactInformationFromRedux.billingAddresses,
        billingAddressUuid:
            currentUserContactInformationFromApi.billingAddressUuid !== undefined
                ? currentUserContactInformationFromApi.billingAddressUuid
                : currentUserContactInformationFromRedux.billingAddressUuid,
        defaultBillingAddressUuid:
            currentUserContactInformationFromApi.defaultBillingAddressUuid !== undefined
                ? currentUserContactInformationFromApi.defaultBillingAddressUuid
                : currentUserContactInformationFromRedux.defaultBillingAddressUuid,
        deliveryAddressUuid:
            currentUserContactInformationFromApi.deliveryAddressUuid !== undefined
                ? currentUserContactInformationFromApi.deliveryAddressUuid
                : currentUserContactInformationFromRedux.deliveryAddressUuid,
        defaultDeliveryAddressUuid:
            currentUserContactInformationFromApi.defaultDeliveryAddressUuid !== undefined
                ? currentUserContactInformationFromApi.defaultDeliveryAddressUuid
                : currentUserContactInformationFromRedux.defaultDeliveryAddressUuid,
        country: country ? country : countryCurrentCode(defaultLocale),
        deliveryCountry: deliveryCountry ? deliveryCountry : countryCurrentCode(defaultLocale),
        contactCountry: contactCountry ? contactCountry : countryCurrentCode(defaultLocale),
    };
};
