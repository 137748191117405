export const SEARCH_QUERY_PARAMETER_NAME = 'query' as const;
export const SEARCH_QUERY_PARAMETER_NAME_SHORT = 'q' as const;
export const PAGE_QUERY_PARAMETER_NAME = 'page' as const;
export const LOAD_MORE_PAGE_QUERY_PARAMETER_NAME = 'loadMorePage' as const;
export const FILTER_QUERY_PARAMETER_NAME = 'filter' as const;
export const SORT_QUERY_PARAMETER_NAME = 'sort' as const;
export const INTERNAL_QUERY_PARAMETERS = [
    SEARCH_QUERY_PARAMETER_NAME,
    SEARCH_QUERY_PARAMETER_NAME_SHORT,
    PAGE_QUERY_PARAMETER_NAME,
    LOAD_MORE_PAGE_QUERY_PARAMETER_NAME,
    FILTER_QUERY_PARAMETER_NAME,
    SORT_QUERY_PARAMETER_NAME,
];
