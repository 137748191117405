import { ContactAddressTypeEnumApi, DeliveryAddressTypeEnumApi, GenderTypeEnumApi } from 'graphql/generated';
import { ContactInformationDefaultType } from 'types/form';
import { StateCreator } from 'zustand';
import { dispatchBroadcastChannel } from 'hooks/useBroadcastChannel';

const userInformationInitialState = {
    firstName: '',
    firstNameVocative: '',
    lastName: '',
    lastNameVocative: '',
    email: '',
    telephone: '',
    gender: GenderTypeEnumApi.MrApi,
    genderHelper: '',
};

const deliveryInformationInitialState = {
    deliveryType: DeliveryAddressTypeEnumApi.SameAsBillingApi,
    deliveryFirstName: '',
    deliveryLastName: '',
    deliveryCompanyName: '',
    deliveryTelephone: '',
    deliveryStreet: '',
    deliveryCity: '',
    deliveryPostcode: '',
};

const contactInformationInitialState = {
    register: false,
    customer: undefined,
    street: '',
    city: '',
    postcode: '',
    companyName: '',
    companyNumber: '',
    companyTaxNumber: '',
    companySkVatNumber: '',
    contactStreet: '',
    contactCity: '',
    contactPostcode: '',
    contactType: ContactAddressTypeEnumApi.SameAsBillingApi,
    note: '',
    questionaryConsent: false,
    customerReference: '',
    dealerCash: '',
    selectedBillingAddressUuid: '',
};

export type ContactInformationSlice = ContactInformationDefaultType & {
    updateContactInformationState: (value: Partial<ContactInformationSlice>) => void;
    resetUserInformationState: (dispatchBroadcastActive?: boolean) => void;
    resetDeliveryInformationState: (dispatchBroadcastActive?: boolean) => void;
    resetContactInformationState: (dispatchBroadcastActive?: boolean) => void;
};

export const createContactInformationSlice: StateCreator<ContactInformationSlice> = (set) => ({
    email: '',
    register: false,
    customer: undefined,
    telephone: '',
    gender: GenderTypeEnumApi.MrApi,
    genderHelper: '',
    firstName: '',
    firstNameVocative: '',
    lastName: '',
    lastNameVocative: '',
    street: '',
    city: '',
    postcode: '',
    companyName: '',
    companyNumber: '',
    companyTaxNumber: '',
    companySkVatNumber: '',
    deliveryType: DeliveryAddressTypeEnumApi.SameAsBillingApi,
    deliveryFirstName: '',
    deliveryLastName: '',
    deliveryCompanyName: '',
    deliveryTelephone: '',
    deliveryStreet: '',
    deliveryCity: '',
    deliveryPostcode: '',
    contactStreet: '',
    contactCity: '',
    contactPostcode: '',
    contactType: ContactAddressTypeEnumApi.SameAsBillingApi,
    note: '',
    questionaryConsent: false,
    customerReference: '',
    dealerCash: '',
    selectedBillingAddressUuid: '',

    updateContactInformationState: (value) => {
        set(value);
    },

    resetUserInformationState: (dispatchBroadcastActive = true) => {
        set(userInformationInitialState);
        if (dispatchBroadcastActive) {
            dispatchBroadcastChannel({
                name: 'currentCustomerUserBroadcast',
                data: { currentCustomerUser: 'userInformation' },
            });
        }
    },

    resetDeliveryInformationState: (dispatchBroadcastActive = true) => {
        set(deliveryInformationInitialState);
        if (dispatchBroadcastActive) {
            dispatchBroadcastChannel({
                name: 'currentCustomerUserBroadcast',
                data: { currentCustomerUser: 'deliveryInformation' },
            });
        }
    },

    resetContactInformationState: (dispatchBroadcastActive = true) => {
        set({ ...contactInformationInitialState, ...deliveryInformationInitialState });
        if (dispatchBroadcastActive) {
            dispatchBroadcastChannel({
                name: 'currentCustomerUserBroadcast',
                data: { currentCustomerUser: 'contactInformation' },
            });
        }
    },
});
