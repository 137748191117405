import { ChatIcon, BookIcon, CalculatorIcon, CatalogIcon, PercentIcon } from 'components/Basic/Icon/IconsSvg';
import { useTypedTranslationFunction } from 'hooks/typescript/UseTypedTranslationFunction';
import { FC, memo } from 'react';
import { getInternationalizedStaticUrls } from 'utils/getInternationalizedStaticUrls';
import { Locale } from 'helpers/locale';
import { useDomainConfig } from 'hooks/useDomainConfig';
import { ExtendedNextLink } from 'components/Basic/ExtendedNextLink/ExtendedNextLink';
import twMerge from 'utils/twMerge';

type LinksProps = {
    actionDiscountsLink: string;
};

const TEST_IDENTIFIER = 'header-links';

export const Links: FC<LinksProps> = memo(({ actionDiscountsLink }) => {
    const t = useTypedTranslationFunction();
    const { defaultLocale, url } = useDomainConfig();
    const [demandCartUrl, complaintUrl] = getInternationalizedStaticUrls(['/demandCart/', '/complaints/'], url);

    const LinksItem_twClass = 'flex lg:mr-[26px] vl:mr-[35px] last:mr-0';
    const LinksItemLink_twClass = 'flex items-center no-underline text-littleSmall';

    return (
        <ul className="ml-4 flex flex-row">
            <li className={LinksItem_twClass}>
                <ExtendedNextLink
                    type="article"
                    href="/kontakt/"
                    className={LinksItemLink_twClass}
                    data-testid={TEST_IDENTIFIER + '-contacts'}
                >
                    <ChatIcon className="mr-[7px] h-[18px] w-[18px] text-primary" />
                    {t('Contacts')}
                </ExtendedNextLink>
            </li>
            <li className={LinksItem_twClass}>
                <ExtendedNextLink
                    type="static"
                    href={complaintUrl}
                    className={LinksItemLink_twClass}
                    data-testid={TEST_IDENTIFIER + '-complaint'}
                >
                    <BookIcon className="mr-[7px] h-[18px] w-[18px] text-primary" />
                    {t('Complaint')}
                </ExtendedNextLink>
            </li>
            <li className={LinksItem_twClass}>
                <ExtendedNextLink
                    type="static"
                    href={demandCartUrl}
                    className={LinksItemLink_twClass}
                    data-testid={TEST_IDENTIFIER + '-demand'}
                >
                    <CalculatorIcon className="mr-[7px] h-[18px] w-[18px] text-primary" />
                    {t('Online demand')}
                </ExtendedNextLink>
            </li>
            {(defaultLocale === Locale.CS || defaultLocale === Locale.SK) && (
                <li className={LinksItem_twClass}>
                    <ExtendedNextLink
                        type="article"
                        href="/nase-katalogy/"
                        className={LinksItemLink_twClass}
                        data-testid={TEST_IDENTIFIER + '-catalogue'}
                    >
                        <CatalogIcon className="mr-[7px] h-[18px] w-[18px] text-primary" />
                        {t('Catalog')}
                    </ExtendedNextLink>
                </li>
            )}
            <li className={LinksItem_twClass}>
                <ExtendedNextLink
                    type="category"
                    href={actionDiscountsLink}
                    className={twMerge(LinksItemLink_twClass, 'text-red hover:text-red')}
                    data-testid={TEST_IDENTIFIER + '-promo'}
                >
                    <PercentIcon className="mr-[7px] h-[18px] w-[18px] text-red" />
                    {t('Promotions and Discounts')}
                </ExtendedNextLink>
            </li>
        </ul>
    );
});

Links.displayName = 'Links';
