import {
    CurrentCustomerUserQueryApi,
    DeliveryAddressFragmentApi,
    GenderTypeEnumApi,
    useCurrentCustomerUserQueryApi,
} from 'graphql/generated';
import { useQueryError } from 'hooks/graphQl/UseQueryError';
import { Translate } from 'next-translate';
import { BillingAddressDataType } from 'types/billingAddress';
import { CurrentCustomerType, DeliveryAddressType } from 'types/customer';
import { ContactInformationFormType } from 'types/form';
import { SelectOptionType } from 'types/selectOptions';
import { mapCurrentCustomerBillingAddressesApiData } from './CurrentCustomerUser';

export function useCurrentCustomerData(): CurrentCustomerType | null | undefined {
    const [{ data, error }] = useCurrentCustomerUserQueryApi();
    useQueryError(error);

    if (data?.currentCustomerUser === undefined) {
        return undefined;
    }

    return mapCurrentCustomerApiData(data.currentCustomerUser);
}

const mapCurrentCustomerApiData = (
    apiCurrentCustomerData: CurrentCustomerUserQueryApi['currentCustomerUser'],
): CurrentCustomerType | null => {
    if (apiCurrentCustomerData === null) {
        return null;
    }

    return {
        ...apiCurrentCustomerData,
        companyCustomer: apiCurrentCustomerData.__typename === 'CompanyCustomerUser',
        telephone: apiCurrentCustomerData.telephone === null ? '' : apiCurrentCustomerData.telephone,
        companyName:
            apiCurrentCustomerData.__typename === 'CompanyCustomerUser' && apiCurrentCustomerData.companyName !== null
                ? apiCurrentCustomerData.companyName
                : '',
        companyNumber:
            apiCurrentCustomerData.__typename === 'CompanyCustomerUser' && apiCurrentCustomerData.companyNumber !== null
                ? apiCurrentCustomerData.companyNumber
                : '',
        companyTaxNumber:
            apiCurrentCustomerData.__typename === 'CompanyCustomerUser' &&
            apiCurrentCustomerData.companyTaxNumber !== null
                ? apiCurrentCustomerData.companyTaxNumber
                : '',
        companySkVatNumber:
            apiCurrentCustomerData.__typename === 'CompanyCustomerUser' &&
            apiCurrentCustomerData.companySkVatNumber !== null
                ? apiCurrentCustomerData.companySkVatNumber
                : '',
        passwordOld: '',
        passwordFirst: '',
        passwordSecond: '',
        firstName: apiCurrentCustomerData.firstName ?? '',
        lastName: apiCurrentCustomerData.lastName ?? '',
        firstNameVocative:
            apiCurrentCustomerData.firstNameVocative !== null ? apiCurrentCustomerData.firstNameVocative : '',
        lastNameVocative:
            apiCurrentCustomerData.lastNameVocative !== null ? apiCurrentCustomerData.lastNameVocative : '',
        gender:
            apiCurrentCustomerData.gender === GenderTypeEnumApi.MrApi
                ? GenderTypeEnumApi.MrApi
                : GenderTypeEnumApi.MrsApi,
        billingAddresses: mapCurrentCustomerBillingAddressesApiData(apiCurrentCustomerData.billingAddresses),
        country: apiCurrentCustomerData.country.code,
    };
};

export const mapDeliveryAddress = (apiDeliveryAddressData: DeliveryAddressFragmentApi): DeliveryAddressType => {
    return {
        ...apiDeliveryAddressData,
        companyName: apiDeliveryAddressData.companyName ?? '',
        street: apiDeliveryAddressData.street ?? '',
        city: apiDeliveryAddressData.city ?? '',
        postcode: apiDeliveryAddressData.postcode ?? '',
        telephone: apiDeliveryAddressData.telephone ?? '',
        firstName: apiDeliveryAddressData.firstName ?? '',
        lastName: apiDeliveryAddressData.lastName ?? '',
        country: apiDeliveryAddressData.country?.code ?? '',
    };
};

export const mapDeliveryAddresses = (apiDeliveryAddressesData: DeliveryAddressFragmentApi[]): DeliveryAddressType[] => {
    return apiDeliveryAddressesData.map((address) => mapDeliveryAddress(address));
};

export function getCurrentCustomerBillingAddressesSelectOptions(
    billingAddresses: BillingAddressDataType[],
    t: Translate,
): SelectOptionType[] {
    const selectOptions =
        billingAddresses.length > 0
            ? billingAddresses.map((billingAddress): SelectOptionType => {
                  const companyName = billingAddress.companyName.length > 0 ? `${billingAddress.companyName},` : '';
                  const companyNumber =
                      billingAddress.companyNumber.length > 0
                          ? `${t('Company number')}: ${billingAddress.companyNumber},`
                          : '';
                  const companyStreet = billingAddress.street.length > 0 ? `${billingAddress.street},` : '';
                  const companyCity = billingAddress.city.length > 0 ? `${billingAddress.city},` : '';
                  const billingAddressLabel = `${companyName} ${companyNumber} ${companyStreet} ${companyCity} ${billingAddress.postcode}`;
                  return { label: billingAddressLabel, value: billingAddress.uuid };
              })
            : [{ label: '', value: '' }];

    return selectOptions;
}

export function getCurrentActiveCompany(currentCustomerData: ContactInformationFormType): BillingAddressDataType[] {
    return currentCustomerData.billingAddresses.filter(
        (billingAddress) => billingAddress.uuid === currentCustomerData.defaultBillingAddressUuid,
    );
}
