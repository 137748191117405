import {
    STATIC_PATHS_DOMAIN_1,
    STATIC_PATHS_DOMAIN_2,
    STATIC_PATHS_DOMAIN_3,
    STATIC_PATHS_DOMAIN_4,
} from 'config/staticRewritePaths/staticPaths';

export const getUrlsOnDomain = ([domain1, domain2, domain3, domain4]: string[], currentDomain: string) => {
    const domains = {
        [domain1]: STATIC_PATHS_DOMAIN_1,
        [domain2]: STATIC_PATHS_DOMAIN_2,
        [domain3]: STATIC_PATHS_DOMAIN_3,
        [domain4]: STATIC_PATHS_DOMAIN_4,
    };

    return domains[currentDomain] || {};
};
