import { desktopFirstSizes, mobileFirstSizes } from 'components/Theme/mediaQueries';
import { canUseDom } from 'helpers/canUseDom';
import { GtmDeviceTypes, GtmFlowType } from 'types/gtm/enums';
import { v4 as uuidV4 } from 'uuid';
import { compressToEncodedURIComponent, decompressFromEncodedURIComponent } from 'lz-string';
import { isServer } from 'helpers/isServer';
import { GtmUserInfoType } from 'types/gtm/types';
import { GtmPurchaseEventOrderPartType } from 'types/gtm/events';

export const getGtmDeviceType = (): GtmDeviceTypes => {
    if (typeof navigator === 'undefined') {
        return GtmDeviceTypes.unknown;
    }
    if (canUseDom()) {
        if (window.innerWidth <= desktopFirstSizes.mobile) {
            return GtmDeviceTypes.mobile;
        }
        return window.innerWidth >= mobileFirstSizes.vl ? GtmDeviceTypes.desktop : GtmDeviceTypes.tablet;
    }

    return GtmDeviceTypes.unknown;
};

export const compressObjectToString = (object: Record<string, unknown>): string =>
    compressToEncodedURIComponent(JSON.stringify(object));

export const decompressStringToObject = <T>(string: string | undefined): T | undefined => {
    if (string === undefined) {
        return undefined;
    }

    const decompressedString = decompressFromEncodedURIComponent(string);

    return JSON.parse(decompressedString);
};

export const getRandomPageId = (): string => uuidV4();

export const saveGtmPurchaseEventInLocalStorage = (
    gtmPurchaseEventOrderPart: GtmPurchaseEventOrderPartType,
    gtmPurchaseEventUserPart: GtmUserInfoType,
    flowType: GtmFlowType,
): void => {
    if (isServer()) {
        return;
    }

    const stringifiedGtmPurchaseEvent = JSON.stringify({
        gtmPurchaseEventOrderPart: compressObjectToString(gtmPurchaseEventOrderPart),
        gtmPurchaseEventUserPart: compressObjectToString(gtmPurchaseEventUserPart),
        flowType: getKeyFromFlowTypeValue(flowType),
    });

    localStorage.setItem('gtmPurchaseEvent', stringifiedGtmPurchaseEvent);
};

const getKeyFromFlowTypeValue = (flowTypeValue: string): keyof typeof GtmFlowType | undefined => {
    const keys = Object.keys(GtmFlowType) as Array<keyof typeof GtmFlowType>;
    const matchedKey = keys.find((key) => GtmFlowType[key] === flowTypeValue);

    return matchedKey;
};

export const getGtmPurchaseEventFromLocalStorage = (): {
    gtmPurchaseEventOrderPart: GtmPurchaseEventOrderPartType | undefined;
    gtmPurchaseEventUserPart: GtmUserInfoType | undefined;
    flowType: GtmFlowType | undefined;
} => {
    if (isServer()) {
        return {
            gtmPurchaseEventOrderPart: undefined,
            gtmPurchaseEventUserPart: undefined,
            flowType: undefined,
        };
    }

    const stringifiedGtmPurchaseEvent = localStorage.getItem('gtmPurchaseEvent');

    if (stringifiedGtmPurchaseEvent === null) {
        return {
            gtmPurchaseEventOrderPart: undefined,
            gtmPurchaseEventUserPart: undefined,
            flowType: undefined,
        };
    }

    const parsedGtmPurchaseEvent = JSON.parse(stringifiedGtmPurchaseEvent);

    return {
        gtmPurchaseEventOrderPart: decompressStringToObject(parsedGtmPurchaseEvent.gtmPurchaseEventOrderPart),
        gtmPurchaseEventUserPart: decompressStringToObject(parsedGtmPurchaseEvent.gtmPurchaseEventUserPart),
        flowType: GtmFlowType[parsedGtmPurchaseEvent.flowType as keyof typeof GtmFlowType],
    };
};

export const removeGtmPurchaseEventFromLocalStorage = (): void => {
    if (isServer()) {
        return;
    }

    localStorage.removeItem('gtmPurchaseEvent');
};

export const saveGtmPurchasePaymentCounterInLocalStorage = (gtmPurchasePaymentCounter: number): void => {
    if (isServer()) {
        return;
    }

    localStorage.setItem('gtmPurchasePaymentCounter', gtmPurchasePaymentCounter.toString());
};

export const increaseGtmPurchasePaymentCounterInLocalStorageByOne = (): void => {
    if (isServer()) {
        return;
    }
    const currentGtmPurchasePaymentCounterAsString = localStorage.getItem('gtmPurchasePaymentCounter') ?? '0';
    const currentGtmPurchasePaymentCounterAsNumber = Number.parseInt(currentGtmPurchasePaymentCounterAsString);

    localStorage.setItem('gtmPurchasePaymentCounter', (currentGtmPurchasePaymentCounterAsNumber + 1).toString());
};

export const removeGtmPurchasePaymentCounterFromLocalStorage = (): void => {
    if (isServer()) {
        return;
    }

    localStorage.removeItem('gtmPurchasePaymentCounter');
};

export const getGtmPurchasePaymentCounterFromLocalStorage = (): number | undefined => {
    if (isServer()) {
        return undefined;
    }

    const currentGtmPurchasePaymentCounterAsString = localStorage.getItem('gtmPurchasePaymentCounter');

    if (currentGtmPurchasePaymentCounterAsString === null) {
        return undefined;
    }

    return Number.parseInt(currentGtmPurchasePaymentCounterAsString);
};
