import { SimpleCategoryFragmentApi } from 'graphql/generated';
import { FC, useContext, useState } from 'react';
import { DropdownMenuContext } from './DropdownMenu';
import { ArrowIcon } from 'components/Basic/Icon/IconsSvg';
import { twJoin } from 'tailwind-merge';
import { ExtendedNextLink } from 'components/Basic/ExtendedNextLink/ExtendedNextLink';

type TertiaryListProps = {
    columnCategoryChildren: SimpleCategoryFragmentApi[];
};

const TEST_IDENTIFIER = 'layout-header-dropdownmenu-tertiarylist';

export const TertiaryList: FC<TertiaryListProps> = ({ columnCategoryChildren }) => {
    const [isTertiaryListOpened, setTertiaryListVisibility] = useState(false);
    const context = useContext(DropdownMenuContext);

    if (columnCategoryChildren.length === 0) {
        return null;
    }

    return (
        <>
            <div
                className="absolute right-0 top-0 flex h-[47px] w-[47px] cursor-pointer items-center justify-center"
                onClick={() => setTertiaryListVisibility(!isTertiaryListOpened)}
            >
                <ArrowIcon
                    className={twJoin(
                        'h-[16px] w-[16px] text-primary ui-transition',
                        isTertiaryListOpened && 'rotate-180',
                    )}
                />
            </div>

            {isTertiaryListOpened && (
                <div className="mb-[15px] w-full">
                    {columnCategoryChildren.map((columnCategoryChild, index) => (
                        <div
                            key={columnCategoryChild.uuid}
                            className="relative flex flex-wrap"
                            onClick={context.scrollToTop}
                            data-testid={`${TEST_IDENTIFIER}-${index}`}
                        >
                            <ExtendedNextLink
                                type="category"
                                href={columnCategoryChild.slug}
                                className="flex flex-1 px-[27px] py-[14px] text-littleSmall font-base text-primary no-underline"
                                onClick={context.onMenuToggleHandler}
                            >
                                {columnCategoryChild.nameForNavigationPanel}
                            </ExtendedNextLink>
                        </div>
                    ))}
                </div>
            )}
        </>
    );
};

TertiaryList.displayName = 'TertiaryList';
