import { mapAvailabilityData } from 'connectors/availability/Availability';
import { getFirstImage, getVariantOrMainVariantImages } from 'connectors/image/Image';
import { mapProductPriceData } from 'connectors/price/Prices';
import { CartItemProductFragmentApi } from 'graphql/generated';
import { SimpleProductType } from 'types/product';

export const mapSimpleProductApiData = (
    simpleProductApiData: CartItemProductFragmentApi,
    currencyCode: string,
): SimpleProductType => {
    return {
        ...simpleProductApiData,
        price: mapProductPriceData(simpleProductApiData.price, currencyCode, simpleProductApiData.stockUnitName),
        image: getFirstImage(
            simpleProductApiData.__typename === 'Variant' && simpleProductApiData.mainVariant !== null
                ? getVariantOrMainVariantImages(simpleProductApiData.images, simpleProductApiData.mainVariant.images)
                : simpleProductApiData.images,
        ),
        unitName: simpleProductApiData.unit.name,
        availability: mapAvailabilityData(simpleProductApiData.availability),
        catalogNumber: simpleProductApiData.catalogNumber,
    };
};
