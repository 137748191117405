import { ArrowSecondaryIcon } from 'components/Basic/Icon/IconsSvg';
import { FC, useRef } from 'react';
import { useScrollTo } from 'hooks/ui/useScrollTo';
import { Button } from 'components/Forms/Button/Button';
import twMerge from 'utils/twMerge';
import { Transition } from 'react-transition-group';
import { canUseDom } from 'helpers/canUseDom';
import { useIsScrollToTop } from 'hooks/ui/useIsScrollToTop';

const TEST_IDENTIFIER = 'scroll-top';

export const ScrollTop: FC = () => {
    const scrollTo = useScrollTo();
    const isScrollToTop = useIsScrollToTop();
    const cssTransitionRef = useRef<HTMLDivElement>(null);
    const scrollY = canUseDom() ? window.scrollY : 0;

    const transitionStyles = {
        entering: { opacity: 100 },
        entered: { opacity: 100 },
        exiting: { opacity: 0 },
        exited: { opacity: 0 },
        unmounted: {},
    };

    return (
        <Transition nodeRef={cssTransitionRef} in={scrollY > 800 && isScrollToTop} timeout={200} unmountOnExit>
            {(state) => (
                <div
                    className={twMerge(
                        'fixed bottom-[184px] right-5 z-aboveMenu ml-[calc(1240px/2+32px)] ui-transition',
                        '[@media(min-width:1240px)_and_(max-width:1440px)]:ml-0',
                        '[@media(min-width:1441px)_and_(max-width:1559px)]:left-1/2 [@media(min-width:1441px)_and_(max-width:1559px)]:right-auto [@media(min-width:1441px)_and_(max-width:1559px)]:ml-[calc(1240px/2+32px)]',
                        '[@media(min-width:1560px)_and_(max-width:1760px)]:ml-0',
                        '[@media(min-width:1761px)]:left-1/2 [@media(min-width:1761px)]:right-auto [@media(min-width:1761px)]:ml-[calc(1560px/2+32px)]',
                    )}
                    onClick={() => scrollTo(0)}
                    data-testid={TEST_IDENTIFIER}
                    ref={cssTransitionRef}
                    style={{
                        ...transitionStyles[state],
                    }}
                >
                    <Button size="square">
                        <ArrowSecondaryIcon className="h-4 w-4 text-white" />
                    </Button>
                </div>
            )}
        </Transition>
    );
};

ScrollTop.displayName = 'ScrollTop';
