import { useCallback, useEffect, useState } from 'react';

export const useGetMobileBottomGap = (defaultValue = 30): number => {
    const [mobileBottomGap, setMobileBottomGap] = useState(defaultValue);

    const handleResize = useCallback(() => {
        const deviceHeight = window.screen.height;
        const browserHeight = window.innerHeight || document.documentElement.clientHeight;
        const menuBottomGap = deviceHeight > browserHeight ? deviceHeight - browserHeight : defaultValue;

        setMobileBottomGap(menuBottomGap);
    }, [defaultValue]);

    useEffect(() => {
        handleResize();

        window.addEventListener('resize', handleResize, { passive: true });

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [handleResize, mobileBottomGap]);

    return mobileBottomGap;
};
