import { RemoveIcon, MenuIcon } from 'components/Basic/Icon/IconsSvg';
import { useTypedTranslationFunction } from 'hooks/typescript/UseTypedTranslationFunction';
import { FC, MouseEventHandler } from 'react';
import twMerge from 'utils/twMerge';

type HamburgerMenuProps = {
    isMenuOpened: boolean;
    onMenuToggleHandler: MouseEventHandler<HTMLDivElement>;
};

export const HamburgerMenu: FC<HamburgerMenuProps> = (props) => {
    const testIdentifier = 'layout-header-hamburgermenu';

    const t = useTypedTranslationFunction();

    return (
        <div
            className={twMerge(
                'flex h-10 w-full cursor-pointer items-center rounded-md border-[2px] border-greyVeryLight bg-greyVeryLight px-[6px]',
                props.isMenuOpened && 'z-menu-1 border-border bg-white',
                'lg:h-[48px]',
            )}
            onClick={props.onMenuToggleHandler}
            data-testid={testIdentifier}
        >
            <div className="flex w-[21px] items-center justify-center">
                {props.isMenuOpened ? (
                    <RemoveIcon className="h-4 w-4 text-primary" />
                ) : (
                    <MenuIcon className="h-[21px] w-[21px] text-primary" />
                )}
            </div>
            <span className="ml-[5px] pt-[1px] text-littleSmall font-semiBold uppercase text-primary">
                {props.isMenuOpened ? t('Close') : t('Menu')}
            </span>
        </div>
    );
};

HamburgerMenu.displayName = 'HamburgerMenu';
