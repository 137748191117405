import { useWidgetsQueryApi, WidgetsQueryApi } from 'graphql/generated';
import { useQueryError } from 'hooks/graphQl/UseQueryError';
import { WidgetPlacementsEnumType, WidgetType } from 'types/widgets';

export const useWidgets = (
    placements: WidgetPlacementsEnumType[],
): { widgets: WidgetType | undefined; customStyles: string | null } => {
    const [{ data, error }] = useWidgetsQueryApi({ variables: { placements } });
    useQueryError(error);

    if (data?.widgets === undefined) {
        return { widgets: undefined, customStyles: null };
    }

    return { widgets: mapWidgets(data.widgets), customStyles: data.widgets[0].customStyles };
};

const mapWidgets = (apiData: WidgetsQueryApi['widgets']): WidgetType => {
    const mappedWidgets: Partial<WidgetType> = {};
    for (const widget of apiData) {
        const placement = widget.placement as WidgetPlacementsEnumType;
        mappedWidgets[placement] = {
            content: widget.content,
        };
    }

    return mappedWidgets as WidgetType;
};
