import { useAutocompleteSearchForm, useAutocompleteSearchFormMeta } from './formMeta';
import { MINIMAL_SEARCH_QUERY_LENGTH, useAutocompleteSearch } from 'connectors/search/AutocompleteSearch';
import { canUseDom } from 'helpers/canUseDom';
import { useGtmSuggestResultEvent } from 'hooks/gtm/useGtmSuggestResultEvent';
import useDebounce from 'hooks/helpers/UseDebounce';
import { useEffectOnce } from 'hooks/ui/useEffectOnce';
import { useRouter } from 'next/router';
import { FC, FormEvent, RefObject, memo, useEffect, useRef, useState } from 'react';
import { Controller, FormProvider, useWatch } from 'react-hook-form';
import { twJoin } from 'tailwind-merge';
import { AutocompleteSearchType } from 'types/search';
import { getInternationalizedStaticUrls } from 'utils/getInternationalizedStaticUrls';
import { TextInput } from 'components/Forms/TextInput/TextInput';
import { SearchIcon } from 'components/Basic/Icon/IconsSvg';
import twMerge from 'utils/twMerge';
import { useShopInfo } from 'connectors/shopInfo/ShopInfo';
import { SEARCH_QUERY_PARAMETER_NAME } from 'helpers/queryParams/queryParamNames';
import { useDomainConfig } from 'hooks/useDomainConfig';
import { Autocomplete } from './Autocomplete';

type AutocompleteSearchProps = {
    inFixedHeader: boolean;
    searchRef: RefObject<HTMLDivElement>;
};

const TEST_IDENTIFIER = 'layout-header-search-autocomplete';

const AutocompleteSearch: FC<AutocompleteSearchProps> = ({ searchRef }) => {
    const router = useRouter();
    const shopInfoData = useShopInfo();
    const [formProviderMethods] = useAutocompleteSearchForm(shopInfoData?.searchHintActive);
    const formMeta = useAutocompleteSearchFormMeta(formProviderMethods);
    const autocompleteSearchQueryValue = useWatch({
        name: formMeta.fields.autocompleteSearchQuery.name,
        control: formProviderMethods.control,
    });
    const debouncedAutocompleteSearchQuery = useDebounce(autocompleteSearchQueryValue, 200);
    const [hasAutocompleteSearchFocus, setAutocompleteSearchFocus] = useState(false);
    const [autocompleteSearchApiResults, fetching] = useAutocompleteSearch(debouncedAutocompleteSearchQuery);
    const [autocompleteSearchResults, setAutocompleteSearchResults] = useState<AutocompleteSearchType | undefined>(
        undefined,
    );
    const autocompleteSearchInRef = useRef<HTMLDivElement>(null);
    const { url } = useDomainConfig();
    const [searchUrl] = getInternationalizedStaticUrls(['/search/'], url);
    const [isAutocompleteSearchOpened, setIsAutocompleteSearchOpened] = useState(false);
    const [isFormSubmitted, setFormSubmitted] = useState(false);

    useEffect(() => {
        if (formProviderMethods.formState.isValid) {
            setAutocompleteSearchResults(autocompleteSearchApiResults);
        } else {
            setAutocompleteSearchResults(undefined);
        }
    }, [autocompleteSearchApiResults, autocompleteSearchQueryValue, formProviderMethods.formState.isValid]);

    useGtmSuggestResultEvent(autocompleteSearchApiResults, debouncedAutocompleteSearchQuery, fetching);

    useEffectOnce(() => {
        if (!canUseDom()) {
            return undefined;
        }

        const onDocumentClickHandler: EventListener = (event) => {
            if (autocompleteSearchInRef.current === null || !(event.target instanceof HTMLElement)) {
                setAutocompleteSearchFocus(false);
                return;
            }

            if (autocompleteSearchInRef.current.contains(event.target)) {
                setAutocompleteSearchFocus(true);
            } else {
                setAutocompleteSearchFocus(false);
            }
        };

        document.addEventListener('click', onDocumentClickHandler);

        return () => document.removeEventListener('click', onDocumentClickHandler);
    });

    useEffect(() => {
        if (!isFormSubmitted || fetching || autocompleteSearchApiResults === undefined) {
            return;
        }

        setAutocompleteSearchFocus(false);
        setFormSubmitted(false);
        formProviderMethods.setValue('autocompleteSearchQuery', '');
        if (autocompleteSearchApiResults.productsSearch.redirectUrl !== null) {
            router.push({ pathname: autocompleteSearchApiResults.productsSearch.redirectUrl });
        } else {
            router.push({
                pathname: searchUrl,
                query: { [SEARCH_QUERY_PARAMETER_NAME]: autocompleteSearchQueryValue },
            });
        }
    }, [
        autocompleteSearchApiResults,
        autocompleteSearchQueryValue,
        fetching,
        formProviderMethods,
        isFormSubmitted,
        router,
        searchUrl,
    ]);

    const handleClickOutside = (event: Event) => {
        if (searchRef.current && !searchRef.current.contains(event.target as Node) && isAutocompleteSearchOpened) {
            setIsAutocompleteSearchOpened(!isAutocompleteSearchOpened);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    });

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const form = event.currentTarget as HTMLFormElement;
        const autocompleteInputElement = form.elements.namedItem('autocompleteSearchQuery') as HTMLInputElement;

        if (autocompleteSearchQueryValue === autocompleteInputElement.value) {
            setFormSubmitted(true);
            return;
        }

        formProviderMethods.setValue('autocompleteSearchQuery', autocompleteInputElement.value);
        setFormSubmitted(true);
    };

    return (
        <>
            <div
                className={twMerge(
                    'hidden h-full cursor-pointer flex-row items-center justify-center rounded-md bg-greyVeryLight px-[10px] text-littleSmall font-semiBold no-underline duration-200 ease-default',
                    'lg:px-[14px] lg:text-small',
                    '[@media(hover:hover)_and_(pointer:fine)]:hover:bg-white [@media(hover:hover)_and_(pointer:fine)]:hover:no-underline [@media(hover:hover)_and_(pointer:fine)]:hover:shadow-secondary',
                    isAutocompleteSearchOpened && 'bg-white no-underline shadow-secondary',
                    'flex lg:hidden',
                )}
                onClick={() => setIsAutocompleteSearchOpened(!isAutocompleteSearchOpened)}
            >
                <SearchIcon className="h-5 w-5 text-primary" />
            </div>
            <div
                className={twJoin(
                    'z-menu h-[48px]',

                    isAutocompleteSearchOpened && 'absolute left-0 right-0 mt-[10px] block lg:static lg:mt-0',
                    !isAutocompleteSearchOpened && 'hidden lg:block',
                )}
                data-testid={`${TEST_IDENTIFIER}-input-wrapper`}
            >
                <div className="duration-200 ease-default" ref={autocompleteSearchInRef}>
                    <form
                        className={twJoin(
                            'relative flex w-full duration-200 ease-default',
                            hasAutocompleteSearchFocus && 'z-aboveMenu',
                        )}
                        onSubmit={
                            !shopInfoData?.searchHintActive
                                ? handleSubmit
                                : formProviderMethods.handleSubmit(() => setFormSubmitted(true))
                        }
                    >
                        <FormProvider {...formProviderMethods}>
                            <Controller
                                control={formProviderMethods.control}
                                name={formMeta.fields.autocompleteSearchQuery.name}
                                render={({ field }) => (
                                    <>
                                        <TextInput
                                            inputTrigger={formProviderMethods.trigger}
                                            id={formMeta.formName + '-' + formMeta.fields.autocompleteSearchQuery.name}
                                            name={formMeta.fields.autocompleteSearchQuery.name}
                                            type="search"
                                            placeholderType="static"
                                            inputSize="medium"
                                            variant="searchInHeader"
                                            label={formMeta.fields.autocompleteSearchQuery.label}
                                            fieldRef={field}
                                            data-testid={`${TEST_IDENTIFIER}-input`}
                                            className="border-primary"
                                        />

                                        <button
                                            className={twJoin(
                                                'absolute bottom-0 right-0 top-0 flex w-12 cursor-pointer rounded-l-none rounded-r-md border-0 bg-primary duration-200 ease-default hover:bg-primaryDarken',
                                            )}
                                            type="submit"
                                        >
                                            <SearchIcon
                                                className="h-12 w-12 p-[14px] text-white"
                                                dataTestid="basic-icon-iconsvg-Search"
                                            />
                                        </button>
                                    </>
                                )}
                            />
                        </FormProvider>
                    </form>
                    {shopInfoData !== undefined && shopInfoData.searchHintActive && (
                        <Autocomplete
                            autocompleteSearchResults={autocompleteSearchResults}
                            isAutocompleteActive={
                                hasAutocompleteSearchFocus &&
                                autocompleteSearchQueryValue.length >= MINIMAL_SEARCH_QUERY_LENGTH &&
                                autocompleteSearchResults !== undefined
                            }
                            autocompleteSearchQueryValue={autocompleteSearchQueryValue}
                        />
                    )}
                </div>
            </div>
        </>
    );
};

/* @component */
export default memo(AutocompleteSearch);
