import { FC } from 'react';

type ShopWithUsProps = {
    htmlContent?: string;
};

export const ShopWithUs: FC<ShopWithUsProps> = (props) => {
    return (
        <>
            {props.htmlContent !== undefined && (
                <div className="flex flex-col items-center rounded-md bg-greyVeryLight py-6 xl:px-[150px]">
                    <div dangerouslySetInnerHTML={{ __html: props.htmlContent }} />
                </div>
            )}
        </>
    );
};

ShopWithUs.displayName = 'ShopWithUs';
