import { FC, HTMLAttributes, RefObject } from 'react';
import { ExtractNativePropsFromDefault } from 'typeHelpers/ExtractNativePropsFromDefault';
import twMerge from 'utils/twMerge';

type NativeProps = ExtractNativePropsFromDefault<
    HTMLAttributes<HTMLDivElement>,
    'children',
    'style' | 'id' | 'className'
>;

type WeblineProps = NativeProps & {
    scrollTarget?: RefObject<HTMLElement>;
    type?: 'colored' | 'dark' | 'light' | 'white';
    'data-testid'?: string;
    parentRef?: RefObject<HTMLDivElement>;
    containerClassName?: string;
};

export const Webline: FC<WeblineProps> = ({ className, type, parentRef, children, containerClassName, ...props }) => {
    const testIdentifier = props['data-testid'] ?? 'layout-webline' + (type !== undefined ? '-' + type : '');

    return (
        <div
            className={twMerge(
                type === 'colored' && 'bg-primary',
                type === 'dark' && 'bg-grey',
                type === 'light' && 'bg-greyVeryLight',
                type === 'white' && 'bg-white',
                className,
            )}
            {...props}
            ref={parentRef}
            data-testid={testIdentifier}
        >
            <div className={twMerge('px-5 xl:mx-auto xl:w-[1240px] xxl:w-[1560px]', containerClassName)}>
                {children}
            </div>
        </div>
    );
};

Webline.displayName = 'Webline';
