import { FC, memo } from 'react';
import { twJoin } from 'tailwind-merge';
import { HeaderContact } from './HeaderContact';
import { Links } from './Links';

type HeaderTabletBlockProps = {
    isFixedHeader: boolean;
    actionDiscountsLink: string;
};

export const HeaderTabletBlock: FC<HeaderTabletBlockProps> = ({ isFixedHeader, actionDiscountsLink }) => {
    return (
        <>
            {!isFixedHeader && (
                <div
                    className={twJoin(
                        'hidden',
                        `lg:order-2 lg:mb-3 lg:mt-[25px] lg:flex lg:w-[calc(100%-var(--headerLogoTabletWidth))] lg:flex-row lg:items-center lg:justify-end`,
                        'vl:order-1 vl:m-0 vl:w-full vl:justify-between vl:py-5',
                    )}
                >
                    <div className="headerTopLeft_twClass">
                        <HeaderContact />
                    </div>

                    <Links actionDiscountsLink={actionDiscountsLink} />
                </div>
            )}
        </>
    );
};

export default memo(HeaderTabletBlock);
HeaderTabletBlock.displayName = 'HeaderTabletBlock';
