import { useTypedTranslationFunction } from 'hooks/typescript/UseTypedTranslationFunction';
import { FC } from 'react';

const TEST_IDENTIFIER = 'layout-footer-footercopyright';

export const FooterCopyright: FC = () => {
    const t = useTypedTranslationFunction();

    return (
        <div className="flex flex-col items-center text-center" data-testid={TEST_IDENTIFIER}>
            <div className="mb-[10px] flex items-center text-extraVerySmall text-greyDark">
                {t('© 2010 - 2017 B2B Partner s.r.o. - All rights reserved.')}
            </div>
            <div className="flex items-center text-extraVerySmall text-greyDark">
                {t('Professional tailor-made e-shop')}
                <a className="ml-[14px]" href="https://www.shopsys.com" target="_blank" rel="noreferrer">
                    <img src="/images/logo-shopsys.svg" alt="shopsys" width={77} height={18} />
                </a>
            </div>
        </div>
    );
};

FooterCopyright.displayName = 'FooterCopyright';
