import {
    useSettingsQueryApi,
    usePrivacyPolicyArticleQueryApi,
    useTermsAndConditionsArticleQueryApi,
    ShopInfoFragmentApi,
} from 'graphql/generated';
import { useQueryError } from 'hooks/graphQl/UseQueryError';

export const useShopInfo = (): ShopInfoFragmentApi | undefined => {
    const [{ data, error }] = useSettingsQueryApi({ requestPolicy: 'cache-first' });
    useQueryError(error);

    if (data?.settings?.shopInfo === undefined) {
        return undefined;
    }

    return data.settings.shopInfo;
};

export const useTermsAndConditionsArticle = (): string | undefined => {
    const [{ data, error }] = useTermsAndConditionsArticleQueryApi();
    useQueryError(error);

    if (data?.termsAndConditionsArticle === undefined || data.termsAndConditionsArticle === null) {
        return undefined;
    }

    return data.termsAndConditionsArticle.slug;
};

export const usePrivacyPolicyArticle = (): string | undefined => {
    const [{ data, error }] = usePrivacyPolicyArticleQueryApi();
    useQueryError(error);

    if (data?.privacyPolicyArticle === undefined || data.privacyPolicyArticle === null) {
        return undefined;
    }

    return data.privacyPolicyArticle.slug;
};
